import ky from "../ky";

export type DictionaryModel = {
    [key: string]: string;
};
export type FitlerPropsType = {
    pageName: string,
    filterValue: DictionaryModel
}

export type FilterPageName = {
    pageName: string
}

export type FilterSettings = {
    pageName: string,
    fieldName: string
}

const urlHelper = (id: number | boolean | "", param: string, end?: string) => {
  return id !== "" ? `${param}=${id}${end && end}` : "";
};

export const getFilters = ({ pageName }: FilterPageName) =>
    ky.get(`Filter?pageName=${pageName}`);

export const getPageFilters = () =>
    ky.get(`Filter/getAll`);

export const updateFilters = (props: FitlerPropsType) =>
    ky.post(`Filter`, { json: { ...props } });