import React, { useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from 'react-select';
import { ReactComponent as FontAwesomeIcon } from "./arrow_dropdown.svg";

import { useStoreActions } from "../../../models/hooks";
import { LangEntry } from "../../../services/languages/languages.model";
import { LangSelect } from "./style";
import * as color from "../../portal/ColorVariables";

const Placeholder = (props: any) => {
    return <components.Placeholder {...props} />;
};

const CaretDownIcon = () => {
    return <FontAwesomeIcon />;
};

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon />
        </components.DropdownIndicator>
    );
};

export const ChangeLanguageSelect: React.FC = () => {
    const [lang, setLang] = useState<LangEntry[]>([]);
    const { i18n } = useTranslation();
    const languages = useStoreActions((action) => action.languages.getLanguages);
    const [defOption, setDefOption] = useState<any>(null);
    const [newOptions, setNewOptions] = useState<any[]>([]);

    const selectChangeLanguage = (e: any) => {
        i18n.changeLanguage(e.code.toLowerCase());
    };

    const value = i18n.language;

    useEffect(() => {
        const response = async () => {
            const result = await languages();
            setLang(result);
        };
        response();
    }, [languages]);

    useEffect(() => {
        if (lang) {
            let options = lang.map(({ id, code }) => {
                return {
                    value: id,
                    label: code,
                    code
                };
            });
            setNewOptions(options);
            let selVal = options.find(x => x.code === value);
            if (selVal) {
                setDefOption(selVal);
            }
        }
    }, [lang, value]);

    const customStyles = {
        container: (provided: any) => ({
            ...provided,
            border: `none`,
            width: 60,
            height: 32,
        }),
        control: (provided: any) => ({
            ...provided,
            border: `1px solid #ffffff`,
            boxShadow: `none`,
            width: 60,
            height: 32,
            minHeight: 0,
            fontSize: `${color.FONT_14}`,
            lineHeight: `142%`,
            borderRadius: `4px`,
            backgroundColor: `transparent`,
            color: `#ffffff`,
            '&:hover': {
                border: `1px solid #ffffff`
            },
            '&:focus': {
                border: `1px solid #ffffff`
            }
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            padding: `2px 4px 2px 4px`
        }),
        singleValue: (provided: any) => ({
            ...provided,
            top: `calc(50% - 1px)`,
            color: `#ffffff`
        }),
        indicatorSeparator: () => ({
            display: `none`
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            background: state.isSelected ? '#ffffff' : '#ffffff',
            color: state.isSelected ? `${color.NEW_DARK_GRAY}` : `${color.NEW_DARK_GRAY}`,
            padding: `6px 16px 7px`,
            fontSize: `${color.FONT_14}`,
            lineHeight: `183%`,
            '&:hover': {
                background: state.isSelected ? `${color.NEW_LIGHT_GREY}` : `${color.NEW_LIGHT_GREY}`
            }
        }),
        menu: (provided: any) => ({
            ...provided,
            width: 60,
            textAlign: "left",
            zIndex: 100
        })
    };

    return lang && lang.length > 0 ? (
        <>
            <Select
                onChange={selectChangeLanguage}
                value={newOptions.find(x => x.code.toLowerCase() === value)}
                options={newOptions}
                defaultValue={defOption}
                components={{ Placeholder, DropdownIndicator }}
                styles={customStyles}
                isSearchable={false}
            />
        </>
    ) : null;
};
