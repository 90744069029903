import styled from 'styled-components';
import * as color from "../components/portal/ColorVariables";

export const AppContainer = styled.div`
    text-align: justify;
    font-family: "Maven Pro", sans-serif;
    background: ${color.NEW_LIGHT};
`;

export const StyledLoader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color.NAV_BGD};
`;