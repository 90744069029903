import ky from "../ky";
import { DefinitionType } from "./defQuestions.model";

export type LanguageCodeType = {
  languageCode: string;
};

export type EditDefType = {
  languageCode: string;
  data: DefinitionType;
};

export type EditQuestDefType = {
    languageCode: string;
    data: DefinitionType;
    questionaireId: number;
}

export type DefId = {
  id?: string | number;
  languageCode?: string;
};

export type QuestionTypeType = {
  questionTypeId: number;
};

export type ImportQuestionType = {
    languageCode: string,
    data: FormData
};

export type ImportQuestionFinalType = {
    importQuestion: string[][],
    languageCode: string,
    languageCode2: string
};

export type LanguageCodeTypeById = {
    languageCode: string;
    importQuestion: number[]
};

export type QuestionTypeEval = {
    questionsType: number;
    evaluationsIds: number[];
};

export type LanguageCodeTypeEval = {
    languageCode: string;
    questionTypeObj: QuestionTypeEval;
};

export const getDefList = ({ languageCode }: LanguageCodeType) =>
    ky.get(`DefQuestions?languageCode=${languageCode}`);

export const getDefListById = ({ languageCode, importQuestion }: LanguageCodeTypeById) =>
    ky.post(`DefQuestions/ById?languageCode=${languageCode}`, {
        json: importQuestion
    });

export const getDefListByEval = ({ languageCode, questionTypeObj }: LanguageCodeTypeEval) =>
    ky.post(`DefQuestions/ByEval?languageCode=${languageCode}`, {
        json: questionTypeObj
    });

export const updateDefinition = ({ languageCode, data }: EditDefType) =>
  ky.put(`DefQuestions?languageCode=${languageCode}`, { json: data });

export const deleteDefinition = ({ id }: DefId) =>
  ky.delete(`DefQuestions?id=${id}`);

export const getOneDefinition = ({ id, languageCode }: DefId) =>
  ky.get(`DefQuestions/${id}?languageCode=${languageCode}`);

export const addDefinition = ({ languageCode, data }: EditDefType) =>
  ky.post(`DefQuestions/add?languageCode=${languageCode}`, { json: data });

export const addQuestionDef = ({ languageCode, data, questionaireId}: EditQuestDefType) =>
    ky.post(`DefQuestions/addDef?languageCode=${languageCode}&questionaireId=${questionaireId}`, { json: data });

export const getBasicDef = ({ questionTypeId }: QuestionTypeType) =>
  ky.get(`DefQuestions/basic?questionTypeId=${questionTypeId}
  `);

export const importQuestions = ({ languageCode, data }: ImportQuestionType) =>
    ky.post(`DefQuestions/Import/Get?languageCode=${languageCode}`, { body: data });

export const importQuestionsFinal = ({ languageCode, languageCode2, importQuestion }: ImportQuestionFinalType) =>
    ky.post(`DefQuestions/addMany?languageCode=${languageCode}&languageCode2=${languageCode2}`, { json: { data: importQuestion } });