import ky from "../ky";
import { QuestionBasicType, QuestionType } from "./questions.model";

export type CategoryId = {
  categoryId?: number | string;
};

export type EditQuestionType = {
  languageCode?: string;
  data: QuestionType;
};

export type EditQuestionTextType = {
    id: number;
    title: string;
    question: string;
    instruction: string;
};

export const getCategoryQuestions = ({ categoryId }: CategoryId) =>
  ky.get(`Questions?categoryId=${categoryId}`);

export const updateQuestion = ({ data }: EditQuestionType) =>
    ky.put(`Questions`, { json: data });

export const updateQuestionText = (data: EditQuestionTextType) =>
    ky.post(`Questions/EditQuestionText`, { json: data });

export type QuestionId = {
  id?: number | string;
  languageCode?: string;
};

export const deleteQuestion = ({ id }: QuestionId) =>
  ky.delete(`Questions?id=${id}`);

export const getOneQuestion = ({ id, languageCode }: QuestionId) =>
  ky.get(`Questions/${id}?languageCode=${languageCode}`);

export type AddQuestionType = { categoryId: number; idx: Array<number> };

export const addQuestions = ({ categoryId, idx }: AddQuestionType) =>
  ky.post(`Questions/add?categoryId=${categoryId}`, { json: { ids: idx } });

export type AddSubQuestionType = { questionId: number; idx: Array<number> };

export const addSubQuestions = ({ questionId, idx }: AddSubQuestionType) =>
  ky.post(`Questions/subquestions/add?questionId=${questionId}`, {
    json: { ids: idx },
  });

export const getQuestionTypes = () => ky.get(`Questions/questionTypes`);

export type MultipleChoiceType = {
  forQuestionId: number;
  languageCode: string;
};

export const getMultipleChoiceQuestions = ({
  forQuestionId,
  languageCode,
}: MultipleChoiceType) =>
  ky.get(`Questions/show?forQuestionId=${forQuestionId}&languageCode=${languageCode}
`);

export type GetBasicType = {
  categoryId: number;
  questionTypeId: number;
};

export const getBasicQuestion = ({
  categoryId,
  questionTypeId,
}: GetBasicType) =>
  ky.get(
    `Questions/basic?categoryId=${categoryId}&questionTypeId=${questionTypeId}`
  );

export type CopyQuestionType = {
  questionId: number;
  categoryId: number;
};

export const copyQuestion = ({ questionId, categoryId }: CopyQuestionType) =>
  ky.post(`Questions/copy?questionId=${questionId}&categoryId=${categoryId}`);

export type QPositionType = {
  questionId?: number;
  isHigher: boolean;
};

export const questionPosition = ({ questionId, isHigher }: QPositionType) =>
  ky.put(`Questions/position?questionId=${questionId}&isHigher=${isHigher}`);

export const updateQuestionPositions = (questions: QuestionBasicType[]) => {
  ky.put(`Questions/editPositionList`, { json: { questions } });
};

export type QuestionnaireId = {
  questionaireId: number;
};

export const getForResponsibility = ({ questionaireId }: QuestionnaireId) =>
  ky.get(`Questions/forQuestionaire?questionaireId=${questionaireId}`);

export type PreviewQuestionType = {
  questionId: number;
  languageCode: string;
};

export const previewQuestion = ({
  questionId,
  languageCode,
}: PreviewQuestionType) =>
  ky.get(
    `Questions/toView?questionId=${questionId}&languageCode=${languageCode}`
  );