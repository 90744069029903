import ky from "../ky";
import { BasicUserModel } from "./users.model";
import { TestingsType } from "../questionnaires/questionnaires.service";

export type UsersListType = {
    currentPageNo: number;
    active: boolean | "";
    query: string;
    sortCol: string;
    isDescending: boolean;
    subtrajectoryId: number | "";
    functionId: number | "";
    userGroupId: number | undefined;
};

const urlHelper = (id: number | boolean | "", param: string) => {
    return id !== "" ? `${param}=${id}&` : "";
};

export type RevertUserType = {
    token: string;
};

export const getUsers = ({
    currentPageNo,
    active,
    query,
    sortCol,
    isDescending,
    subtrajectoryId,
    functionId,
    userGroupId
}: UsersListType) =>
    ky.get(
        `Users/getAll?${urlHelper(subtrajectoryId, "SubtrajectoryId")}${urlHelper(
            functionId,
            "FunctionId"
        )}${urlHelper(
            active,
            "Active"
        )}PageNumber=${currentPageNo}&SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10${userGroupId ? `&UserGroupId=${userGroupId}` : ``}`
    );

export type DeleteUserType = {
    id: string;
};

export const deleteUser = ({ id }: DeleteUserType) =>
    ky.delete(`Users?id=${id}`);

export const getBasicUser = () => ky.get(`Users/getBasic`);

export const addUser = ({
    id,
    idToken,
    idInstance,
    name,
    email,
    password,
    phone,
    idUsergroup,
    active,
    inOverview,
    directLinkCode,
    anonymous,
    canLogin,
    deleted,
    functionId,
    sendEmail,
    userSubtrajectoriesDto
}: BasicUserModel) =>
    ky.post(`Users/add`, {
        json: {
            id,
            idToken,
            idInstance,
            name,
            email,
            password,
            phone,
            idUsergroup,
            active,
            inOverview,
            directLinkCode,
            anonymous,
            canLogin,
            deleted,
            functionId,
            sendEmail,
            userSubtrajectoriesDto
        },
    });

export const getUserFunctions = () => ky.get(`Functions/show`);

export type GetUserType = {
    id?: string;
};
export const getOneUser = ({ id }: GetUserType) => ky.get(`Users/${id}`);

export const editUser = ({
    id,
    idToken,
    idInstance,
    name,
    email,
    password,
    phone,
    idUsergroup,
    active,
    inOverview,
    directLinkCode,
    anonymous,
    canLogin,
    deleted,
    functionId,
    sendEmail,
    userSubtrajectoriesDto
}: BasicUserModel) =>
    ky.put(`Users`, {
        json: {
            id,
            idToken,
            idInstance,
            name,
            email,
            password,
            phone,
            idUsergroup,
            active,
            inOverview,
            directLinkCode,
            anonymous,
            canLogin,
            deleted,
            functionId,
            sendEmail,
            userSubtrajectoriesDto
        },
    });

export const getUsersList = () => ky.get(`Users/getAllToShow`);

export const getUserIp = () => ky.get(`Users/ip`);

export const getProfile = () => ky.get(`Users/profile`);

export type ProfileModel = {
    id: number;
    name: string;
    email: string;
    currentPassword?: string;
    firstPassword?: string;
    secondPassword?: string;
    phone?: string;
    rememberFilters?: boolean;
};

export const updateProfile = ({
    id,
    name,
    email,
    currentPassword,
    firstPassword,
    secondPassword,
    phone,
    rememberFilters,
}: ProfileModel) =>
    ky.put(`Users/profile`, {
        json: {
            id,
            name,
            email,
            currentPassword,
            firstPassword,
            secondPassword,
            phone,
            rememberFilters,
        },
    });

export type SubtrajectoryIdType = {
    subtrajectoryId: number;
};

export const getForSubtrajectory = ({ subtrajectoryId }: SubtrajectoryIdType) =>
    ky.get(`Users/getForSubtrajectory?subtrajectoryId=${subtrajectoryId}`);

export const getForActions = () => ky.get(`Users/getForAction`);

export const getForEditActions = ({ code }: TestingsType) =>
    ky.get(`Users/getForSubtrajectoryByTestingCode?testingCode=${code}`)

export const getUserSignalsList = ({ id }: GetUserType) => ky.get(`Users/Signals/${id}`);
export const getUserActionsList = ({ id }: GetUserType) => ky.get(`Users/Actions/${id}`);

export const revertUser = ({ token }: RevertUserType) =>
    ky.get(`Users/revertUser?token=${token}`);