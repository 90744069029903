import ky from "../ky";

export type LangCode = {
  languageCode: string;
};

export const getEmailsDefinitoins = ({ languageCode }: LangCode) =>
  ky.get(`EmailDefinitions?languageCode=${languageCode}`);

export type EmailDefinitionType = {
  id: number;
  email: boolean | string;
  senderEmail: string;
  emailDefinitionTextDto: EmailTextType;
};

export type EmailTextType = {
  senderEmail: string;
  title: string;
  emailTitle?: string | null;
  emailText?: string | null;
  emailHtml?: string | null;
  smsText?: string | null;
};

export type UpdateEmailType = {
  languageCode: string;
  data: EmailDefinitionType;
};

export const updateEmailDefinition = ({
  languageCode,
  data,
}: UpdateEmailType) =>
  ky.put(`EmailDefinitions?languageCode=${languageCode}`, { json: data });

export type ForSubcategoryType = {
  languageCode: string;
  subtrajectoryId: number;
};

export const emailListForSubCategory = ({
  languageCode,
  subtrajectoryId,
}: ForSubcategoryType) =>
  ky.get(
    `EmailDefinitions/forSubtrajectory?languageCode=${languageCode}&subtrajectoryId=${subtrajectoryId}`
  );

export type UpdateForSubcategoryType = {
  languageCode: string;
  subtrajectoryId: number;
  data: EmailDefinitionType;
};

export const updateEmailForSubcategory = ({
  languageCode,
  subtrajectoryId,
  data,
}: UpdateForSubcategoryType) =>
  ky.put(
    `EmailDefinitions/forSubtrajectory?languageCode=${languageCode}&subtrajectoryId=${subtrajectoryId}`,
    { json: data }
  );

export type GetOneEmailType = {
  id: number;
  languageCode: string;
};

export const getOneEmail = ({ id, languageCode }: GetOneEmailType) =>
  ky.get(`EmailDefinitions/${id}?languageCode=${languageCode}`);

export type GetOneEmailForSubcategoryType = {
  id: number;
  languageCode: string;
  subtrajectoryId: number;
};

export const getOneEmailForSubcategory = ({
  id,
  languageCode,
  subtrajectoryId,
}: GetOneEmailForSubcategoryType) =>
  ky.get(
    `EmailDefinitions/forSubtrajectory/${id}?languageCode=${languageCode}&subtrajectoryId=${subtrajectoryId}`
  );
