import { thunk, Thunk, Action, action } from "easy-peasy";

import {
    EmailDefinitionType,
    UpdateForSubcategoryType as UpdateForSubcatType,
    ForSubcategoryType,
    UpdateEmailType,
    getOneEmailForSubcategory,
    GetOneEmailForSubcategoryType,
    GetOneEmailType,
    emailListForSubCategory,
    getEmailsDefinitoins,
    getOneEmail,
    updateEmailDefinition,
    updateEmailForSubcategory,
    LangCode
} from "./emails.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

export type EmailListItem = {
    id: number;
    title: string;
};

export type EmailDefinitionModel = {
    getEmailsDefinitoins: Thunk<EmailDefinitionModel, LangCode>;
    updateEmailDefinition: Thunk<EmailDefinitionModel, UpdateEmailType>;
    emailListForSubCategory: Thunk<EmailDefinitionModel, ForSubcategoryType>;
    updateEmailForSubcategory: Thunk<EmailDefinitionModel, UpdateForSubcatType>;
    getOneEmail: Thunk<EmailDefinitionModel, GetOneEmailType>;
    getOneEmailForSubcategory: Thunk<
        EmailDefinitionModel,
        GetOneEmailForSubcategoryType
    >;
    error: ErrorEntry;
    setError: Action<EmailDefinitionModel, ErrorEntry>;
    delError: Action<EmailDefinitionModel>;
};

const emails: EmailDefinitionModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getEmailsDefinitoins: thunk(async (actions, payload) => {
        return fetchResponse<EmailListItem[]>({
            payload,
            actions,
            request: getEmailsDefinitoins,
        });
    }),
    updateEmailDefinition: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateEmailDefinition,
            message: "Email has been edited",
        });
    }),
    emailListForSubCategory: thunk(async (actions, payload) => {
        return fetchResponse<EmailListItem[]>({
            actions,
            payload,
            request: emailListForSubCategory,
        });
    }),
    updateEmailForSubcategory: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateEmailForSubcategory,
            message: "Email has been edited",
        });
    }),
    getOneEmail: thunk(async (actions, payload) => {
        return fetchResponse<EmailDefinitionType>({
            actions,
            payload,
            request: getOneEmail,
        });
    }),
    getOneEmailForSubcategory: thunk(async (actions, payload) => {
        return fetchResponse<EmailDefinitionType>({
            actions,
            payload,
            request: getOneEmailForSubcategory,
        });
    }),
};

export default emails;
