export type FetchBasicProps = {
  actions: any;
  payload?: object;
  request: any;
  message: string;
};

export const fetchBasic = async ({
  actions,
  payload,
  request,
  message,
}: FetchBasicProps) => {
  try {
    const response = await request(payload);
    if (response.status === 200) {
      actions.setError({
        code: response.status,
        message,
      });
    } else if (response.status === 204) {
      actions.setError({
        code: response.status,
        message: "No data",
      });
    }
    return response;
  } catch (err) {
    console.log(err);
    if (err.response) {
      const mess = await err.response.json();
      actions.setError({
        code: err.response.status,
        message: mess.message,
      });
    }
  }
};

export type FetchResponseProps = {
  actions: any;
  payload?: object;
  request: any;
};
export const fetchResponse = async <T>({
  actions,
  payload,
  request,
}: FetchResponseProps) => {
  try {
    const response = await request(payload);
    if (response.status === 204) {
      actions.setError({
        code: response.status,
        message: "No data",
      });
    } else {
      const parsed = (await response.json()) as T;
      return parsed;
    }
  } catch (err) {
    console.log(err);
    if (err.response.status !== 200) {
      const mess = await err.response.json();
      actions.setError({
        code: err.response.status,
        message: mess.message,
      });
    }
  }
};

export const fetchFile = async ({
    actions,
    payload,
    request,
}: FetchResponseProps) => {
    try {
        const response = await request(payload);
        if (response.status === 204) {
            actions.setError({
                code: response.status,
                message: "No data",
            });
        } else {
            const parsed = await response.blob();
            return parsed;
        }
    } catch (err) {
        console.log(err);
        if (err.response.status !== 200) {
            const mess = await err.response.json();
            actions.setError({
                code: err.response.status,
                message: mess.message,
            });
        }
    }
};
