import { thunk, Thunk, Action, action } from "easy-peasy";
import {
  getQCategories,
  QuestionnaireIdType,
  updateQCategory,
  QCategoryModel,
  deleteQCategory,
  QCategoryIdType,
  getQCategorie,
  addQCategory,
  qCategoryPosition,
  QCategoryPositionType,
  getQCategoryeName,
  updateCategoriesPositions,
} from "./qCategories.service";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";
import { ErrorEntry } from "../users/users.model";

export type BasicQCategoryModel = {
  id: number;
  title: string;
  spsslabel: string;
  position: number;
};

export type DataNameIdType = {
  questionnaireId: number;
  questionnaireName: string;
  categoryId: number;
  categoryName: string;
  lock?: boolean;
};

export type QCategoriesModel = {
  getQCategories: Thunk<QCategoriesModel, QuestionnaireIdType>;
  updateQCategory: Thunk<QCategoriesModel, QCategoryModel>;
  deleteQCategory: Thunk<QCategoriesModel, QCategoryIdType>;
  getQCategorie: Thunk<QCategoriesModel, QCategoryIdType>;
  addQCategory: Thunk<QCategoriesModel, QCategoryModel>;
  qCategoryPosition: Thunk<QCategoriesModel, QCategoryPositionType>;
  getQCategoryeName: Thunk<QCategoriesModel, QCategoryIdType>;
  updateCategoriesPositions: Thunk<QCategoriesModel, BasicQCategoryModel[]>;
  error: ErrorEntry;
  setError: Action<QCategoriesModel, ErrorEntry>;
  delError: Action<QCategoriesModel>;
};

const qCategories: QCategoriesModel = {
  error: { code: 0 },
  setError: action((state, payload) => {
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),
  getQCategories: thunk(async (actions, payload) => {
    return fetchResponse<BasicQCategoryModel[]>({
      actions,
      payload,
      request: getQCategories,
    });
  }),
  updateQCategory: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: updateQCategory,
      message: "Category has been edited",
    });
  }),
  deleteQCategory: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: deleteQCategory,
      message: "Category has been deleted",
    });
  }),
  getQCategorie: thunk(async (actions, payload) => {
    return fetchResponse<QCategoryModel>({
      actions,
      payload,
      request: getQCategorie,
    });
  }),
  addQCategory: thunk(async (actions, payload) => {
      return fetchResponse<number>({
      actions,
      payload,
      request: addQCategory,
    });
  }),
  qCategoryPosition: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: qCategoryPosition,
      message: "Position has been changed",
    });
  }),
  getQCategoryeName: thunk(async (actions, payload) => {
    return fetchResponse<DataNameIdType>({
      actions,
      payload,
      request: getQCategoryeName,
    });
  }),
  updateCategoriesPositions: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: updateCategoriesPositions,
      message: "Positions has been edited",
    });
  }),
};

export default qCategories;
