import { thunk, Thunk, Action, action } from "easy-peasy";

import {
    getDeleteReasons,
    editDeleteReason,
    deleteDeleteReason,
    getDeleteReason,
    addDeleteReason,
    updateDeleteReasonsPositions
} from "./deleteReasons.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

type ID = {
    id: number;
};

export type BasicDeleteReasonModel = ID & {
    title: string;
    active: boolean | string;
    position: number;
};

export type DeleteReasonsModel = {
    getDeleteReasons: Thunk<DeleteReasonsModel>;
    editDeleteReason: Thunk<DeleteReasonsModel, BasicDeleteReasonModel>;
    deleteDeleteReason: Thunk<DeleteReasonsModel>;
    getDeleteReason: Thunk<DeleteReasonsModel, ID>;
    addDeleteReason: Thunk<DeleteReasonsModel, BasicDeleteReasonModel>;
    updateDeleteReasonsPositions: Thunk<DeleteReasonsModel, BasicDeleteReasonModel[]>;
    error: ErrorEntry;
    setError: Action<DeleteReasonsModel, ErrorEntry>;
    delError: Action<DeleteReasonsModel>;
};

const deleteReasons: DeleteReasonsModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getDeleteReasons: thunk(async (actions, payload) => {
        return fetchResponse<BasicDeleteReasonModel[]>({
            actions,
            request: getDeleteReasons,
        });
    }),
    getDeleteReason: thunk(async (actions, payload) => {
        return fetchResponse<BasicDeleteReasonModel>({
            actions,
            payload,
            request: getDeleteReason,
        });
    }),
    deleteDeleteReason: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            request: deleteDeleteReason,
            message: "Delete Reason has been deleted",
        });
    }),
    addDeleteReason: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addDeleteReason,
            message: "Delete Reason has been added",
        });
    }),
    editDeleteReason: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: editDeleteReason,
            message: "Delete Reason has been edited",
        });
    }),
    updateDeleteReasonsPositions: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateDeleteReasonsPositions,
            message: "Delete Reason positions have been edited",
        });
    }),
};

export default deleteReasons;
