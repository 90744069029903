import { thunk, Thunk, Action, action } from "easy-peasy";
import {
    getPopupsList,
    readOff,
    PopupsId,
    LanguageCode,
    PopupListType,
    getMyPopupList,
    getMyPopupUnread
} from "./popups.service";
import { ErrorEntry } from "../users/users.model";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";

export type PopupItem = {
    id: number;
    title: string;
    text: string;
    date: string;
    readOff: boolean;
};

export type Popups = {
    items: PopupItem[]
}

export type DefPopups = {
    currentPage: number,
    totalPages: number,
    pageSize: number,
    totalCount: number,
    count: number,
    items: PopupItem[],
    hasPrevious: boolean,
    hasNext: boolean
};

export type UserPopupModel = {
    error: ErrorEntry;
    readOffCount: number;
    setReadOffCount: Action<UserPopupModel, number>;

    setError: Action<UserPopupModel, ErrorEntry>;
    delError: Action<UserPopupModel>;

    getPopups: Thunk<UserPopupModel, LanguageCode>;
    readOff: Thunk<UserPopupModel, PopupsId>;
    getMyPopupList: Thunk<UserPopupModel, PopupListType>;
    getMyPopupUnread: Thunk<UserPopupModel>;
};

const popups: UserPopupModel = {
    readOffCount: 0,
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getPopups: thunk(async (actions, payload) => {
        return fetchResponse<PopupItem>({
            actions,
            payload,
            request: getPopupsList,
        });
    }),
    readOff: thunk(async (actions, payload) => {
        const response = await fetchBasic({
            actions,
            payload,
            request: readOff,
            message: "Popup has been readed",
        });
        actions.getMyPopupUnread();
        return response;
    }),
    getMyPopupList: thunk(async (actions, payload) => {
        return fetchResponse<DefPopups>({
            actions,
            payload,
            request: getMyPopupList,
        });
    }),
    setReadOffCount: action((state, payload) => {
        state.readOffCount = payload;
    }),
    getMyPopupUnread: thunk(async (actions) => {
        let response = await fetchResponse<number>({
            actions,
            request: getMyPopupUnread,
        });
        if (response === undefined) {
            actions.setReadOffCount(0);
        } else {
            actions.setReadOffCount(response);
        }
    }),
};

export default popups;
