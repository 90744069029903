import { thunk, Thunk, Action, action } from "easy-peasy";
import {
    ThemeDefId,
    EditThemeDefType,
    getThemeDefList,
    updateThemeDefinition,
    deleteThemeDefinition,
    getOneThemeDefinition,
    addThemeDefinition,
    getBasicThemeDefinition,
} from "./defThemes.service";
import { ErrorEntry } from "../users/users.model";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";

export type ThemeDefinitionType = {
    id: number;
    name: string;
};

export type DefThemeModel = {
    getThemeDefList: Thunk<DefThemeModel>;
    updateThemeDefinition: Thunk<DefThemeModel, EditThemeDefType>;
    addThemeDefinition: Thunk<DefThemeModel, EditThemeDefType>;
    getBasicThemeDefinition: Thunk<DefThemeModel>;
    deleteThemeDefinition: Thunk<DefThemeModel, ThemeDefId>;
    getOneThemeDefinition: Thunk<DefThemeModel, ThemeDefId>;
    error: ErrorEntry;
    setError: Action<DefThemeModel, ErrorEntry>;
    delError: Action<DefThemeModel>;
};

const defThemes: DefThemeModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getThemeDefList: thunk(async (actions) => {
        return fetchResponse<ThemeDefinitionType[]>({
            actions,
            request: getThemeDefList,
        });
    }),
    updateThemeDefinition: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateThemeDefinition,
            message: "Definition has been edited",
        });
    }),
    deleteThemeDefinition: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteThemeDefinition,
            message: "Definition has been deleted",
        });
    }),
    getOneThemeDefinition: thunk(async (actions, payload) => {
        return fetchResponse<ThemeDefinitionType>({
            actions,
            payload,
            request: getOneThemeDefinition,
        });
    }),
    addThemeDefinition: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addThemeDefinition,
            message: "Definition has been added",
        });
    }),
    getBasicThemeDefinition: thunk(async (actions) => {
        return fetchResponse<ThemeDefinitionType>({
            actions,
            request: getBasicThemeDefinition,
        });
    }),
};

export default defThemes;
