import ky from "../ky";

import {
  InstanceModelBasic,
  InstanceIpRes,
  InstanceOptions,
} from "../../pages/portal/options/instances/model";
import { LangEntry } from "../languages/languages.model";

export type InstanListType = {
  currentPageNo: number;
  search: string;
  sortCol: string;
  isDescending: boolean;
};

export const getInstancesList = ({
  isDescending,
  currentPageNo,
  search,
  sortCol,
}: InstanListType) =>
  ky.get(
    `Instances?PageNumber=${currentPageNo}&SearchWord=${search}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10`
  );

export const getBasicInstance = () => ky.get(`Instances/information/1`);

export type AddInstanceType = {
  instanceDto: InstanceModelBasic;
  instanceIpRestrictionsDto: InstanceIpRes[];
  instanceIpPoliclinicsDto: InstanceIpRes[];
  instanceOptionsDto: InstanceOptions;
  languagesDto: LangEntry[];
};

export const addInstance = ({
  instanceDto,
  instanceIpRestrictionsDto,
  instanceIpPoliclinicsDto,
  instanceOptionsDto,
  languagesDto,
}: AddInstanceType) =>
  ky.post(`Instances/add`, {
    json: {
      instanceDto,
      instanceIpRestrictionsDto,
      instanceIpPoliclinicsDto,
      instanceOptionsDto,
      languagesDto,
    },
  });

export type DeleteInstanceType = {
  id: number;
};

export const deleteInstance = ({ id }: DeleteInstanceType) =>
  ky.delete(`Instances/${id}`);

export type OneInstanceType = {
  id: number;
};

export const getOneInstance = ({ id }: OneInstanceType) =>
  ky.get(`Instances/information/${id}`);

export const updateInstance = ({
  instanceDto,
  instanceIpRestrictionsDto,
  instanceIpPoliclinicsDto,
  instanceOptionsDto,
  languagesDto,
}: AddInstanceType) =>
  ky.put(`Instances`, {
    json: {
      instanceDto,
      instanceIpRestrictionsDto,
      instanceIpPoliclinicsDto,
      instanceOptionsDto,
      languagesDto,
    },
  });

export const getListToShow = () => ky.get(`Instances/show`);
