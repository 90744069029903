import { thunk, Thunk, Action, action } from "easy-peasy";

import {
  FunctionsListType,
  FunctionsType,
  IdFunctionsType,
  addFunctions,
  deleteFunctions,
  getFunctionsList,
  getFunctionsL,
  getOneFunctions,
  updateFunctions,
  getFunctionsToShow,
} from "./functions.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

export type FunctionList = {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  count: number;
  items: FunctionItem[];
  hasPrevious: boolean;
  hasNext: boolean;
};

export type FunctionItem = {
  id: number;
  name: string;
};

export type FunctionsModel = {
  getFunctionsList: Thunk<FunctionsModel, FunctionsListType>;
  deleteFunctions: Thunk<FunctionsModel, IdFunctionsType>;
  addFunctions: Thunk<FunctionsModel, FunctionsType>;
  getOneFunctions: Thunk<FunctionsModel, IdFunctionsType>;
  updateFunctions: Thunk<FunctionsModel, FunctionsType>;
  getFunctionsL: Thunk<FunctionsModel>;
  getFunctionsToShow: Thunk<FunctionsModel>;
  error: ErrorEntry;
  setError: Action<FunctionsModel, ErrorEntry>;
  delError: Action<FunctionsModel>;
};

const functions: FunctionsModel = {
  error: { code: 0 },
  setError: action((state, payload) => {
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),
  getFunctionsList: thunk(async (actions, payload) => {
    return fetchResponse<FunctionList>({
      actions,
      payload,
      request: getFunctionsList,
    });
  }),
  getFunctionsL: thunk(async (actions) => {
    return fetchResponse<FunctionList>({
      actions,
      request: getFunctionsL,
    });
  }),
  deleteFunctions: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: deleteFunctions,
      message: "Function has been deleted",
    });
  }),
  addFunctions: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: addFunctions,
      message: "Function has been added",
    });
  }),
  getOneFunctions: thunk(async (actions, payload) => {
    return fetchResponse<FunctionItem>({
      actions,
      payload,
      request: getOneFunctions,
    });
  }),
  getFunctionsToShow: thunk(async (actions) => {
    return fetchResponse<FunctionItem[]>({
      actions,
      request: getFunctionsToShow,
    });
  }),
  updateFunctions: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: updateFunctions,
      message: "Function has been edited",
    });
  }),
};

export default functions;
