import { thunk, Thunk, Action, action } from "easy-peasy";
import {
    getSubCategories,
    SubCategoryListType,
    SubcategoryId,
    SubcategoryModel,
    updateSubcategory,
    getOneSubcategory,
    getBasicSubcategory,
    subcetegoriesToShow,
    deleteSubcategory,
    addSubcategory,
    TrajectoryId,
    UpdateSubcatModel,
    listForTrajectory,
    listForTrajectoryWithNotActive,
    listForTrajectoryOnStart,
    showOptions,
    showDoctors,
    showForUser,
    CodeParam,
    getIdByLink,
    IdParam,
    createNewOperation
} from "./subcategories.service";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";
import { ErrorEntry } from "../users/users.model";

export interface QSubcategoriesList {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    count: number;
    items: BasicSubcategoryModel[];
    hasPrevious: boolean;
    hasNext: boolean;
}

export type BasicSubcategoryModel = {
    id: number;
    title: string;
    trajectoryTitle: string;
    active: boolean;
    changedTexts: number;
};

export type ForTrajcetoryListItem = {
    id: number;
    title: string;
    hasEvaluationMoment: boolean;
    isProms: boolean;
    isActive?: boolean;
};

export type DoctorListItem = {
    doctorId: number;
    doctorName: string;
    active: boolean;
};

export type OptionListItem = {
    id: number;
    value: string;
};

export type StringItem = {
    val: string
};

export type SubcategoriesModel = {
    getSubCategories: Thunk<SubcategoriesModel, SubCategoryListType>;
    updateSubcategory: Thunk<SubcategoriesModel, UpdateSubcatModel>;
    deleteSubcategory: Thunk<SubcategoriesModel, SubcategoryId>;
    subcetegoriesToShow: Thunk<SubcategoriesModel>;
    getOneSubcategory: Thunk<SubcategoriesModel, SubcategoryId>;
    showOptions: Thunk<SubcategoriesModel, SubcategoryId>;
    showDoctors: Thunk<SubcategoriesModel, SubcategoryId>;
    addSubcategory: Thunk<SubcategoriesModel, UpdateSubcatModel>;
    getBasicSubcategory: Thunk<SubcategoriesModel, TrajectoryId>;
    listForTrajectory: Thunk<SubcategoriesModel, TrajectoryId>;
    listForTrajectoryWithNotActive: Thunk<SubcategoriesModel, TrajectoryId>;
    listForTrajectoryOnStart: Thunk<SubcategoriesModel, TrajectoryId>;
    showForUser: Thunk<SubcategoriesModel>;
    error: ErrorEntry;
    setError: Action<SubcategoriesModel, ErrorEntry>;
    delError: Action<SubcategoriesModel>;
    getIdByLink: Thunk<SubcategoriesModel, CodeParam>;
    createNewOperation: Thunk<SubcategoriesModel, IdParam>;
};

const subcategories: SubcategoriesModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getSubCategories: thunk(async (actions, payload) => {
        return fetchResponse<QSubcategoriesList>({
            actions,
            payload,
            request: getSubCategories,
        });
    }),
    updateSubcategory: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateSubcategory,
            message: "Subcategory has been edited",
        });
    }),
    deleteSubcategory: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteSubcategory,
            message: "Subcategory has been deleted",
        });
    }),
    subcetegoriesToShow: thunk(async (actions, payload) => {
        return fetchResponse<BasicSubcategoryModel[]>({
            actions,
            request: subcetegoriesToShow,
        });
    }),
    addSubcategory: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addSubcategory,
            message: "Subcategory has been added",
        });
    }),
    getBasicSubcategory: thunk(async (actions, payload) => {
        return fetchResponse<SubcategoryModel>({
            actions,
            payload,
            request: getBasicSubcategory,
        });
    }),
    getOneSubcategory: thunk(async (actions, payload) => {
        return fetchResponse<SubcategoryModel>({
            actions,
            payload,
            request: getOneSubcategory,
        });
    }),
    listForTrajectory: thunk(async (actions, payload) => {
        return fetchResponse<ForTrajcetoryListItem[]>({
            actions,
            payload,
            request: listForTrajectory,
        });
    }),
    listForTrajectoryWithNotActive: thunk(async (actions, payload) => {
        return fetchResponse<ForTrajcetoryListItem[]>({
            actions,
            payload,
            request: listForTrajectoryWithNotActive,
        });
    }),
    listForTrajectoryOnStart: thunk(async (actions, payload) => {
        return fetchResponse<ForTrajcetoryListItem[]>({
            actions,
            payload,
            request: listForTrajectoryOnStart,
        });
    }),
    showForUser: thunk(async (actions) => {
        return fetchResponse<ForTrajcetoryListItem[]>({
            actions,
            request: showForUser,
        });
    }),
    showDoctors: thunk(async (actions, payload) => {
        return fetchResponse<DoctorListItem[]>({
            actions,
            payload,
            request: showDoctors,
        });
    }),
    showOptions: thunk(async (actions, payload) => {
        return fetchResponse<OptionListItem[]>({
            actions,
            payload,
            request: showOptions,
        });
    }),
    getIdByLink: thunk(async (actions, payload) => {
        return fetchResponse<number>({
            actions,
            payload,
            request: getIdByLink,
        });
    }),
    createNewOperation: thunk(async (actions, payload) => {
        return fetchResponse<StringItem>({
            actions,
            payload,
            request: createNewOperation,
        });
    }),
};

export default subcategories;
