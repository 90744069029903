import { thunk, Thunk, Action, action } from "easy-peasy";
import {
    getCategoryQuestions,
    CategoryId,
    updateQuestion,
    EditQuestionType,
    updateQuestionText,
    EditQuestionTextType,
    deleteQuestion,
    QuestionId,
    getOneQuestion,
    addQuestions,
    getQuestionTypes,
    getMultipleChoiceQuestions,
    MultipleChoiceType,
    getBasicQuestion,
    GetBasicType,
    copyQuestion,
    CopyQuestionType,
    AddQuestionType,
    addSubQuestions,
    AddSubQuestionType,
    questionPosition,
    QPositionType,
    updateQuestionPositions,
    QuestionnaireId,
    getForResponsibility,
    previewQuestion,
    PreviewQuestionType,
} from "./questions.service";
import { ErrorEntry } from "../users/users.model";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";

export type QuestionBasicType = {
    id: number;
    title: string;
    spsslabel: string;
    obligated: boolean;
    active: boolean;
    questionTypeName: string;
    categoryId: number;
    propertyName: string;
    position: number;
    correct: boolean;
};

export type AnswearDefType = {
    id: number;
    spsslabel: string;
    active: boolean;
    questionId: number;
    openInterpretation: boolean;
    position: number;
    score: number;
    answerDefinitionTextDto: {
        value: string;
    };
};

export type QuestionOptionsType = {
    toon_waardes: number;
    toon_images: number;
    displaymode: 1 | 2 | 3 | 4;
    tekst_links: string;
    tekst_rechts: string;
};

export type QuestionTextType = {
    instruction: string;
    question: string;
};

export type QuestionBaseType = {
    id: number;
    name: string;
};

export type MultipleListType = {
    id: number;
    title: string;
    questionText: string;
};

export type QuestionType = {
    id: number;
    title: string;
    spsslabel: string;
    obligated: boolean;
    active: boolean;
    questionType: number;
    parentQuestion: number;
    categoryId: number;
    propertyId: number;
    questionnaireId: number;
    dependantAnswerId: number;
    dependantQuestionId: number;
    questionNumber: boolean;
    position: number;
    locked: boolean;
    dependantAnswers?: number[];
    displayAllways: boolean;
    questionTextDto?: QuestionTextType;
    questionOptions?: QuestionOptionsType;
    subquestions?: QuestionType[];
    answerDefinitionsDto?: AnswearDefType[];
};

export type QuestionModel = {
    getCategoryQuestions: Thunk<QuestionModel, CategoryId>;
    getOneQuestion: Thunk<QuestionModel, QuestionId>;
    addQuestions: Thunk<QuestionModel, AddQuestionType>;
    addSubQuestions: Thunk<QuestionModel, AddSubQuestionType>;
    deleteQuestion: Thunk<QuestionModel, QuestionId>;
    updateQuestion: Thunk<QuestionModel, EditQuestionType>;
    updateQuestionText: Thunk<QuestionModel, EditQuestionTextType>;
    getQuestionTypes: Thunk<QuestionModel>;
    getMultipleChoiceQuestions: Thunk<QuestionModel, MultipleChoiceType>;
    getBasicQuestion: Thunk<QuestionModel, GetBasicType>;
    copyQuestion: Thunk<QuestionModel, CopyQuestionType>;
    questionPosition: Thunk<QuestionModel, QPositionType>;
    updateQuestionPositions: Thunk<QuestionModel, QuestionBasicType[]>;
    getForResponsibility: Thunk<QuestionModel, QuestionnaireId>;
    getQuestionPreview: Thunk<QuestionModel, PreviewQuestionType>;
    error: ErrorEntry;
    setError: Action<QuestionModel, ErrorEntry>;
    delError: Action<QuestionModel>;
};

const questions: QuestionModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getCategoryQuestions: thunk(async (actions, payload) => {
        return fetchResponse<QuestionBasicType[]>({
            actions,
            payload,
            request: getCategoryQuestions,
        });
    }),
    getOneQuestion: thunk(async (actions, payload) => {
        return fetchResponse<QuestionType>({
            actions,
            payload,
            request: getOneQuestion,
        });
    }),
    updateQuestion: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateQuestion,
            message: "Question has been edited",
        });
    }),
    updateQuestionText: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateQuestionText,
            message: "Question has been edited",
        });
    }),
    deleteQuestion: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteQuestion,
            message: "Question has been deleted",
        });
    }),
    addQuestions: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addQuestions,
            message: "Questions has been added",
        });
    }),
    addSubQuestions: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addSubQuestions,
            message: "Subquestions has been added",
        });
    }),
    getQuestionTypes: thunk(async (actions) => {
        return fetchResponse<QuestionBaseType[]>({
            actions,
            request: getQuestionTypes,
        });
    }),
    getMultipleChoiceQuestions: thunk(async (actions, payload) => {
        return fetchResponse<MultipleListType[]>({
            actions,
            payload,
            request: getMultipleChoiceQuestions,
        });
    }),
    getForResponsibility: thunk(async (actions, payload) => {
        return fetchResponse<MultipleListType[]>({
            actions,
            payload,
            request: getForResponsibility,
        });
    }),
    getBasicQuestion: thunk(async (actions, payload) => {
        return fetchResponse<QuestionType>({
            actions,
            payload,
            request: getBasicQuestion,
        });
    }),
    copyQuestion: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: copyQuestion,
            message: "Question has been copied",
        });
    }),
    questionPosition: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: questionPosition,
            message: "Position has been changed",
        });
    }),
    updateQuestionPositions: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateQuestionPositions,
            message: "Positions has been edited",
        });
    }),
    getQuestionPreview: thunk(async (actions, payload) => {
        return fetchResponse<QuestionType>({
            actions,
            payload,
            request: previewQuestion,
        });
    }),
};

export default questions;
