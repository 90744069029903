import { ThemeConfig } from './types';

export const h13: ThemeConfig = {
    tenant: 'h13',
    baseColor: '#FBBC63',
    navColor: '#ffffff',
    textColor: '#444',
    hoverColor: '#2C8895',
    disabled: '#999999',
    inputBgColor: "#FBFBFC",
    matrixHoverColor: '#2C8895',
    matrixHoverTextColor: '#444',
    matrixOddBgColor: '#FBFBFC',
    matrixOddTextColor: '#444'
};

export default h13;

/*
@text_link:             #FBBC63;
@text_link_response:    #2C8895;
*/