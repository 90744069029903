import ky from "../ky";

import { AnswerType } from "./defAnswers.model";

export type LanguageCodeType = {
    languageCode: string;
};

export type EditAnsType = {
    languageCode: string;
    data: AnswerType;
};

export type EditAnsTypeDef = {
    languageCode: string;
    data: AnswerType;
    questionId: number;
};

export type AnsId = {
    id?: string | number;
    languageCode?: string;
};

export type ImportAnswerType = {
    languageCode: string,
    data: FormData
};

export type ImportAnswerFinalType = {
    importAnswer: string[][],
    languageCode: string,
    languageCode2: string
};

export const getAnsList = ({ languageCode }: LanguageCodeType) =>
    ky.get(`DefAnswers?languageCode=${languageCode}`);

export const updateAnswer = ({ languageCode, data }: EditAnsType) =>
    ky.put(`DefAnswers?languageCode=${languageCode}`, { json: data });

export const deleteAnswer = ({ id }: AnsId) => ky.delete(`DefAnswers?id=${id}`);

export const getOneAnswer = ({ id, languageCode }: AnsId) =>
    ky.get(`DefAnswers/${id}?languageCode=${languageCode}`);

export const addAnswer = ({ languageCode, data }: EditAnsType) =>
    ky.post(`DefAnswers/add?languageCode=${languageCode}`, { json: data });

export const addAnswerDef = ({ languageCode, data, questionId }: EditAnsTypeDef) =>
    ky.post(`DefAnswers/addDef?languageCode=${languageCode}&questionId=${questionId}`, { json: data });

export const getBasicAns = () =>
    ky.get(`DefAnswers/basic`);

export const importAnswers = ({ languageCode, data }: ImportAnswerType) =>
    ky.post(`DefAnswers/Import/Get?languageCode=${languageCode}`, { body: data });

export const importAnswersFinal = ({ languageCode, languageCode2, importAnswer }: ImportAnswerFinalType) =>
    ky.post(`DefAnswers/addMany?languageCode=${languageCode}&languageCode2=${languageCode2}`, { json: { data: importAnswer } });
