import { thunk, Thunk, Action, action } from "easy-peasy";
import {
  getInstancesList,
  InstanListType,
  getBasicInstance,
  addInstance,
  AddInstanceType,
  deleteInstance,
  DeleteInstanceType,
  getOneInstance,
  OneInstanceType,
  updateInstance,
  getListToShow,
} from "./instances.service";
import { InstanceModel } from "../../pages/portal/options/instances/model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";
import { ErrorEntry } from "../users/users.model";

export interface InstanceResponse {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  count: number;
  items: InstanceEntry[];
  hasPrevious: boolean;
  hasNext: boolean;
}

export type InstanceEntry = {
  id: number;
  name: string;
  url: string;
};

export type InstancesModel = {
  getInstancesList: Thunk<InstancesModel, InstanListType>;
  getBasicInstance: Thunk<InstancesModel>;
  addInstance: Thunk<InstancesModel, AddInstanceType>;
  updateInstance: Thunk<InstancesModel, AddInstanceType>;
  deleteInstance: Thunk<InstancesModel, DeleteInstanceType>;
  getOneInstance: Thunk<InstancesModel, OneInstanceType>;
  getListToShow: Thunk<InstancesModel>;
  error: ErrorEntry;
  setError: Action<InstancesModel, ErrorEntry>;
  delError: Action<InstancesModel>;
};

const instanceList: InstancesModel = {
  error: { code: 0 },
  setError: action((state, payload) => {
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),
  getInstancesList: thunk(async (actions, payload) => {
    return fetchResponse<InstanceResponse>({
      actions,
      payload,
      request: getInstancesList,
    });
  }),
  getBasicInstance: thunk(async (actions) => {
    return fetchResponse<InstanceModel>({
      actions,
      request: getBasicInstance,
    });
  }),
  addInstance: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: addInstance,
      message: "Instance has been added",
    });
  }),
  updateInstance: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: updateInstance,
      message: "Instance has been edited",
    });
  }),
  deleteInstance: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: deleteInstance,
      message: "The hospital has been removed",
    });
  }),
  getOneInstance: thunk(async (actions, payload) => {
    return fetchResponse<InstanceModel>({
      actions,
      payload,
      request: getOneInstance,
    });
  }),
  getListToShow: thunk(async (actions, payload) => {
    return fetchResponse<InstanceEntry[]>({
      actions,
      request: getListToShow,
    });
  }),
};

export default instanceList;
