import ky from "../ky";
import { SubDefinitionType } from "./defSubquestions.model";

export type LanguageCodeType = {
  languageCode: string;
};

export type EditSubDefType = {
  languageCode: string;
  data: SubDefinitionType;
};

export type EditSubDefTypeDef = {
    languageCode: string;
    data: SubDefinitionType;
    questionId: number;
};

export type SubDefId = {
  id?: string | number;
  languageCode?: string;
};

export type ImportSubquestionType = {
    languageCode: string,
    data: FormData
};

export type ImportSubquestionFinalType = {
    importSubquestion: string[][],
    languageCode: string,
    languageCode2: string
};

export const getSubDefList = ({ languageCode }: LanguageCodeType) =>
  ky.get(`DefSubquestions?languageCode=${languageCode}`);

export const updateSubDefinition = ({ languageCode, data }: EditSubDefType) =>
  ky.put(`DefSubquestions?languageCode=${languageCode}`, { json: data });

export const deleteSubDefinition = ({ id }: SubDefId) =>
  ky.delete(`DefSubquestions?id=${id}`);

export const getOneSubDefinition = ({ id, languageCode }: SubDefId) =>
  ky.get(`DefSubquestions/${id}?languageCode=${languageCode}`);

export const addSubDefinition = ({ languageCode, data }: EditSubDefType) =>
    ky.post(`DefSubquestions/add?languageCode=${languageCode}`, { json: data });

export const addSubDefinitionDef = ({ languageCode, data, questionId }: EditSubDefTypeDef) =>
    ky.post(`DefSubquestions/addDef?languageCode=${languageCode}&questionId=${questionId}`, { json: data });

export const getBasicSubDefinition = () => ky.get(`DefSubquestions/basic`);

export const importSubquestions = ({ languageCode, data }: ImportSubquestionType) =>
    ky.post(`DefSubquestions/Import/Get?languageCode=${languageCode}`, { body: data });

export const importSubquestionsFinal = ({ languageCode, languageCode2, importSubquestion }: ImportSubquestionFinalType) =>
    ky.post(`DefSubquestions/addMany?languageCode=${languageCode}&languageCode2=${languageCode2}`, { json: { data: importSubquestion } });