import ky from "../ky";

export type ActionStatusListType = {
    currentPageNo: number;
    query: string;
    sortCol: string;
    isDescending: boolean;
};

export const getActionStatusList = ({
    currentPageNo,
    query,
    sortCol,
    isDescending,
}: ActionStatusListType) =>
    ky.get(
        `ActionStatus?PageNumber=${currentPageNo}&SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10`
    );

export const getActionStatusL = () => ky.get(`ActionStatus`);

export type IdActionStatusType = {
    id?: string | number;
};

export const deleteActionStatus = ({ id }: IdActionStatusType) =>
    ky.delete(`ActionStatus?id=${id}`);

export type ActionStatusType = {
    id?: number;
    title: string;
};

export type ImportType = {
    languageCode: string,
    data: FormData
};

export type ImportFinalType = {
    importData: string[][]
};

export const addActionStatus = ({ title }: ActionStatusType) =>
    ky.post(`ActionStatus/add`, { json: { title } });

export const updateActionStatus = ({ title, id }: ActionStatusType) =>
    ky.put(`ActionStatus`, { json: { title, id } });

export const getOneActionStatus = ({ id }: IdActionStatusType) =>
    ky.get(`ActionStatus/${id}`);

export const getForActions = () => ky.get(`ActionStatus/ToShow`);

export const importData = ({ languageCode, data }: ImportType) =>
    ky.post(`ActionStatus/Import/Get?languageCode=${languageCode}`, { body: data });

export const importDataFinal = ({ importData }: ImportFinalType) =>
    ky.post(`ActionStatus/addMany`, { json: { data: importData } });