import ky from "../ky";

export const getQuery = () => ky.get(`QueryProperties`);

export type QueryType = {
  id?: number;
  title: string;
  spsslabel: string;
  type: number | null;
  active?: boolean | null;
  monthView?: boolean | null;
  locked?: boolean;
};

export const addQuery = ({ title, spsslabel, type, active, monthView }: QueryType) =>
  ky.post(`QueryProperties/add`, {
      json: { title, spsslabel, type, active, monthView },
  });

export type EditQueryType = {
  id?: string | number;
};

export const editQuery = ({ id }: EditQueryType) =>
  ky.get(`QueryProperties/${id}`);

export const updateQuery = ({
  id,
  title,
  spsslabel,
  type,
  active,
  monthView,
  locked,
}: QueryType) =>
  ky.put(`QueryProperties`, {
      json: { id, title, spsslabel, type, active, monthView, locked },
  });

export const delQuery = ({ id }: EditQueryType) =>
  ky.delete(`QueryProperties?id=${id}`);

export const getQueryTypes = () => ky.get(`QueryProperties/types`);

export const propertiesToShow = () => ky.get(`QueryProperties/show`);

export const getPropertyList = () =>
    ky.get(`QueryProperties/monthView`, { timeout: 30000 });
