import { thunk, Thunk, action, Action } from "easy-peasy";
import { getMenu, getUserPermissions, getUserPosition } from "./menu.service";

export type MenuType = {
    name: string;
    label: string;
    submenu?: { name: string; label: string };
};

export type UserPermissionType = {
    className: string;
    permissionName: string;
};

export type MenuModel = {
    menu: MenuType[];
    setMenu: Action<MenuModel, MenuType[]>;
    getMenu: Thunk<MenuModel>;
    userPermissions: UserPermissionType[];
    getUserPermissions: Thunk<MenuModel>;
    setUserPermissions: Action<MenuModel, UserPermissionType[]>;
    userPosition: number;
    getUserPosition: Thunk<MenuModel>;
    setUserPosition: Action<MenuModel, number>;
};



const menu: MenuModel = {
    menu: [],
    userPermissions: [],
    userPosition: 5,
    setMenu: action((state, payload) => {
        state.menu = [...payload];
    }),
    setUserPermissions: action((state, payload) => {
        state.userPermissions = [...payload];
    }),
    setUserPosition: action((state, payload) => {
        state.userPosition = payload;
    }),
    getMenu: thunk(async (actions, payload) => {
        try {
            const response = (await getMenu().json()) as MenuType[];
            actions.setMenu(response);
            return response;
        } catch (error) {
            console.log(error.message);
        }
    }),
    getUserPermissions: thunk(async (actions, payload) => {
        try {
            const response = (await getUserPermissions().json()) as UserPermissionType[];
            actions.setUserPermissions(response);
            return response;
        } catch (error) {
            console.log(error.message);
        }
    }),
    getUserPosition: thunk(async (actions, payload) => {
        try {
            const response = (await getUserPosition().json()) as number;
            actions.setUserPosition(response);
            return response;
        } catch (error) {
            console.log(error.message);
        }
    }),
};

export default menu;
