import { thunk, Thunk, Action, action } from "easy-peasy";

import {
  EvaluationMomentId,
  getResponsibilities,
  ResponsibilityId,
  addResponsibility,
  deleteResponsibility,
  getOneResponsibility,
  getReactionTimes,
  UpdateResType,
  updateResponsibility,
} from "./responsibilities.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

export type ResponsibilitiesListItem = {
  id: number;
  questionaireId: number;
  questionaireName?: string;
  questionaireCategoryId: number;
  questionaireCategoryName?: string;
  questionId: number;
  questionName?: string;
  scoretype: number;
  scoretypeName?: string;
  minValue: number;
  maxValue: number;
  evaluationMomentId?: number;
  subtrajectoryId?: number;
  queryPropertyId: number;
  queryPropertyName?: string;
  responsibilityLinesDto?: ResponsibilitiesLine[];
  responsibilityLinesToShow?: ResponsibilitiesLine[];
};

export type ResponsibilitiesLine = {
  id: number;
  type: number;
  typeName?: string;
  userId: number;
  userName?: string;
  roleId: number;
  roleName?: string;
  reactionTime: number;
  position: number;
};

export type ResponsibilitiesModel = {
  getResponsibilities: Thunk<ResponsibilitiesModel, EvaluationMomentId>;
  updateResponsibility: Thunk<ResponsibilitiesModel, UpdateResType>;
  deleteResponsibility: Thunk<ResponsibilitiesModel, ResponsibilityId>;
  getOneResponsibility: Thunk<ResponsibilitiesModel, ResponsibilityId>;
  addResponsibility: Thunk<ResponsibilitiesModel, UpdateResType>;
  getReactionTimes: Thunk<ResponsibilitiesModel>;
  error: ErrorEntry;
  setError: Action<ResponsibilitiesModel, ErrorEntry>;
  delError: Action<ResponsibilitiesModel>;
};

export type ReactionTimes = {
  reactionTimes: Array<number>;
};

const responsibilities: ResponsibilitiesModel = {
  error: { code: 0 },
  setError: action((state, payload) => {
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),
  getResponsibilities: thunk(async (actions, payload) => {
    return fetchResponse<ResponsibilitiesListItem[]>({
      actions,
      payload,
      request: getResponsibilities,
    });
  }),
  updateResponsibility: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: updateResponsibility,
      message: "Responsibility has been edited",
    });
  }),
  deleteResponsibility: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: deleteResponsibility,
      message: "Responsibility has been deleted",
    });
  }),
  getOneResponsibility: thunk(async (actions, payload) => {
    return fetchResponse<ResponsibilitiesListItem>({
      actions,
      payload,
      request: getOneResponsibility,
    });
  }),
  addResponsibility: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: addResponsibility,
      message: "Responsibility has been added",
    });
  }),
  getReactionTimes: thunk(async (actions) => {
    return fetchResponse<ReactionTimes>({
      actions,
      request: getReactionTimes,
    });
  }),
};

export default responsibilities;
