import { thunk, Thunk, Action, action } from "easy-peasy";

import {
  getDoctors,
  DoctorsListType,
  deleteDoctor,
  DeleteDoctorType,
  getBasicDoctor,
  addDoctor,
  editDoctor,
  EditDoctorType,
  updateDoctor,
  getListToShow,
} from "./doctors.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

export interface DoctorsList {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  count: number;
  items: DoctorsItem[];
  hasPrevious: boolean;
  hasNext: boolean;
}

export type DoctorsItem = {
  id: number;
  name: string;
  email?: string;
  active: true;
};

export type BasicDoctorModel = {
  id: number;
  instanceId: number;
  name: string;
  importCode: string;
  email: string;
  password: string;
  active: boolean | string;
  languageId: number;
};

export type DoctorsListItem = {
  doctorId: number;
  doctorName: string;
  active: boolean;
};

export type DoctorsModel = {
  getDoctors: Thunk<DoctorsModel, DoctorsListType>;
  deleteDoctor: Thunk<DoctorsModel, DeleteDoctorType>;
  getBasicDoctor: Thunk<DoctorsModel>;
  getListToShow: Thunk<DoctorsModel>;
  addDoctor: Thunk<DoctorsModel, BasicDoctorModel>;
  editDoctor: Thunk<DoctorsModel, EditDoctorType>;
  updateDoctor: Thunk<DoctorsModel, BasicDoctorModel>;
  error: ErrorEntry;
  setError: Action<DoctorsModel, ErrorEntry>;
  delError: Action<DoctorsModel>;
};

const doctors: DoctorsModel = {
  error: { code: 0 },
  setError: action((state, payload) => {
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),
  getDoctors: thunk(async (actions, payload) => {
    return fetchResponse<DoctorsList>({
      actions,
      payload,
      request: getDoctors,
    });
  }),
  deleteDoctor: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: deleteDoctor,
      message: "Doctor has been deleted",
    });
  }),
  getBasicDoctor: thunk(async (actions) => {
    return fetchResponse<BasicDoctorModel>({
      actions,
      request: getBasicDoctor,
    });
  }),
  addDoctor: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: addDoctor,
      message: "Doctor has been added",
    });
  }),
  editDoctor: thunk(async (actions, payload) => {
    return fetchResponse<BasicDoctorModel>({
      actions,
      payload,
      request: editDoctor,
    });
  }),
  updateDoctor: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: updateDoctor,
      message: "Doctor has been edited",
    });
  }),
  getListToShow: thunk(async (actions, payload) => {
    return fetchResponse<DoctorsListItem[]>({
      actions,
      request: getListToShow,
    });
  }),
};

export default doctors;
