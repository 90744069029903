import { thunk, Thunk, Action, action } from "easy-peasy";
import {
  SubcategoryId,
  getEvaluationMoments,
  updateEvaluationMoment,
  EvaluationMoment,
  deleteEvaluationMoment,
  EvaluationId,
  getOneMoment,
  addEvaluationMoment,
  getBasicMoment,
  getQuestionnaireListForEvaluation,
  EvaluationMomentId,
  updateEvaluationMomentQuestionnaire,
  updateEvaluationMomentType,
  deleteEvaluationMomentQuestionnaire,
  getEvaluationMomentQuestionnaire,
  addEvaluationMomentQuestionnaire,
  editPositionList,
  EvaluationViewProps,
  getEvaluationView
} from "./evaluation.service";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";
import { ErrorEntry } from "../users/users.model";

export type EvaluationType = {
  id: number;
  title: string;
  spsslabel: string;
  intake: boolean;
  days: number;
  active: boolean;
  subtrajectoryId: number;
  role: 1 | 2;
  mailPatient: boolean;
  onOperationdate: boolean;
  showOnOperationDate?: boolean;
};

export type EvaluationCheckboxType = {
    id: number,
    value: string,
    isChecked: boolean
};

export type EvaluationQuestionnaire = {
  id: number;
  active: boolean;
  inExcel: boolean;
  evaluationMomentId?: number;
  questionaireId?: number;
  questionaireTitle?: string;
  greater?: boolean | null;
  number?: number | null;
  position: number;
  canDelete?: boolean;
};

export type InvitationType = {
  id: number,
  date: string,
  mailStatusName: string,
  typeName: string
};

export type EvaluationViewResponse = {
  id: number,
  operationId: number,
  title: string,
  trajectoryName: string,
  subtrajectoryName: string,
  subtrajectoryId: number | string,
  date: string | null,
  role: number,
  patientStatusName: string | null,
  questionaires: string[],
  invitations: InvitationType[]
};

export type EvaluationModel = {
  error: ErrorEntry;

  setError: Action<EvaluationModel, ErrorEntry>;
  delError: Action<EvaluationModel>;

  getEvaluationMoments: Thunk<EvaluationModel, SubcategoryId>;
  updateEvaluationMoment: Thunk<EvaluationModel, EvaluationMoment>;
  deleteEvaluationMoment: Thunk<EvaluationModel, EvaluationId>;
  addEvaluationMoment: Thunk<EvaluationModel, EvaluationMoment>;
  getOneMoment: Thunk<EvaluationModel, EvaluationId>;
  getBasicMoment: Thunk<EvaluationModel, SubcategoryId>;
  getQuestionnaireListForEvaluation: Thunk<EvaluationModel, EvaluationMomentId>;
  updateEvaluationMomentQuestionnaire: Thunk<
    EvaluationModel,
    updateEvaluationMomentType
  >;
  deleteEvaluationMomentQuestionnaire: Thunk<EvaluationModel, EvaluationId>;
  getEvaluationMomentQuestionnaire: Thunk<EvaluationModel, EvaluationId>;
  addEvaluationMomentQuestionnaire: Thunk<EvaluationModel, EvaluationMomentId>;
  editPositionList: Thunk<EvaluationModel, EvaluationQuestionnaire[]>;
  getEvaluationView: Thunk<EvaluationModel, EvaluationViewProps>;
};

const evaluation: EvaluationModel = {
  error: { code: 0 },
  setError: action((state, payload) => {
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),
  getEvaluationMoments: thunk(async (actions, payload) => {
    return fetchResponse<EvaluationType[]>({
      actions,
      payload,
      request: getEvaluationMoments,
    });
  }),
  updateEvaluationMoment: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: updateEvaluationMoment,
      message: "Evaluation moment has been edited",
    });
  }),
  deleteEvaluationMoment: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: deleteEvaluationMoment,
      message: "Evaluation moment has been deleted",
    });
  }),
  getOneMoment: thunk(async (actions, payload) => {
    return fetchResponse<EvaluationType>({
      actions,
      payload,
      request: getOneMoment,
    });
  }),
  addEvaluationMoment: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: addEvaluationMoment,
      message: "Evaluation moment has been added",
    });
  }),
  getBasicMoment: thunk(async (actions, payload) => {
    return fetchResponse<EvaluationType>({
      actions,
      payload,
      request: getBasicMoment,
    });
  }),
  getQuestionnaireListForEvaluation: thunk(async (actions, payload) => {
    return fetchResponse<EvaluationQuestionnaire[]>({
      actions,
      payload,
      request: getQuestionnaireListForEvaluation,
    });
  }),
  updateEvaluationMomentQuestionnaire: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: updateEvaluationMomentQuestionnaire,
      message: "Evaluation moment questionnaire has been edited",
    });
  }),
  deleteEvaluationMomentQuestionnaire: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: deleteEvaluationMomentQuestionnaire,
      message: "Evaluation moment questionnaire has been deleted",
    });
  }),
  getEvaluationMomentQuestionnaire: thunk(async (actions, payload) => {
    return fetchResponse<EvaluationQuestionnaire>({
      actions,
      payload,
      request: getEvaluationMomentQuestionnaire,
    });
  }),
  addEvaluationMomentQuestionnaire: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: addEvaluationMomentQuestionnaire,
      message: "Evaluation moment has been added",
    });
  }),
  editPositionList: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: editPositionList,
      message: "Positions has been edited",
    });
  }),
  getEvaluationView: thunk(async (actions, payload) => {
    return fetchResponse<EvaluationViewResponse>({
      actions,
      payload,
      request: getEvaluationView,
    });
  }),
};

export default evaluation;
