import { thunk, Thunk, Action, action } from "easy-peasy";
import {
    getOneQuestionnaire,
    getQuestionnaires,
    deleteQuestionnaires,
    copyQuestionnaires,
    toggleLockQuestionnaire,
    addQuestionnaires,
    updateQuestionaire,
    BasicQuestionnaireModel,
    QuestionnaireIdType,
    QuestionnairesListType,
    ToggleType,
    getQuestionnaireName,
    previewQuestionnaire,
    PreviewQuestionnaireType,
    getQuestionnairesList,
    EvaluationMomentId,
    getAllQuestionnaries,
    previewQuestionnaires,
    PreviewQuestionnairesType,
    getTestingsQuestionnaireStatus,
    resendEmailWithCode,
    ResendEmailType,
    getTestingsQuestionnaire,
    TestingsType,
    saveTestingsAnswers,
    TestingsAnswersModel,
    getPatientStatus,
    getTestingsAnswers,
    getTestingsInfo,
    TestingIdType,
    TestingsViewProps,
    getTestingsView,
    resetTesting,
    logTesting,
    ActivateProps,
    activateTestings,
    getInvitationData,
    sendInvitationAuto,
    InvitationManualProps,
    sendInvitationManual,
    setAsSent,
    getEvaluatieAnswers
} from "./questionnaires.service";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";

export interface QuestionnairesList {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    count: number;
    items: BasicQuestionnaireModel[];
    hasPrevious: boolean;
    hasNext: boolean;
}

export type ErrorEntry = {
    code?: number;
    message?: string;
};

export type NameType = {
    name: string;
};

export type QuestionnaireListItem = {
    id: number;
    title: string;
    spsslabel: string;
    active: boolean;
};

export type FilterType = {
    id: number;
    name: string;
};

export type ObQuestion = {
    id: number;
    count: number;
    answersId: number[];
};

export type QuestionnairesModel = {
    error: ErrorEntry;

    setError: Action<QuestionnairesModel, ErrorEntry>;
    delError: Action<QuestionnairesModel>;

    selAnswers: answerType[];
    setSelAnswer: Action<QuestionnairesModel, answerType>;
    clearSelAnswer: Action<QuestionnairesModel>;
    obQuestions: ObQuestion[];
    setObQuestions: Action<QuestionnairesModel, ObQuestion[]>;
    clearObQuestions: Action<QuestionnairesModel>;

    getQuestionnaires: Thunk<QuestionnairesModel, QuestionnairesListType>;
    getAllQuestionnaries: Thunk<QuestionnairesModel>;
    getPatientStatus: Thunk<QuestionnairesModel>;
    deleteQuestionnaires: Thunk<QuestionnairesModel, QuestionnaireIdType>;
    copyQuestionnaires: Thunk<QuestionnairesModel, QuestionnaireIdType>;
    addQuestionnaires: Thunk<QuestionnairesModel, BasicQuestionnaireModel>;
    updateQuestionaire: Thunk<QuestionnairesModel, BasicQuestionnaireModel>;
    getOneQuestionnaire: Thunk<QuestionnairesModel, QuestionnaireIdType>;
    toggleLockQuestionnaire: Thunk<QuestionnairesModel, ToggleType>;
    getQuestionnaireName: Thunk<QuestionnairesModel, QuestionnaireIdType>;
    getQuestionnairePreview: Thunk<QuestionnairesModel, PreviewQuestionnaireType>;
    getQuestionnairesList: Thunk<QuestionnairesModel, EvaluationMomentId>;
    getQuestionnairesPreview: Thunk<
        QuestionnairesModel,
        PreviewQuestionnairesType
    >;
    getTestingsQuestionnaireStatus: Thunk<QuestionnairesModel, TestingsType>;
    resendEmailWithCode: Thunk<QuestionnairesModel, ResendEmailType>;
    getTestingsQuestionnaire: Thunk<QuestionnairesModel, TestingsType>;
    saveTestingsAnswers: Thunk<QuestionnairesModel, TestingsAnswersModel>;
    getTestingsAnswers: Thunk<QuestionnairesModel, TestingsType>;
    getTestingsInfo: Thunk<QuestionnairesModel, TestingsType>;
    getTestingsView: Thunk<QuestionnairesModel, TestingsViewProps>;
    resetTesting: Thunk<QuestionnairesModel, TestingsType>;
    activateTestings: Thunk<QuestionnairesModel, ActivateProps>;
    getInvitationData: Thunk<QuestionnairesModel, TestingIdType>;
    sendInvitationAuto: Thunk<QuestionnairesModel, TestingIdType>;
    sendInvitationManual: Thunk<QuestionnairesModel, InvitationManualProps>;
    logTesting: Thunk<QuestionnairesModel, TestingsType>;
    setAsSent: Thunk<QuestionnairesModel, TestingIdType>;
    getEvaluatieAnswers: Thunk<QuestionnairesModel, QuestionnaireIdType>;
};

const questionnaires: QuestionnairesModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    selAnswers: [],
    setSelAnswer: action((state, payload) => {
        if (state.selAnswers) {
            state.selAnswers = [...state.selAnswers.filter(x => x.questionId !== payload.questionId), payload];
        } else {
            state.selAnswers = [payload];
        }
    }),
    clearSelAnswer: action((state) => {
        state.selAnswers = [];
    }),
    obQuestions: [],
    setObQuestions: action((state, payload) => {
        state.obQuestions = payload;
    }),
    clearObQuestions: action((state) => {
        state.obQuestions = [];
    }),
    getQuestionnaires: thunk(async (actions, payload) => {
        return fetchResponse<QuestionnairesList>({
            actions,
            payload,
            request: getQuestionnaires,
        });
    }),
    deleteQuestionnaires: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteQuestionnaires,
            message: "Questionnaire has been deleted",
        });
    }),
    copyQuestionnaires: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: copyQuestionnaires,
            message: "Questionnaire has been copied",
        });
    }),
    addQuestionnaires: thunk(async (actions, payload) => {
        return fetchResponse<BasicQuestionnaireModel>({
            actions,
            payload,
            request: addQuestionnaires,
        });
    }),
    getOneQuestionnaire: thunk(async (actions, payload) => {
        return fetchResponse<BasicQuestionnaireModel>({
            actions,
            payload,
            request: getOneQuestionnaire,
        });
    }),
    getPatientStatus: thunk(async (actions) => {
        return fetchResponse<FilterType[]>({
            actions,
            request: getPatientStatus,
        });
    }),
    updateQuestionaire: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateQuestionaire,
            message: "Questionnaire has been edited",
        });
    }),
    toggleLockQuestionnaire: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: toggleLockQuestionnaire,
            message: "Questionnaire lock status has been changed",
        });
    }),
    getQuestionnaireName: thunk(async (actions, payload) => {
        return fetchResponse<NameType>({
            actions,
            payload,
            request: getQuestionnaireName,
        });
    }),
    getQuestionnairePreview: thunk(async (actions, payload) => {
        return fetchResponse<previewQuestionnaireResponseType>({
            actions,
            payload,
            request: previewQuestionnaire,
        });
    }),
    getQuestionnairesList: thunk(async (actions, payload) => {
        return fetchResponse<QuestionnaireListItem[]>({
            actions,
            payload,
            request: getQuestionnairesList,
        });
    }),
    getAllQuestionnaries: thunk(async (actions) => {
        return fetchResponse<QuestionnaireListItem[]>({
            actions,
            request: getAllQuestionnaries,
        });
    }),
    getQuestionnairesPreview: thunk(async (actions, payload) => {
        return fetchResponse<previewQuestionnaireResponseType[]>({
            actions,
            payload,
            request: previewQuestionnaires,
        });
    }),
    getTestingsQuestionnaireStatus: thunk(async (actions, payload) => {
        return fetchResponse<any[]>({
            actions,
            payload,
            request: getTestingsQuestionnaireStatus
        });
    }),
    resendEmailWithCode: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: resendEmailWithCode,
            message: "E-mail with code sent!",
        });
    }),
    getTestingsQuestionnaire: thunk(async (actions, payload) => {
        return fetchResponse<testingsQuestionnaireResponseType[]>({
            actions,
            payload,
            request: getTestingsQuestionnaire,
        });
    }),
    saveTestingsAnswers: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: saveTestingsAnswers,
            message: "Testings answers sent!",
        });
    }),
    getTestingsAnswers: thunk(async (actions, payload) => {
        return fetchResponse<TestingsQuestionnaireAnswersType>({
            actions,
            payload,
            request: getTestingsAnswers
        });
    }),
    getTestingsInfo: thunk(async (actions, payload) => {
        return fetchResponse<TestingInfoType>({
            actions,
            payload,
            request: getTestingsInfo
        });
    }),
    getTestingsView: thunk(async (actions, payload) => {
        return fetchResponse<TestingViewResponse>({
            actions,
            payload,
            request: getTestingsView
        });
    }),
    resetTesting: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: resetTesting,
            message: "Testing has been reseted",
        });
    }),
    activateTestings: thunk(async (actions, payload) => {
        return fetchResponse<ActivateResponse>({
            actions,
            payload,
            request: activateTestings
        });
    }),
    getInvitationData: thunk(async (actions, payload) => {
        return fetchResponse<InvitationData>({
            actions,
            payload,
            request: getInvitationData
        });
    }),
    sendInvitationAuto: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: sendInvitationAuto,
            message: "Invitation has been sent",
        });
    }),
    sendInvitationManual: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: sendInvitationManual,
            message: "Invitation has been sent",
        });
    }),
    logTesting: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: logTesting,
            message: "Testing has been logged",
        });
    }),
    setAsSent: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: setAsSent,
            message: "Testing has been manualy sent",
        });
    }),
    getEvaluatieAnswers: thunk(async (actions, payload) => {
        return fetchResponse<TestingsQuestionnaireAnswersType>({
            actions,
            payload,
            request: getEvaluatieAnswers
        });
    }),
};

export type questionaireTextDtoType = {
    title: string;
    intro: string;
};

export type questionaireQuestionTextDtoType = {
    instruction: string;
    question: string;
};

export type questionOptionsType = {
    toon_waardes: null | boolean;
    toon_images: null | boolean;
    displaymode: number;
    tekst_links: string;
    tekst_rechts: string;
};

export type multiAnswerDefinitionTextDtoType = {
    value: string;
};

export type answerDefinitionsToViewType = {
    id: number;
    active: boolean;
    openInterpretation: boolean;
    position: number;
    score: number;
    answerDefinitionTextDto: multiAnswerDefinitionTextDtoType;
};

export type subquestionsType = {
    id: number;
    active: boolean;
    answerDefinitionstoView: null | answerDefinitionsToViewType;
    categoryId: null | number;
    dependantAnswerId: null | number;
    obligated: boolean;
    parentQuestion: null | number;
    position: number;
    propertyId: null | number;
    questionNumber: boolean;
    questionOptions: null | questionOptionsType;
    questionTextDto: null | questionaireQuestionTextDtoType;
    questionType: number;
    questionIndexText: string;
    title: string;
    answers?: answerType[];
};

export type questionToViewType = {
    id: number;
    title: string;
    obligated: boolean;
    active: boolean;
    questionType: number;
    parentQuestion: null | number;
    categoryId: number;
    propertyId: null | number;
    dependantAnswerId: null | number;
    questionNumber: false;
    position: boolean;
    questionTextDto: null | questionaireQuestionTextDtoType;
    questionOptions: null | questionOptionsType;
    answerDefinitionsToView: answerDefinitionsToViewType[];
    subquestions: null | subquestionsType[];
    intro?: string | undefined;
    questionnaireIndex?: number;
    introOnStart?: boolean;
    answers?: answerType[];
    questionIndex?: number;
    questionIndexText: string;
    dependantAnswers?: number[];
    displayAllways: boolean;
    minScore: null | number;
    maxScore: null | number;
};

export type questionaireCategoryToView = {
    id: number;
    title: string;
    questionOnPage: number;
    active: boolean;
    questionaireId: number;
    introOnStart: boolean;
    locked: boolean;
    questionaireCategoryTextDto: questionaireTextDtoType;
    questionsToView: null | questionToViewType[];
};

export type previewQuestionnaireResponseType = {
    id: number;
    title: string;
    intoOnStart: boolean;
    routing: boolean;
    active: boolean;
    questionaireTextDto: null | questionaireTextDtoType;
    questionaireCategoryToView: null | questionaireCategoryToView[];
};

export type answerType = {
    id?: number;
    questionId?: number;
    value?: string;
    answerSelectedIds?: number[];
};

export type questionaireAnswerSchemaType = {
    questionaireId: number;
    answersToView: answerType[];
};

export type questionaireSchemasType = {
    questionaireToView?: previewQuestionnaireResponseType;
    questionaireAnswerSchema: questionaireAnswerSchemaType;
};

export type testingsQuestionnaireResponseType = {
    testingId: number;
    questionaireSchemas: questionaireSchemasType[];
};

export type QuestionsWithAnswersType = {
    questionId: number,
    questionType?: number,
    questionText: string,
    answerTexts: string[],
    subquestions?: QuestionsWithAnswersType[]
};

export type filledQuestionaireCategories = {
    questionaireCategoryTitle: string;
    questionaireTitle: string;
    questionsWithAnswers: QuestionsWithAnswersType[];
};

export type TestingsQuestionnaireAnswersType = {
    filledQuestionaireCategoriesDto: filledQuestionaireCategories[];
};

export type TestingInfoType = {
    testingId: number,
    testingCode: string,
    patientTitleAndName: string,
    filledDate: string,
    patientNumber: string | number,
    subtrajectoryName: string,
    trajectoryName: string,
    patientEmail: string
};

export type InvitationType = {
    id: number,
    date: string,
    mailStatusName: string,
    typeName: string
};

export type TestingViewResponse = {
    id: number,
    operationId: number,
    code: string,
    trajectoryName: string,
    subtrajectoryName: string,
    subtrajectoryId: number | string,
    date: string | null,
    role: number,
    patientStatusName: string | null,
    filledDate: string | null,
    filledBy: string | null,
    questionaires: string[],
    invitations: InvitationType[]
};

export type ActivateResponse = {
    testingId: number
};

export type InvitationData = {
    receiverEmail: string,
    senderEmail: string,
    emailTitle: string,
    emailText: string,
    emailHtml: string
};

export default questionnaires;
