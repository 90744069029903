import { ThemeConfig } from './types';

export const h5: ThemeConfig = {
    tenant: 'h5',
    baseColor: '#88C100',
    navColor: '#fff',
    textColor: '#444',
    hoverColor: '#88C100',
    disabled: '#999999',
    inputBgColor: "#88C100",
    matrixHoverColor: '#88C100',
    matrixHoverTextColor: '#444',
    matrixOddBgColor: '#88C100',
    matrixOddTextColor: '#444'
};

export default h5;