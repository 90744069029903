import { thunk, Thunk, Action, action } from "easy-peasy";
import {
  NotificationListType,
  getNotifications,
  NotifiactionIdType,
  NotificationPayloadType,
  getNotificationById,
  NotifiactionUpdatePayloadType,
  updateNotifiactionSignal,
  deleteNotification,
  getSignalsDuringEvaluationId,
  SignalsPayloadType,
  getSignalsDuringEvaluationCode
} from "./notifications.service";
import { ErrorEntry } from "../users/users.model";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";

export type NotificationsModel = {
  error: ErrorEntry;

  getNotifications: Thunk<NotificationsModel, NotificationListType>;
  getNotificationById: Thunk<NotificationsModel, NotificationPayloadType>;
  updateNotification: Thunk<NotificationsModel, NotifiactionUpdatePayloadType>;
  deleteNotification: Thunk<NotificationsModel, NotifiactionIdType>;
  getSignalsDuringEvaluationId: Thunk<
    NotificationsModel, 
    NotificationPayloadType
  >;
  getSignalsDuringEvaluationCode: Thunk<NotificationsModel, SignalsPayloadType>;

  setError: Action<NotificationsModel, ErrorEntry>;
  delError: Action<NotificationsModel>;
};

const Notifications: NotificationsModel = {
  error: { code: 0 },

  setError: action((state, payload) => {
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),

  getNotifications: thunk(async (actions, payload) => {
    return fetchResponse<NotificationsListResponseType>({
      actions,
      payload,
      request: getNotifications,
    });
  }),
  getNotificationById: thunk(async (actions, payload) => {
    return fetchResponse<NotificationResponseType>({
      actions,
      payload,
      request: getNotificationById,
    });
  }),
  updateNotification: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: updateNotifiactionSignal,
      message: "Notification has been updated"
    });
  }),
  deleteNotification: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: deleteNotification,
      message: "Notification has been deleted"
    });
  }),
  getSignalsDuringEvaluationId: thunk(async (actions, payload) => {
    return fetchResponse<SignalResponseType[]>({
      actions,
      payload,
      request: getSignalsDuringEvaluationId,
    });
  }),
  getSignalsDuringEvaluationCode: thunk(async (actions, payload) => {
    return fetchResponse<SignalResponseType[]>({
      actions,
      payload,
      request: getSignalsDuringEvaluationCode,
    });
  }),
};

export type NotificationItemType = {
  id: number;
  responsibilityId: number,
  responsibilityName: string,
  questionContent: string,
  categoryContent: string,
  queryPropertyContent: string,
  made: string,
  followed: boolean,
  actions: string,
  sumOfOpenActions: number,
  sumOfAllActions: number
};

export type NotificationsListResponseType = {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  count: number;
  items: NotificationItemType[];
  hasPrevious: boolean;
  hasNext: boolean;
};

export type NotificationResponseType = {
  id: number,
  responsibilityId: number,
  responsibilityName: string,
  patientTitleAndName: string,
  testingId: number,
  testingCode: string,
  made: string,
  questionContent: string,
  categoryContent: string,
  queryPropertyContent: string,
  followed: boolean,
  editPermission: boolean,
  actionCategoryId: number,
  trajectoryName: string,
  subtrajectoryName: string,
  score: number,
  minValue: number,
  maxValue: number,
  patientNumber: string | number,
  actionDto: any,
  notificationMail: any,
  editedUserName: string
};

export type SignalResponseType = {
  id: number,
  questionContent: string,
  categoryContent: string,
  queryPropertyContent: string,
  followed: boolean,
  score: number,
  minValue: number,
  maxValue: number
};

export default Notifications;
