import ky from "../ky";
import { ResponsibilitiesListItem } from "./responsibilities.model";

export type EvaluationMomentId = {
  evaluationMomentId: number;
};

export const getResponsibilities = ({
  evaluationMomentId,
}: EvaluationMomentId) =>
  ky.get(`Responsibilities?evaluationMomentId=${evaluationMomentId}`);

export type UpdateResType = {
  data: ResponsibilitiesListItem;
};

export const updateResponsibility = ({ data }: UpdateResType) =>
  ky.put(`Responsibilities`, { json: data });

export type ResponsibilityId = {
  id: number;
};

export const deleteResponsibility = ({ id }: ResponsibilityId) =>
  ky.delete(`Responsibilities?id=${id}`);

export const getOneResponsibility = ({ id }: ResponsibilityId) =>
  ky.get(`Responsibilities/${id}`);

export const addResponsibility = ({ data }: UpdateResType) =>
  ky.post(`Responsibilities/add`, { json: data });

export const getReactionTimes = () => ky.get(`Responsibilities/reactionTimes`);
