import ky from "../ky";

export type ActionPriorityListType = {
  currentPageNo: number;
  query: string;
  sortCol: string;
  isDescending: boolean;
  pageSize?: number;
};

export const getActionPriorityList = ({
  currentPageNo,
  query,
  sortCol,
  isDescending,
  pageSize = 10
}: ActionPriorityListType) =>
  ky.get(
    `ActionPriority?PageNumber=${currentPageNo}&SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=${pageSize}`
  );

export const getActionPriorityL = () => ky.get(`ActionPriority`);

export type IdActionPriorityType = {
  id?: string | number;
};

export const deleteActionPriority = ({ id }: IdActionPriorityType) =>
  ky.delete(`ActionPriority?id=${id}`);

export type ActionPriorityType = {
  id?: number;
  title: string;
  hours: number;
};

export type ImportType = {
    languageCode: string,
    data: FormData
};

export type ImportFinalType = {
    importData: string[][]
};

export const addActionPriority = ({ title, hours }: ActionPriorityType) =>
  ky.post(`ActionPriority/add`, { json: { title, hours } });

export const updateActionPriority = ({ title, id, hours}: ActionPriorityType) =>
  ky.put(`ActionPriority`, { json: { title, id, hours} });

export const getOneActionPriority = ({ id }: IdActionPriorityType) =>
  ky.get(`ActionPriority/${id}`);

export const getForActions = () => ky.get(`ActionPriority/ToShow`);

export const importData = ({ languageCode, data }: ImportType) =>
    ky.post(`ActionPriority/Import/Get?languageCode=${languageCode}`, { body: data });

export const importDataFinal = ({ importData }: ImportFinalType) =>
    ky.post(`ActionPriority/addMany`, { json: { data: importData } });