import { thunk, Thunk, action, Action } from "easy-peasy";
import { getLanguages } from "./languages.service";
import { getAllLanguages} from "./languages.service";

export type LangEntry = {
  id: number;
  code: string;
  name: string;
  position?: number;
};

export type LangCode = string;

export type LanguagesModel = {
  getLanguages: Thunk<LanguagesModel>;
  getAllLanguages: Thunk<LanguagesModel>;
  setLangCode: Action<LanguagesModel, LangCode>;
  langCode: string;
};

const languages: LanguagesModel = {
  langCode: "NL",
  getLanguages: thunk(async () => {
    try {
      const response = (await getLanguages().json()) as LangEntry[];
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }),
    getAllLanguages: thunk(async () => {
        try {
            const response = (await getAllLanguages().json()) as LangEntry[];
            return response;
        } catch (error) {
            console.log(error.message);
        }
    }),
  setLangCode: action((state, payload) => {
    state.langCode = payload;
  }),
};

export default languages;
