import styled from 'styled-components';
import { mainLogoUrlBuilder } from '../../../helpers/mainLogoUrlBuilder';

import * as color from '../../../components/portal/ColorVariables';

export const HospitalLogo = styled.img.attrs(props => ({
  src: mainLogoUrlBuilder(props.theme.tenant),
}))`
  display: inline-block;
  margin: 5px 0 5px 64px;
  height: 70px;
    @media ${color.media.xsmall} {
            max-width: 240px;
            max-height: 40px;
            height: auto;
            margin: 5px 0 5px 5px;
        }
`;

export const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: auto;
 @media ${color.media.xsmall} {
           margin: 0 auto;
           width: fit-content;
}

`;