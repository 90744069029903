import { ThemeConfig } from './types';

export const h11: ThemeConfig = {
    tenant: 'h11',
    baseColor: '#4a8c2b',
    navColor: '#ffffff',
    textColor: '#444',
    hoverColor: '#4a8c2b',
    disabled: '#999999',
    inputBgColor: "#FBFBFC",
    matrixHoverColor: '#4a8c2b',
    matrixHoverTextColor: '#444',
    matrixOddBgColor: '#FBFBFC',
    matrixOddTextColor: '#444'
};

export default h11;

/*
@text_header: #2d2d2d;
@text_link: #4a8c2b;
@text_link_response: #cfced1;
*/