import { thunk, Thunk, Action, action } from "easy-peasy";

import {
    ActionPriorityListType,
    ActionPriorityType,
    IdActionPriorityType,
    addActionPriority,
    deleteActionPriority,
    getActionPriorityList,
    getActionPriorityL,
    getOneActionPriority,
    updateActionPriority,
    getForActions,
    ImportType,
    importData,
    ImportFinalType,
    importDataFinal
} from "./actionPriority.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

export type ActionList = {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    count: number;
    items: ActionItem[];
    hasPrevious: boolean;
    hasNext: boolean;
};

export type ActionMainParams = {
    id: number;
    title: string;
};

export type ActionItem = ActionMainParams & {
    hours: number;
};

export type ColumnName = {
    key: string,
    val: string
};

export type ImportData = {
    columnNames: ColumnName[],
    fileData: string[][],
    languageCode: string
};

export type ImportResult = {
    totalRowsCount: number,
    processedRowsCount: number,
    ignoredRowsCount: number,
    successedRowsCount: number,
    wrongRowsCount: number,
    addedRowsCount: number,
    updatedRowsCount: number,
    errors: ColumnName[]
};

export type ActionPriorityModel = {
    getActionPriorityList: Thunk<ActionPriorityModel, ActionPriorityListType>;
    deleteActionPriority: Thunk<ActionPriorityModel, IdActionPriorityType>;
    addActionPriority: Thunk<ActionPriorityModel, ActionPriorityType>;
    getOneActionPriority: Thunk<ActionPriorityModel, IdActionPriorityType>;
    updateActionPriority: Thunk<ActionPriorityModel, ActionPriorityType>;
    getActionPriorityL: Thunk<ActionPriorityModel>;
    getPrioritiesForActions: Thunk<ActionPriorityModel>;
    error: ErrorEntry;
    setError: Action<ActionPriorityModel, ErrorEntry>;
    delError: Action<ActionPriorityModel>;
    importData: Thunk<ActionPriorityModel, ImportType>;
    importDataFinal: Thunk<ActionPriorityModel, ImportFinalType>;
};

const actionPriority: ActionPriorityModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getActionPriorityList: thunk(async (actions, payload) => {
        return fetchResponse<ActionList>({
            actions,
            payload,
            request: getActionPriorityList,
        });
    }),
    getActionPriorityL: thunk(async (actions) => {
        return fetchResponse<ActionList>({
            actions,
            request: getActionPriorityL,
        });
    }),
    deleteActionPriority: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteActionPriority,
            message: "Action priority has been deleted",
        });
    }),
    addActionPriority: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addActionPriority,
            message: "Action priority has been added",
        });
    }),
    getOneActionPriority: thunk(async (actions, payload) => {
        return fetchResponse<ActionItem>({
            actions,
            payload,
            request: getOneActionPriority,
        });
    }),
    updateActionPriority: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateActionPriority,
            message: "Action priority has been edited",
        });
    }),
    getPrioritiesForActions: thunk(async (actions) => {
        return fetchResponse<ActionMainParams[]>({
            actions,
            request: getForActions
        });
    }),
    importData: thunk(async (actions, payload) => {
        return fetchResponse<ImportData>({
            actions,
            payload,
            request: importData
        });
    }),
    importDataFinal: thunk(async (actions, payload) => {
        return fetchResponse<ImportResult>({
            actions,
            payload,
            request: importDataFinal
        });
    }),
};

export default actionPriority;
