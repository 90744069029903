import styled from 'styled-components'

const NoBackgroundButton = styled.div`
    color: ${props => props.theme.navColor};

    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 142%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 8px;

    width: 32px;
    height: 32px;

    background: transparent;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 4px;

    :hover, :focus {
        border: 1px solid ${props => props.theme.navColor};
        box-sizing: border-box;
        border-radius: 4px;
    }
`

export default NoBackgroundButton;