import ky from "../ky";
import { BasicDeleteReasonModel } from "./deleteReasons.model";

export const getDeleteReasons = () => ky.get(`DeleteReasons`);

export const editDeleteReason = ({
    id,
    title,
    active,
    position
}: BasicDeleteReasonModel) =>
    ky.put(`DeleteReasons`, {
        json: {
            id,
            title,
            active,
            position
        },
    });

export type DeleteDeleteReason = {
    id: number;
};

export const deleteDeleteReason = ({ id }: DeleteDeleteReason) =>
    ky.delete(`DeleteReasons?id=${id}`);

export type GetDeleteReason = {
    id: number;
};

export const getDeleteReason = ({ id }: GetDeleteReason) =>
    ky.get(`DeleteReasons/${id}`);

export const addDeleteReason = ({
    id,
    title,
    active,
    position
}: BasicDeleteReasonModel) =>
    ky.post(`DeleteReasons/add`, {
        json: {
            id,
            title,
            active,
            position
        },
    });

export const updateDeleteReasonsPositions = (deletereasons: BasicDeleteReasonModel[]) =>
    ky.put(`DeleteReasons/editPositionList`, { json: { deletereasons } });

