import { thunk, Thunk, Action, action } from "easy-peasy";

import {
    RoleListType,
    RoleType,
    IdRoleType,
    addRole,
    deleteRole,
    getRoleList,
    getRoleL,
    getRoleSignalsList,
    getOneRole,
    updateRole,
    getRolesToShow,
} from "./roles.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

export type RoleList = {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    count: number;
    items: RoleItem[];
    hasPrevious: boolean;
    hasNext: boolean;
};

export type UserRoleType = {
    idUser: number;
    idRole: number;
    userName: string;
};

export type RoleItem = {
    id: number;
    instanceId?: number;
    name: string;
    userRoles?: Array<UserRoleType>;
    notificationsAndActions: number;
};

export type RoleModel = {
    getRoleList: Thunk<RoleModel, RoleListType>;
    deleteRole: Thunk<RoleModel, IdRoleType>;
    addRole: Thunk<RoleModel, RoleType>;
    getOneRole: Thunk<RoleModel, IdRoleType>;
    updateRole: Thunk<RoleModel, RoleType>;
    getRoleL: Thunk<RoleModel>;
    getRoleSignalsList: Thunk<RoleModel, IdRoleType>;
    getRolesToShow: Thunk<RoleModel>;
    error: ErrorEntry;
    setError: Action<RoleModel, ErrorEntry>;
    delError: Action<RoleModel>;
};

const Role: RoleModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getRoleList: thunk(async (actions, payload) => {
        return fetchResponse<RoleList>({
            actions,
            payload,
            request: getRoleList,
        });
    }),
    getRoleSignalsList: thunk(async (actions, payload) => {
        return fetchResponse<string[]>({
            actions,
            payload,
            request: getRoleSignalsList,
        });
    }),
    getRoleL: thunk(async (actions) => {
        return fetchResponse<RoleList>({
            actions,
            request: getRoleL,
        });
    }),
    deleteRole: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteRole,
            message: "Role has been deleted",
        });
    }),
    addRole: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addRole,
            message: "Role has been added",
        });
    }),
    getOneRole: thunk(async (actions, payload) => {
        return fetchResponse<RoleItem>({
            actions,
            payload,
            request: getOneRole,
        });
    }),
    updateRole: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateRole,
            message: "Role has been edited",
        });
    }),
    getRolesToShow: thunk(async (actions) => {
        return fetchResponse<RoleItem[]>({
            actions,
            request: getRolesToShow,
        });
    }),
};

export default Role;
