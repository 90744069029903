import { thunk, Thunk, Action, action } from "easy-peasy";

import {
  ImagesListType,
  ImagesType,
  IdImagesType,
  TagImagesType,
  addImages,
  deleteImages,
  getImagesL,
  updateImages,
  getImageTag,
  getImage,
  getImageByTag,
  getOptionalImage,
  getImagesList,
  delImage,
  delOpitonalImage
} from "./images.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

export type ImageList = {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  count: number;
  items: ImageItem[];
  hasPrevious: boolean;
  hasNext: boolean;
};

export type ImageItem = {
  id: number;
  tag: string;
  title: string;
};

export type ImagesModel = {
  getImagesList: Thunk<ImagesModel, ImagesListType>;
  deleteImages: Thunk<ImagesModel, IdImagesType>;  
  delImage: Thunk<ImagesModel, IdImagesType>;
  delOpitonalImage: Thunk<ImagesModel, IdImagesType>;
  addImages: Thunk<ImagesModel, ImagesType>;
  updateImages: Thunk<ImagesModel, ImagesType>;
  getImagesL: Thunk<ImagesModel>;
  getImageTag: Thunk<ImagesModel, IdImagesType>;
  getImage: Thunk<ImagesModel, IdImagesType>;
  getImageByTag: Thunk<ImagesModel, TagImagesType>;
  getOptionalImage: Thunk<ImagesModel, IdImagesType>;
  error: ErrorEntry;
  setError: Action<ImagesModel, ErrorEntry>;
  delError: Action<ImagesModel>;
};

const Images: ImagesModel = {
  error: { code: 0 },
  setError: action((state, payload) => {
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),
  getImagesList: thunk(async (actions, payload) => {
    return fetchResponse<ImageList>({
      actions,
      payload,
      request: getImagesList,
    });
  }),
  getImagesL: thunk(async (actions) => {
    return fetchResponse<ImageList>({
      actions,
      request: getImagesL,
    });
  }),
  deleteImages: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: deleteImages,
      message: "Image tag has been deleted",
    });
  }),
  delImage: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: delImage,
      message: "Image has been deleted",
    });
  }),
  delOpitonalImage: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: delOpitonalImage,
      message: "Image has been deleted",
    });
  }),
  addImages: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: addImages,
      message: "Image has been added",
    });
  }),
  getImageTag: thunk(async (actions, payload) => {
    return fetchResponse<ImageItem>({
      actions,
      payload,
      request: getImageTag,
    });
  }),
  getImage: thunk(async (actions, payload) => {
    try {
      const response = await getImage(payload);
      return response;
    } catch (error) {
      console.log(error);
    }
  }),
  getImageByTag: thunk(async (actions, payload) => {
    try {
      const response = await getImageByTag(payload);
      return response;
    } catch (error) {
      console.log(error);
    }
  }),
  getOptionalImage: thunk(async (actions, payload) => {
    try {
      const response = await getOptionalImage(payload);
      return response;
    } catch (error) {
      console.log(error);
    }
  }),
  updateImages: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: updateImages,
      message: "Image has been edited",
    });
  }),
};

export default Images;
