import ky from "../ky";
import { BasicQCategoryModel } from "./qCategories.model";

export type QCategoryModel = {
  languageCode?: string;
  id?: string | number;
  instanceId?: number;
  title: string;
  spsslabel: string;
  questionOnPage: -1 | 1;
  active: boolean;
  questionaireId: number;
  introOnStart: boolean;
  locked?: boolean;
  questionaireTitle?: string;
  questionaireCategoryTextDto: QCategoryTextType;
};

type QCategoryTextType = {
  title: string;
  intro: string;
};

export type QuestionnaireIdType = {
  questionaireId?: number | string;
};

export type QCategoryIdType = {
  id?: number | string;
  languageCode?: string;
};

export type QCategoryPositionType = {
  categoryId?: number;
  isHigher: boolean;
};

export const qCategoryPosition = ({
  categoryId,
  isHigher,
}: QCategoryPositionType) =>
  ky.put(
    `QuestionaireCategories/position?categoryId=${categoryId}&isHigher=${isHigher}`
  );

export const getQCategorie = ({ id, languageCode }: QCategoryIdType) =>
  ky.get(`QuestionaireCategories/${id}?languageCode=${languageCode}`);

export const deleteQCategory = ({ id }: QCategoryIdType) =>
  ky.delete(`QuestionaireCategories?id=${id}`);

export const getQCategories = ({ questionaireId }: QuestionnaireIdType) =>
  ky.get(`QuestionaireCategories?questionaireId=${questionaireId}`);

export const addQCategory = ({
  languageCode,
  id,
  title,
  spsslabel,
  questionOnPage,
  active,
  questionaireId,
  introOnStart,
  questionaireCategoryTextDto,
}: QCategoryModel) =>
  ky.post(`QuestionaireCategories/add?languageCode=${languageCode}`, {
    json: {
      id,
      title,
      spsslabel,
      questionOnPage,
      active,
      questionaireId,
      introOnStart,
      questionaireCategoryTextDto,
    },
  });

export const updateQCategory = ({
  languageCode,
  id,
  title,
  spsslabel,
  questionOnPage,
  active,
  questionaireId,
  introOnStart,
  questionaireCategoryTextDto,
}: QCategoryModel) =>
  ky.put(`QuestionaireCategories/?languageCode=${languageCode}`, {
    json: {
      id,
      title,
      spsslabel,
      questionOnPage,
      active,
      questionaireId,
      introOnStart,
      questionaireCategoryTextDto,
    },
  });

export const getQCategoryeName = ({ id }: QCategoryIdType) =>
  ky.get(`QuestionaireCategories/name/${id}`);

export const updateCategoriesPositions = (
  questionaireCategories: BasicQCategoryModel[]
) =>
  ky.put(`QuestionaireCategories/editPositionList`, {
    json: { questionaireCategories },
  });
