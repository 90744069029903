import { useEffect, useRef } from "react";
import { useStoreState } from "easy-peasy";

import { LoginResponeEntry } from "../components/logging/LogInEmail";
import addDays from "date-fns/fp/addDays/index.js";

interface handleErrorMessageTypes {
    errorMessageNumber: number;
    errorMessageSetter: (number: number) => void;
}

export const usePrevious = <T extends {}>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const parseTimeDate = (date: Date) => {
    const t = new Date(date);
    const hr = ("0" + t.getHours()).slice(-2);
    const min = ("0" + t.getMinutes()).slice(-2);
    const sec = ("0" + t.getSeconds()).slice(-2);
    const mon = ("0" + (t.getMonth() + 1)).slice(-2);
    const day = ("0" + t.getDate()).slice(-2);

    return `${t.getFullYear()}-${mon}-${day} ${hr}:${min}:${sec}`;
};

export const parseTime = (date: string) => {
    const t = new Date(date);
    const hr = ("0" + t.getHours()).slice(-2);
    const min = ("0" + t.getMinutes()).slice(-2);
    const sec = ("0" + t.getSeconds()).slice(-2);
    const mon = ("0" + (t.getMonth() + 1)).slice(-2);
    const day = ("0" + t.getDate()).slice(-2);

    return `${t.getFullYear()}-${mon}-${day} ${hr}:${min}:${sec}`;
};

export const parseDate = (date: Date) => {
    const mon = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${date.getFullYear()}-${mon}-${day}`;
}

export const parseDateDMY = (date: Date) => {
    const mon = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${day}-${mon}-${date.getFullYear()}`;
}

export const changeDateYD = (date: string) => {
    const t = new Date(date);
    const mon = ("0" + (t.getMonth() + 1)).slice(-2);
    const day = ("0" + t.getDate()).slice(-2);
    return `${day}-${mon}-${t.getFullYear()}`;
}

export const changeDateDY = (date: string) => {
    const t = new Date(date);
    const mon = ("0" + (t.getMonth() + 1)).slice(-2);
    const day = ("0" + t.getDate()).slice(-2);
    return `${t.getFullYear()}-${mon}-${day}`;
}

export const getCurrentDateYear = () => {
    var date = new Date();

    return parseDate(date);
}

export const getLastYear = () => {
    var date = new Date();
    date.setFullYear(date.getFullYear() - 1);

    return parseDate(date);
}

export const getCurrentDateYear2 = () => {
    var date = new Date();

    return parseDateDMY(date);
}

export const getLastYear2 = () => {
    var date = new Date();
    date.setFullYear(date.getFullYear() - 1);

    return parseDateDMY(date);
}

export const getLastWeek = () => {
    var date = new Date();
    date.setDate(date.getDate() - 7);

    return parseDate(date);
}

export const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const blobToString = (file: Blob) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const errorNumbersArray = [
    1,
    2,
    3,
    4,
    5,
    6,
    9,
    151,
    152,
    153,
    154,
    155,
    156,
    157,
    158,
    159,
    160,
    169,
    170,
    171,
    172,
    173,
    174,
    175,
    176,
    177,
    178,
    190,
];
export const handleErrorMessage = ({
    errorMessageNumber,
    errorMessageSetter,
}: handleErrorMessageTypes) => {
    if (errorNumbersArray.includes(errorMessageNumber)) {
        errorMessageSetter(errorMessageNumber);
    } else {
        errorMessageSetter(666);
    }
};

interface handleSetErrorWithVariableTypes {
    errorNumber: number;
    messageVariable: number | string;
}

interface chooseErrorHandlingTypes {
    isErrorMessage: string | number;
    handleSetErrorWithVariable: ({
        errorNumber,
        messageVariable,
    }: handleSetErrorWithVariableTypes) => void;
    handleSetError: (number: number) => void;
}

export const chooseErrorHandling = ({
    isErrorMessage,
    handleSetErrorWithVariable,
    handleSetError,
}: chooseErrorHandlingTypes) => {
    if (String(isErrorMessage).length > 3) {
        let messageError = Number(String(isErrorMessage).split(";")[0]);
        let messageVariable: string = String(isErrorMessage).split(";")[1];
        handleSetErrorWithVariable({
            errorNumber: messageError,
            messageVariable: messageVariable,
        });
    } else if (String(isErrorMessage).length) {
        handleErrorMessage({
            errorMessageNumber: Number(isErrorMessage),
            errorMessageSetter: handleSetError,
        });
    }
};

interface modifyArrayWithoutMutationProps {
    array: any[];
    index: number;
    value: any;
};

export const modifyArrayWithoutMutation = ({
    array,
    index,
    value
}: modifyArrayWithoutMutationProps) => {
    return [
        ...array.slice(0, index),
        value,
        ...array.slice(index + 1)
    ];
};

export const decodeHtml = (html: string) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;

    return txt.value;
};

export const addP = (text: string) => {
    if (!text.startsWith("<p>") || !text.endsWith("</p>")) {
        return "<p>" + text + "</p>";
    }
    return text;
};

export const addSpaces = (html: string) => html.replace(/<span/gi, `&nbsp;<span`).replace(/span>/gi, `span>&nbsp;`);

export const stripedHtml = (html: string) => html.replace(/<[^>]+>/g, '').replace('&nbsp;', ' ');

export const stripedScript = (html: string) => html.replace(/(<|%3C)script[\s\S]*?(>|%3E)[\s\S]*?(<|%3C)(\/|%2F)script[\s\S]*?(>|%3E)/gi, '');

export const cutString = (str: string, num: number) => {
    if (str.length <= num) return str;

    return `${str.substr(0, num)}...`;
};

export const checkRights = (perms: LoginResponeEntry[], key: string, val: string) => {
    let first = perms.find(el => el.name === key);
    if (first) {
        if (!Array.isArray(first.items)) {
            return false;
        }
        let second = first.items.find(el => el.permissionName === val);
        if (second) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const orderList = (inputList: number[], iNum: number, isUp: boolean) => {
    const n = inputList.length;
    if (n < 2) return inputList;

    const f = inputList.indexOf(iNum);
    if (f === -1) return inputList;

    if (isUp === true) {
        if (f === 0) return inputList;
        let res: number[] = [];

        for (let j = 0; j < n; j++) {
            if (j < f - 1 || j > f) {
                res = [...res, inputList[j]];
            }
            if (j === f) {
                res = [...res, inputList[j], inputList[j - 1]];
            }
        }

        return res;
    }

    if (isUp === false) {
        if (f === n - 1) return inputList;
        let res: number[] = [];

        for (let j = 0; j < n; j++) {
            if (j < f || j > f + 1) {
                res = [...res, inputList[j]];
            }
            if (j === f) {
                res = [...res, inputList[j + 1], inputList[j]];
            }
        }

        return res;
    }

    return inputList;
};

export const makeDotList = (inputList: string[]) => {
    let res = "";
    let num = 0;
    for (let i = 0; i < inputList.length; i++) {
        if (i > 0) {
            res = res + "<br />"
        }
        res = res + "- " + inputList[i];
        num = num + 1;
    }
    return res;
};