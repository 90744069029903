import React, { useEffect, useState, Suspense } from "react";
import { AppContainer, StyledLoader } from "./style";
import {
    Route,
    Switch,
    BrowserRouter as Router
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { StylesProvider, CircularProgress } from '@material-ui/core';

import { getCurrentTenantConfig } from "../config/tenant-config";
import { ThemeConfig } from "../theme/themes/types";
import { MainHeader } from "../components/nav/Logo";
import { MainNav } from "../components/nav/Nav";
import { useStoreActions } from "../models/hooks";
import initI18n from "../i18n";

const MainPage = React.lazy(() => import("../pages/start/MainPage"));
const MainRegisterPage = React.lazy(() => import("../pages/register/MainRegisterPage"));
const SubRegisterPage = React.lazy(() => import("../pages/register/SubRegisterPage"));
const SubStartPage = React.lazy(() => import("../pages/register/SubStartPage"));
const SubEndPage = React.lazy(() => import("../pages/register/SubEndPage"));

const ForgotPasswordScreen = React.lazy(() => import("../pages/forgotPassword"));
const ResetPasswordScreen = React.lazy(() => import("../pages/resetPassword"));
const Portal = React.lazy(() => import("../pages/portal/Portal"));
const PortalPreview = React.lazy(() => import("../pages/portal/PortalPreview"));
const PortalTestings = React.lazy(() => import("../pages/portal/PortalTestings"));

const Blocked = React.lazy(() => import("../pages/register/registration/Blocked"));
const UserRevertScreen = React.lazy(() => import("../pages/userRevert"));

const VisitPrint = React.lazy(() => import("../pages/portal/visits/print"));
const EvaluatiePrint = React.lazy(() => import("../pages/portal/settings/evaluationMoments/print"));

interface Props {
    theme: ThemeConfig;
};

const App: React.FC<Props> = ({ theme }) => {
    const [ready, setReady] = useState<boolean>(false);
    const getTranslations = useStoreActions(
        (actions) => actions.translations.getTranslations
    );
    const { title } = getCurrentTenantConfig();
    document.title = title;

    const init = async () => {
        const resources = await getTranslations();
        initI18n(resources);
        setReady(true);
    };

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
                {ready && (
                    <Suspense
                        fallback={
                            <StyledLoader>
                                <CircularProgress size={50} color="inherit" />
                            </StyledLoader>
                        }
                    >
                        <Router>
                            <Switch>
                                <Route
                                    path="/portal/questionnaires/questionnaires/preview/:id"
                                    component={PortalPreview}
                                />
                                <Route
                                    path="/portal/questionnaires/evaluationMoments/preview/:id"
                                    component={PortalPreview}
                                />
                                <Route
                                    path="/portal/result/print/:code"
                                    component={VisitPrint}
                                />
                                <Route
                                    path="/portal/visit/print/:id"
                                    component={EvaluatiePrint}
                                />
                                <Route
                                    path="/start/1/:code"
                                    component={PortalTestings}
                                />
                                <Route path="/portal" component={Portal} />
                                <Route path="/beheer" component={Portal} />
                                <Route path="/forgotpassword" component={ForgotPasswordScreen} />
                                <Route path="/userrevert/:code" component={UserRevertScreen} />
                                <Route
                                    path="/resetpassword"
                                    component={(props: any) => <ResetPasswordScreen {...props} />}
                                />
                                <Route path="*">
                                    <AppContainer>
                                        <header>
                                            <MainHeader />
                                            <MainNav />
                                        </header>
                                        <Route exact path="/" component={MainPage} />
                                        <Route path="/blocked" component={Blocked} />
                                        <Route path="/register" component={MainRegisterPage} />
                                        <Route path="/registration/:code" component={SubStartPage} />
                                        <Route path="/aanmelden/:code" component={SubStartPage} />
                                        <Route path="/registrate/:code" component={SubStartPage} />
                                        <Route path="/completed/:code" component={SubEndPage} />
                                    </AppContainer>
                                </Route>
                            </Switch>
                        </Router>
                    </Suspense>
                )}
            </ThemeProvider>
        </StylesProvider>
    );
};

export default App;
