import { thunk, Thunk, Action, action } from "easy-peasy";

import {
  IpBlockListType,
  IdIpBlockType,
  deleteIpBlock,
  getIpBlockList,
} from "./ipBlock.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

export type ActionList = {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  count: number;
  items: IpBlockItem[];
  hasPrevious: boolean;
  hasNext: boolean;
};

export type IpBlockItem = {
  id: number;
  ip: string;
  date: string;
};

export type IpBlockModel = {
  getIpBlockList: Thunk<IpBlockModel, IpBlockListType>;
  deleteIpBlock: Thunk<IpBlockModel, IdIpBlockType>;
  error: ErrorEntry;
  setError: Action<IpBlockModel, ErrorEntry>;
  delError: Action<IpBlockModel>;
};

const ipBlock: IpBlockModel = {
  error: { code: 0 },
  setError: action((state, payload) => {
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),
  getIpBlockList: thunk(async (actions, payload) => {
    return fetchResponse<ActionList>({
      actions,
      payload,
      request: getIpBlockList,
    });
  }),
  deleteIpBlock: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: deleteIpBlock,
      message: "Ip block has been deleted",
    });
  })
};

export default ipBlock;
