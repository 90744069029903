import { ThemeConfig } from './types';

export const h1: ThemeConfig = {
  tenant: 'h1',
  baseColor: '#f58026',
  navColor: '#fff',
  textColor: '#444',
  hoverColor: '#b3b3b3',
  disabled: '#C8C8C8',
  inputBgColor: "#dfdfdf",
    matrixHoverColor: '#b3b3b3',
    matrixHoverTextColor: '#444',
    matrixOddBgColor: '#dfdfdf',
    matrixOddTextColor: '#444'
};

export default h1;