import ky from "ky";
import { URL_API } from "../helpers/routePaths";
const apiInstance = ky.create({
  prefixUrl: URL_API,
  hooks: {
    afterResponse: [
      (_request, _options, response) => {
        const localStorage = window.localStorage.getItem("is_logged");
        if (response.status === 401 && localStorage === "true") {
          window.localStorage.setItem("is_logged", "false");
          window.location.reload();
        }
        if (response.status === 404) {
          window.alert(`404 wrong address!`);
        }
        return response;
      },
    ],
  },
});
export default apiInstance;
