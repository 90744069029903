import React from 'react';

import NoBackgroundButton from './style';

export const ChangeFontSizeButtons: React.FC = () => {

  const basicHtml = document.getElementsByTagName('html')

  const changeFontSizeReg = () => {
    basicHtml[0].style.fontSize= "16px";

  }

  const changeFontSizeBiger = () => {
    basicHtml[0].style.fontSize= "20px";

  }

  const changeFontSizeBiest = () => {
    basicHtml[0].style.fontSize= "24px";

  }
 
    return (
      <>
        <NoBackgroundButton onClick={changeFontSizeReg}>A</NoBackgroundButton>
        <NoBackgroundButton onClick={changeFontSizeBiger}>A+</NoBackgroundButton>
        <NoBackgroundButton onClick={changeFontSizeBiest}>A++</NoBackgroundButton>
      </>
    )

}