import { thunk, Thunk, Action, action } from "easy-peasy";

import {
    getDashboardStats,
    StoriesProps,
    StoriesPropsTag,
    StoriesPropsCpmplements,
    TestingLogProps,
    SetSettledProps,
    StoriesPropsInternal,
    getStories,
    getLabels,
    getAllAnswers,
    getAllInfo,
    getAllInfoFiltered,
    getWords,
    getSubtrajects,
    getSubtrajects2,
    getTags,
    getComplements,
    getImprovements,
    getTestingLogs,
    setSettled,
    FooterProps,
    getFooter,
    VisitsProps,
    getDashboardVisits,
    getRefreshTime,
    RefreshTimeProps
} from "./dashboard.service";
import { ErrorEntry } from "../users/users.model";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";
import { getLastYear } from "../../helpers/utils";

export type DashboardModel = {
    error: ErrorEntry;
    fromDate?: string | null | undefined;
    toDate?: string | null | undefined;
    subtrajectoryIds?: number[] | undefined;
    npsPositive: boolean;
    npsNegative: boolean;
    npsNeutral: boolean;
    noNps: boolean;
    internal: boolean;
    fontSize: number;

    setFromDate: Action<DashboardModel, string | null | undefined>;
    setToDate: Action<DashboardModel, string | null | undefined>;
    setSubtrajectoryIds: Action<DashboardModel, number[]>;
    setNpsPositive: Action<DashboardModel, boolean>;
    setNpsNegative: Action<DashboardModel, boolean>;
    setNpsNeutral: Action<DashboardModel, boolean>;
    setNoNps: Action<DashboardModel, boolean>;
    setInternal: Action<DashboardModel, boolean>;
    setFontSize: Action<DashboardModel, number>;
    setError: Action<DashboardModel, ErrorEntry>;
    delError: Action<DashboardModel>;

    getDashboardStats: Thunk<DashboardModel>;
    getStories: Thunk<DashboardModel, StoriesProps>;
    getLabels: Thunk<DashboardModel, StoriesProps>;
    getAllAnswers: Thunk<DashboardModel, StoriesProps>;
    getAllInfo: Thunk<DashboardModel, StoriesPropsInternal>;
    getAllInfoFiltered: Thunk<DashboardModel, StoriesProps>;
    getWords: Thunk<DashboardModel, StoriesProps>;
    getSubtrajects: Thunk<DashboardModel>;
    getSubtrajects2: Thunk<DashboardModel, StoriesPropsInternal>;
    getTags: Thunk<DashboardModel, StoriesPropsTag>;
    getComplements: Thunk<DashboardModel, StoriesPropsCpmplements>;
    getImprovements: Thunk<DashboardModel, StoriesPropsCpmplements>;
    getTestingLogs: Thunk<DashboardModel, TestingLogProps>;
    setSettled: Thunk<DashboardModel, SetSettledProps>;
    getFooter: Thunk<DashboardModel, FooterProps>;
    getDashboardVisits: Thunk<DashboardModel, VisitsProps>;
    getRefreshTime: Thunk<DashboardModel, RefreshTimeProps>
};

const dashboard: DashboardModel = {
    error: { code: 0 },

    fromDate: getLastYear(),
    toDate: null,
    subtrajectoryIds: [],
    npsPositive: true,
    npsNegative: true,
    npsNeutral: true,
    noNps: true,
    internal: false,
    fontSize: 16,

    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    setFromDate: action((state, payload) => {
        state.fromDate = payload;
    }),
    setToDate: action((state, payload) => {
        state.toDate = payload;
    }),
    setSubtrajectoryIds: action((state, payload) => {
        state.subtrajectoryIds = payload;
    }),
    setNpsPositive: action((state, payload) => {
        state.npsPositive = payload;
    }),
    setNpsNegative: action((state, payload) => {
        state.npsNegative = payload;
    }),
    setNpsNeutral: action((state, payload) => {
        state.npsNeutral = payload;
    }),
    setNoNps: action((state, payload) => {
        state.noNps = payload;
    }),
    setInternal: action((state, payload) => {
        state.internal = payload;
    }),
    setFontSize: action((state, payload) => {
        state.fontSize = payload;
    }),

    getDashboardStats: thunk(async (actions) => {
        return fetchResponse<DashboardStats>({
            actions,
            request: getDashboardStats,
        });
    }),
    getStories: thunk(async (actions, payload) => {
        return fetchResponse<DashboardStories[]>({
            actions,
            payload,
            request: getStories,
        });
    }),
    getLabels: thunk(async (actions, payload) => {
        return fetchResponse<DashboardLabels>({
            actions,
            payload,
            request: getLabels,
        });
    }),
    getAllAnswers: thunk(async (actions, payload) => {
        return fetchResponse<DashboardAllAnswers>({
            actions,
            payload,
            request: getAllAnswers,
        });
    }),
    getAllInfo: thunk(async (actions, payload) => {
        return fetchResponse<DashboardAllInfo>({
            actions,
            payload,
            request: getAllInfo,
        });
    }),
    getAllInfoFiltered: thunk(async (actions, payload) => {
        return fetchResponse<DashboardAllInfo>({
            actions,
            payload,
            request: getAllInfoFiltered,
        });
    }),
    getWords: thunk(async (actions, payload) => {
        return fetchResponse<DashboardWord[]>({
            actions,
            payload,
            request: getWords,
        });
    }),
    getSubtrajects: thunk(async (actions) => {
        return fetchResponse<DashboardSubtraject[]>({
            actions,
            request: getSubtrajects,
        });
    }),
    getSubtrajects2: thunk(async (actions, payload) => {
        return fetchResponse<DashboardSubtraject[]>({
            actions,
            payload,
            request: getSubtrajects2,
        });
    }),
    getTags: thunk(async (actions, payload) => {
        return fetchResponse<DashboardTagListType>({
            actions,
            payload,
            request: getTags,
        });
    }),
    getComplements: thunk(async (actions, payload) => {
        return fetchResponse<DashboardComplementListType>({
            actions,
            payload,
            request: getComplements,
        });
    }),
    getImprovements: thunk(async (actions, payload) => {
        return fetchResponse<DashboardComplementListType>({
            actions,
            payload,
            request: getImprovements,
        });
    }),
    getTestingLogs: thunk(async (actions, payload) => {
        return fetchResponse<TeatingLogsListType>({
            actions,
            payload,
            request: getTestingLogs,
        });
    }),
    setSettled: thunk((actions, payload) => {
        return fetchResponse<DashboardComplementListType>({
            actions,
            payload,
            request: setSettled
        });
    }),
    getFooter: thunk(async (actions, payload) => {
        return fetchResponse<DashboardFooter>({
            actions,
            payload,
            request: getFooter,
        });
    }),
    getDashboardVisits: thunk(async (actions, payload) => {
        return fetchResponse<DashboardVisitListType>({
            actions,
            payload,
            request: getDashboardVisits,
        });
    }),
    getRefreshTime: thunk(async (actions, payload) => {
        return fetchResponse<number>({
            actions,
            payload,
            request: getRefreshTime,
        });
    }),
};

export type DashboardFooter = {
    noInvitation: number,
    bounced: number,
    noResponce: number
};

export type DashboardStats = {
    userActions: number,
    userUnfinishedActions: number,
    openNotifications: number,
    userId: number
};

export type DashboardStories = {
    queryPropertyTitle: string,
    storyContent: string,
    testingCode: string,
    storyType: number
};

export type DashboardLabel = {
    labelId: number,
    labelName: string,
    count: number
};

export type DashboardLabels = {
    complimentLabels: DashboardLabel[],
    improvementLabels: DashboardLabel[],
    actionCount: number,
    actionHandledCount: number
};

export type DashboardAllAnswers = {
    numberOfSatisfiedAnswers: number,
    numberOfToImproveAnswers: number
};

export type DashboardAllInfo = {
    numberOfVisits: number,
    reportMark: number,
    nps: number,
    percentOfNpsPositive: number,
    percentOfNpsNegative: number,
    percentOfNpsNeutral: number,
    percentOfNoNps: number,
    percentOfNpsPositive2: number,
    percentOfNpsNegative2: number,
    percentOfNpsNeutral2: number
};

export type DashboardWord = {
    tag: string,
    type: number,
    size: number
};

export type DashboardSubtraject = {
    label: string,
    value: string,
    disabled: boolean,
    subtrajects: string[]
};

export type DashboardSubtrajectSel = {
    label: string,
    value: string
};

export type DashboardTagItem = {
    operationId: number,
    testingId: number,
    evaluationCode: string,
    patientNumber: string,
    value: string,
    subtrajectoryName: string,
    operationDate: string
};

export type DashboardTagListType = {
    currentPage: number,
    totalPages: number,
    pageSize: number,
    totalCount: number,
    count: number,
    items: DashboardTagItem[],
    hasPrevious: boolean,
    hasNext: boolean
};

export type DashboardComplementItem = {
    testingCode: string,
    patientNumber: string,
    evaluationMomentId: number,
    evaluationMomentName: string,
    subcategoryName: string,
    answer: string,
    notificationCount: number,
    notificationUnhandledCount: number,
    actionCount: number,
    actionUnhandledCount: number,
    labelCount: number,
    labelCountAI: number,
    lastLogging: string,
    settled: boolean,
    settledBy: string,
    testingCount: number
};

export type DashboardComplementListType = {
    currentPage: number,
    totalPages: number,
    pageSize: number,
    totalCount: number,
    count: number,
    items: DashboardComplementItem[],
    hasPrevious: boolean,
    hasNext: boolean
};

export type TestingLogsItem = {
    logTime: string,
    logUser: string
};

export type TeatingLogsListType = {
    currentPage: number,
    totalPages: number,
    pageSize: number,
    totalCount: number,
    count: number,
    items: TestingLogsItem[],
    hasPrevious: boolean,
    hasNext: boolean
};

export type DashboardVisitItem = {
    operationId: number,
    testingId: number,
    evaluationCode: string,
    bezoekNumber: string,
    patientNumber: string,
    patientEmail: string,
    subtrajectoryName: string,
    visitDate: string,
    operationDate: string,
    plannedDate: string,
    type: number,
    invitationName: string[]
};

export type DashboardVisitListType = {
    currentPage: number,
    totalPages: number,
    pageSize: number,
    totalCount: number,
    count: number,
    items: DashboardVisitItem[],
    hasPrevious: boolean,
    hasNext: boolean
};

export default dashboard;
