import { ThemeConfig } from './types';

export const h9: ThemeConfig = {
    tenant: 'h9',
    baseColor: '#c8d200',
    navColor: '#ffffff',
    textColor: '#444',
    hoverColor: '#c8d200',
    disabled: '#999999',
    inputBgColor: "#FBFBFC",
    matrixHoverColor: '#c8d200',
    matrixHoverTextColor: '#444',
    matrixOddBgColor: '#FBFBFC',
    matrixOddTextColor: '#444'
};

export default h9;

/*
@text_header: #323269;
@text_link: #c8d200;
*/