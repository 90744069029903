import { thunk, Thunk, Action, action } from "easy-peasy";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

import { getTags, setTags, TagsListModel } from "./tags.service";
import { ErrorEntry } from "../users/users.model";

export type TagsModel = {
  error: ErrorEntry;
  setError: Action<TagsModel, ErrorEntry>;
  delError: Action<TagsModel>;
  getTags: Thunk<TagsModel>;
  setTags: Thunk<TagsModel, TagsListModel>;
};

const Tags: TagsModel = {
  error: { code: 0 },
  setError: action((state, payload) => {
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),
  getTags: thunk(async (actions) => {
    return fetchResponse<TagsListModel>({
      actions,
      request: getTags,
    });
  }),
  setTags: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: setTags,
      message: "Tags had been edited",
    });
  }),
};

export default Tags;
