import ky from "../ky";
import { LabelData } from "./labels.model";

export type LabelListType = {
    currentPageNo: number;
    query: string;
    sortCol: string;
    isDescending: boolean;
};

export const getLabelList = ({
    currentPageNo,
    query,
    sortCol,
    isDescending,
}: LabelListType) =>
    ky.get(
        `Labels?PageNumber=${currentPageNo}&SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10`
    );

export const getLabelL = () => ky.get(`Labels`);

export type IdLabelType = {
    id?: string | number;
};

export const deleteLabel = ({ id }: IdLabelType) =>
    ky.delete(`Labels?id=${id}`);

export type LabelType = {
    id?: number;
    title: string;
};

export type LabelFavouriteParam = {
    id: number;
    favourite: boolean;
};

export const addLabel = ({ title }: LabelType) =>
    ky.post(`Labels/add`, { json: { title } });

export const updateLabel = ({ title, id }: LabelType) =>
    ky.put(`Labels`, { json: { title, id } });

export const getOneLabel = ({ id }: IdLabelType) =>
    ky.get(`Labels/${id}`);

export const getAllLabels = () => ky.get(`Labels/toShow`);

export type TestingCodeType = {
    code: string;
};

export type TestingCodeSingleType = {
    code: string;
    type: number;
};

export type ImportType = {
    languageCode: string,
    data: FormData
};

export type ImportFinalType = {
    importData: string[][]
};

export const getLabelsForCode = ({ code }: TestingCodeType) =>
    ky.get(`Labels/labelOperations/toShow?testingCode=${code}`);

export const getLabelsDataForCode = ({ code }: TestingCodeType) =>
    ky.get(`Labels/labelOperations?testingCode=${code}`);

export type UpdateLabelsProps = TestingCodeType & {
    labelOperations: LabelData[];
};

export type UpdateLabelsPropsSingle = UpdateLabelsProps & {
    type: number;
};

export const updateLabels = ({ code, labelOperations }: UpdateLabelsProps) =>
    ky.post(`Labels/labelOperations`, {
        json: {
            testingCode: code,
            labelOperations
        }
    });

export const updateLabelsSingle = ({ code, labelOperations, type }: UpdateLabelsPropsSingle) =>
    ky.post(`Labels/labelOperations?type=${type}`, {
        json: {
            testingCode: code,
            labelOperations
        }
    });

export const getLabelsDataForCodeSingle = ({ code, type }: TestingCodeSingleType) =>
    ky.get(`Labels/labelOperations?testingCode=${code}&type=${type}`);

export const importData = ({ languageCode, data }: ImportType) =>
    ky.post(`Labels/Import/Get?languageCode=${languageCode}`, { body: data });

export const importDataFinal = ({ importData }: ImportFinalType) =>
    ky.post(`Labels/addMany`, { json: { data: importData } });

export const setFavourite = ({ id, favourite }: LabelFavouriteParam) =>
    ky.put(`Labels/favourite?id=${id}&favourite=${favourite}`);