import ky from "../ky";
import { UserIdType } from "../../pages/portal/settings/roles/add";

export type RoleListType = {
  userName: string;
  userId?: number;
  currentPageNo: number;
  query: string;
  sortCol: string;
  isDescending: boolean;
};

export const getRoleList = ({
  userName,
  userId,
  currentPageNo,
  query,
  sortCol,
  isDescending,
}: RoleListType) =>
  ky.get(
    `Roles?UserName=${userName}&UserId=${userId}&PageNumber=${currentPageNo}&SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10`
  );

// &UserId=${userId}

export const getRoleL = () => ky.get(`Roles`);

export const getRoleSignalsList = ({ id }: IdRoleType) => ky.get(`Roles/Signals/${id}`);

export type IdRoleType = {
  id?: string | number;
};

export const deleteRole = ({ id }: IdRoleType) => ky.delete(`Roles?id=${id}`);

export type RoleType = {
  name: string;
  idUser: UserIdType[];
  id?: number;
};

export const addRole = ({ name, idUser }: RoleType) =>
  ky.post(`Roles/add`, { json: { name, userRoles: idUser } });

export const updateRole = ({ name, idUser, id }: RoleType) =>
  ky.put(`Roles`, { json: { name, userRoles: idUser, id } });

export const getOneRole = ({ id }: IdRoleType) => ky.get(`Roles/${id}`);

export const getRolesToShow = () => ky.get(`Roles/allRolesToShow`);
