import ky from "../ky";

export const getTags = () => ky.get(`Tags`);

type TagRecord = {
  id: number;
  name: string;
};

export type TagsListModel = {
  tagsPositiveDto: TagRecord[];
  tagsNegativeDto: TagRecord[];
};

export const setTags = ({ tagsPositiveDto, tagsNegativeDto }: TagsListModel) =>
  ky.post(`Tags`, { json: { tagsPositiveDto, tagsNegativeDto } });
