import { ThemeConfig } from './types';

export const h6: ThemeConfig = {
    tenant: 'h6',
    baseColor: '#B2B2B2',
    navColor: '#fff',
    textColor: '#444',
    hoverColor: '#B2B2B2',
    disabled: '#999999',
    inputBgColor: "#B2B2B2",
    matrixHoverColor: '#B2B2B2',
    matrixHoverTextColor: '#444',
    matrixOddBgColor: '#B2B2B2',
    matrixOddTextColor: '#444'
};

export default h6;