import { thunk, Thunk, Action, action } from "easy-peasy";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";
import {
  GetAnswerType,
  addAnswer,
  AddAnswerTypes,
  GetAnswerResponseObject,
  getAnswer,
} from "./answers.service";

export type AnswerTypesModel = {
  error: ErrorEntry;
  setError: Action<AnswerTypesModel, ErrorEntry>;
  delError: Action<AnswerTypesModel>;
  getAnswer: Thunk<AnswerTypesModel, GetAnswerType>;
  addAnswer: Thunk<AnswerTypesModel, AddAnswerTypes>;
};

const answers: AnswerTypesModel = {
  error: { code: 0 },
  setError: action((state, payload) => {
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),
  getAnswer: thunk(async (actions, payload) => {
    return fetchResponse<GetAnswerResponseObject[]>({
      payload,
      actions,
      request: getAnswer,
    });
  }),
  addAnswer: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: addAnswer,
      message: "Answer successfully added",
    });
  }),
};

export default answers;
