import ky from "../ky";

export type GetAnswerType = {
  questionId: number;
  languageCode?: string;
};

export type AddAnswerTypes = {
  questionId: number;
  idx: Array<number>;
};

export type GetAnswerResponseObject = {
  id: number;
  answerText: string;
};

export const getAnswer = ({ questionId, languageCode }: GetAnswerType) =>
  ky.get(`Answers?questionId=${questionId}&languageCode=${languageCode}`);

export const addAnswer = ({ questionId, idx }: AddAnswerTypes) =>
  ky.post(`Answers/add?questionId=${questionId}`, { json: { ids: idx } });
