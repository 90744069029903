import { thunk, Thunk, Action, action } from "easy-peasy";
import {
  getSubCommList,
  SubCommId,
  updateSubComm,
  SubCommType,
  deleteSubComm,
  CommId,
  getOneComm,
  addSubComm,
  getBasicComm,
  SubtrajctoryId,
  sendTestComm,
} from "./communication.service";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";
import { ErrorEntry } from "../users/users.model";

export type CommunicationListItem = {
  id: number;
  subtrajectoryId: number;
  days: number;
  active: boolean;
  email: boolean;
  subtrajectoryCommunicationTextDto: CommunicationTextType;
  subtrajectoryCommunicationTextTitle?: string;
};

export type CommunicationTextType = {
  title: string;
  mailTitle: string;
  mailText: string;
  mailHtml: string;
  smsText: string;
};

export type CommunicationModel = {
  getSubCommList: Thunk<CommunicationModel, SubCommId>;
  updateSubComm: Thunk<CommunicationModel, SubCommType>;
  addSubComm: Thunk<CommunicationModel, SubCommType>;
  deleteSubComm: Thunk<CommunicationModel, CommId>;
  getOneComm: Thunk<CommunicationModel, CommId>;
  getBasicComm: Thunk<CommunicationModel, SubtrajctoryId>;
  sendTestComm: Thunk<CommunicationModel, SubCommType>;
  error: ErrorEntry;
  setError: Action<CommunicationModel, ErrorEntry>;
  delError: Action<CommunicationModel>;
};

const communication: CommunicationModel = {
  error: { code: 0 },
  setError: action((state, payload) => {
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),
  getSubCommList: thunk(async (actions, payload) => {
    return fetchResponse<CommunicationListItem[]>({
      actions,
      payload,
      request: getSubCommList,
    });
  }),
  updateSubComm: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: updateSubComm,
      message: "Communication moment has been edited",
    });
  }),
  deleteSubComm: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: deleteSubComm,
      message: "Communication moment has been deleted",
    });
  }),
  getOneComm: thunk(async (actions, payload) => {
    return fetchResponse<CommunicationListItem>({
      actions,
      payload,
      request: getOneComm,
    });
  }),
  addSubComm: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: addSubComm,
      message: "Communication moment has been added",
    });
  }),
  getBasicComm: thunk(async (actions, payload) => {
    return fetchResponse<CommunicationListItem>({
      actions,
      payload,
      request: getBasicComm,
    });
  }),
  sendTestComm: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: sendTestComm,
      message: "Test mail has been send",
    });
  }),
};

export default communication;
