import { thunk, Thunk, Action, action } from "easy-peasy";
import {
    getAnsList,
    LanguageCodeType,
    updateAnswer,
    EditAnsType,
    EditAnsTypeDef,
    deleteAnswer,
    AnsId,
    getOneAnswer,
    addAnswer,
    addAnswerDef,
    getBasicAns,
    ImportAnswerType,
    importAnswers,
    ImportAnswerFinalType,
    importAnswersFinal
} from "./defAnswers.service";
import { ErrorEntry } from "../users/users.model";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";

export interface AnswerType {
    id: number;
    spsslabel: string;
    theme: string;
    themeCode: number;
    score: number;
    locked: boolean;
    defAnswerTextDto: AnsTextType;
    active: boolean;
    questionaires: string[];
}

export type AnsTextType = {
    value: string;
};

export type DefAnswerModel = {
    getAnsList: Thunk<DefAnswerModel, LanguageCodeType>;
    updateAnswer: Thunk<DefAnswerModel, EditAnsType>;
    addAnswer: Thunk<DefAnswerModel, EditAnsType>;
    addAnswerDef: Thunk<DefAnswerModel, EditAnsTypeDef>;
    getBasicAns: Thunk<DefAnswerModel>;
    deleteAnswer: Thunk<DefAnswerModel, AnsId>;
    getOneAnswer: Thunk<DefAnswerModel, AnsId>;
    importAnswers: Thunk<DefAnswerModel, ImportAnswerType>;
    importAnswersFinal: Thunk<DefAnswerModel, ImportAnswerFinalType>;
    error: ErrorEntry;
    setError: Action<DefAnswerModel, ErrorEntry>;
    delError: Action<DefAnswerModel>;
};

export type ColumnName = {
    key: string,
    val: string
};

export type ImportData = {
    columnNames: ColumnName[],
    fileData: string[][],
    languageCode: string
};

export type ImportResult = {
    totalRowsCount: number,
    processedRowsCount: number,
    ignoredRowsCount: number,
    successedRowsCount: number,
    wrongRowsCount: number,
    addedRowsCount: number,
    updatedRowsCount: number,
    errors: ColumnName[]
};

const defAnswers: DefAnswerModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getAnsList: thunk(async (actions, payload) => {
        return fetchResponse<AnswerType[]>({
            actions,
            payload,
            request: getAnsList,
        });
    }),
    updateAnswer: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateAnswer,
            message: "Answer has been edited",
        });
    }),
    deleteAnswer: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteAnswer,
            message: "Answer has been deleted",
        });
    }),
    getOneAnswer: thunk(async (actions, payload) => {
        return fetchResponse<AnswerType>({
            actions,
            payload,
            request: getOneAnswer,
        });
    }),
    addAnswer: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addAnswer,
            message: "Answer has been added",
        });
    }),
    addAnswerDef: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addAnswerDef,
            message: "Answer has been added",
        });
    }),
    getBasicAns: thunk(async (actions) => {
        return fetchResponse<AnswerType>({
            actions,
            request: getBasicAns,
        });
    }),
    importAnswers: thunk(async (actions, payload) => {
        return fetchResponse<ImportData>({
            actions,
            payload,
            request: importAnswers
        });
    }),
    importAnswersFinal: thunk(async (actions, payload) => {
        return fetchResponse<ImportResult>({
            actions,
            payload,
            request: importAnswersFinal
        });
    }),
};

export default defAnswers;
