import { ThemeConfig } from './types';

export const h10: ThemeConfig = {
    tenant: 'h10',
    baseColor: '#55A656',
    navColor: '#ffffff',
    textColor: '#444',
    hoverColor: '#D43545',
    disabled: '#999999',
    inputBgColor: "#FBFBFC",
    matrixHoverColor: '#D43545',
    matrixHoverTextColor: '#444',
    matrixOddBgColor: '#FBFBFC',
    matrixOddTextColor: '#444'
};

export default h10;

/*
@kleur_blauw: #D43545;
@kleur_donkerblauw: #55A656;
@kleur_lichtgrijs: #b3b3b3;
@kleur_donkergrijs: #444444;
*/