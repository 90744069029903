import { thunk, Thunk, Action, action } from "easy-peasy";

import {
  InstanceId,
  getPatientFields,
  updatePatientField,
  getPatientFieldListToShow,
  getOnePatientField,
  deletePatientField,
  addPatientField,
  getBasicPatientField,
  PatientFieldData,
  PatientFiledDto,
  PatenitFiledId,
  PatientFieldAnswerDto,
  UpdatePositionsType,
  editPositionList,
} from "./patientField.service";
import { ErrorEntry } from "../users/users.model";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";

export type PatientFieldItem = {
  id: number;
  title: string;
  spsslabel: string;
  obligated: boolean;
  active: boolean;
  position: number;
};

export type PatientFieldToShow = {
  id: number;
  title: string;
  obligated: true;
  type: number;
  patientFieldAnswers: [
    {
      idToken: string;
      value: string;
    }
  ];
};

export type PatientFieldModel = {
  getPatientFields: Thunk<PatientFieldModel, InstanceId>;
  updatePatientField: Thunk<PatientFieldModel, PatientFieldData>;
  deletePatientField: Thunk<PatientFieldModel, PatenitFiledId>;
  getOnePatientField: Thunk<PatientFieldModel, PatenitFiledId>;
  addPatientField: Thunk<PatientFieldModel, PatientFieldData>;
  getBasicPatientField: Thunk<PatientFieldModel, InstanceId>;
  editPositionList: Thunk<PatientFieldModel, UpdatePositionsType>;
  getPatientFieldListToShow: Thunk<PatientFieldModel>;
  error: ErrorEntry;
  setError: Action<PatientFieldModel, ErrorEntry>;
  delError: Action<PatientFieldModel>;
};

const patientFields: PatientFieldModel = {
  error: { code: 0 },
  setError: action((state, payload) => {
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),
  getPatientFields: thunk(async (actions, payload) => {
    return fetchResponse<PatientFieldItem[]>({
      actions,
      payload,
      request: getPatientFields,
    });
  }),
  updatePatientField: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: updatePatientField,
      message: "Patient Field has been edited",
    });
  }),
  deletePatientField: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: deletePatientField,
      message: "Patient Field has been deleted",
    });
  }),
  getOnePatientField: thunk(async (actions, payload) => {
    return fetchResponse<PatientFiledDto>({
      actions,
      payload,
      request: getOnePatientField,
    });
  }),
  addPatientField: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: addPatientField,
      message: "Patient Field has been added",
    });
  }),
  getBasicPatientField: thunk(async (actions, payload) => {
    return fetchResponse<PatientFiledDto>({
      actions,
      payload,
      request: getBasicPatientField,
    });
  }),
  getPatientFieldListToShow: thunk(async (actions) => {
    return fetchResponse<PatientFieldToShow[]>({
      actions,
      request: getPatientFieldListToShow,
    });
  }),
  editPositionList: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: editPositionList,
      message: "Patient Field postions has been changed",
    });
  }),
};

export default patientFields;
