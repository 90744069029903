import ky from "../ky";

import { BasicDoctorModel } from "./doctors.model";

export type DoctorsListType = {
  currentPageNo: number;
  query: string;
  sortCol: string;
  isDescending: boolean;
};

export const getDoctors = ({
  currentPageNo,
  query,
  sortCol,
  isDescending,
}: DoctorsListType) =>
  ky.get(
    `Doctors?PageNumber=${currentPageNo}&SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10`
  );

export type DeleteDoctorType = {
  id: number;
};

export const deleteDoctor = ({ id }: DeleteDoctorType) =>
  ky.delete(`Doctors?id=${id}`);

export const getBasicDoctor = () => ky.get(`Doctors/getBasic`);

export const addDoctor = ({
  id,
  instanceId,
  name,
  importCode,
  email,
  password,
  active,
  languageId,
}: BasicDoctorModel) =>
  ky.post(`Doctors/add`, {
    json: {
      id,
      instanceId,
      name,
      importCode,
      email,
      password,
      active,
      languageId,
    },
  });

export type EditDoctorType = {
  id?: string;
};

export const editDoctor = ({ id }: EditDoctorType) => ky.get(`Doctors/${id}`);

export const updateDoctor = ({
  id,
  instanceId,
  name,
  importCode,
  email,
  password,
  active,
  languageId,
}: BasicDoctorModel) =>
  ky.put(`Doctors`, {
    json: {
      id,
      instanceId,
      name,
      importCode,
      email,
      password,
      active,
      languageId,
    },
  });

export const getListToShow = () => ky.get(`Doctors/getAllToShow`);
