import ky from "../ky";

export type IpBlockListType = {
  currentPageNo: number;
  query: string;
  sortCol: string;
  isDescending: boolean;
};

export const getIpBlockList = ({
  currentPageNo,
  query,
  sortCol,
  isDescending,
}: IpBlockListType) =>
  ky.get(
    `IpBlocks?PageNumber=${currentPageNo}&SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10`
  );

export type IdIpBlockType = {
  id?: string | number;
};

export const deleteIpBlock = ({ id }: IdIpBlockType) =>
  ky.delete(`IpBlocks?id=${id}`);

