import ky from "../ky";

const urlHelper = (
  id: string | number | boolean | "", 
  param: string, 
  end?: string
) => {
  return (id === false || (id && id !== "")) ? `${param}=${id}${end && end}` : "";
};

export type NotificationListType = {
  subcategoryId: number | "",
  userId: number | "",
  followed: boolean | "",
  currentPageNo: number,
  search: string,
  sortCol: string,
  isDescending: boolean,
  pageSize: number,
  languageCode: string
};

export const getNotifications = ({
  subcategoryId,
  userId,
  followed,
  currentPageNo,
  search,
  sortCol,
  isDescending,
  pageSize,
  languageCode
}: NotificationListType) =>
    ky.get(`Notifications?${urlHelper(subcategoryId, "SubtrajectoryId", "&")}${urlHelper(userId, "UserId", "&")}${urlHelper(followed, "Followed", "&")}${urlHelper(currentPageNo, "PageNumber", "&")}${urlHelper(search, "SearchWord", "&")}${urlHelper(sortCol, "OrderBy", "&")}${urlHelper(isDescending, "IsDescending", "&")}${urlHelper(pageSize, "PageSize", "&")}&LanguageCode=${languageCode}`);

export type NotifiactionIdType = {
  id: number
};

export type NotificationPayloadType = NotifiactionIdType & {
  languageCode: string
};

export const getNotificationById = ({ 
  id, 
  languageCode 
}: NotificationPayloadType) =>
  ky.get(`Notifications/${id}?languageCode=${languageCode}`);

export type NotifiactionUpdatePayloadType = NotifiactionIdType & {
  signal: boolean
};

export const updateNotifiactionSignal = ({ 
  id, 
  signal 
}: NotifiactionUpdatePayloadType) =>
  ky.put(`Notifications`, {
    json: {
      id,
      followed: signal
    }
  });

export const deleteNotification = ({ id }: NotifiactionIdType) =>
  ky.delete(`Notifications?id=${id}`);

export const getSignalsDuringEvaluationId = ({ 
  id, 
  languageCode 
}: NotificationPayloadType) =>
  ky.get(`Notifications/fromTestingByNotificationId?notificationId=${id}&languageCode=${languageCode}`);

export type SignalsPayloadType = {
  code: string,
  languageCode: string
};

export const getSignalsDuringEvaluationCode = ({ 
  code, 
  languageCode 
}: SignalsPayloadType) =>
  ky.get(`Notifications/fromTestingByCode?testingCode=${code}&languageCode=${languageCode}`);