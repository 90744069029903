import { thunk, Thunk, Action, action } from "easy-peasy";

import {
    FitlerPropsType,
    FilterPageName,
    getFilters,
    updateFilters,
    getPageFilters
} from "./filters.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

export type FiltersModel = {
    error: ErrorEntry;

    setError: Action<FiltersModel, ErrorEntry>;
    delError: Action<FiltersModel>;

    getFilters: Thunk<FiltersModel, FilterPageName>;
    updateFilters: Thunk<FiltersModel, FitlerPropsType>;

    getPageFilters: Thunk<FiltersModel>;
};

const filters: FiltersModel = {
    error: { code: 0 },

    setError: action((state, payload) => {
        console.log({ payload })
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),

    getFilters: thunk(async (actions, payload) => {
        return fetchResponse<FitlerPropsType>({
            actions,
            payload,
            request: getFilters,
        });
    }),
    updateFilters: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateFilters,
            message: "Filter has been edited",
        });
    }),
    getPageFilters: thunk(async (actions) => {
        return fetchResponse<FitlerPropsType[]>({
            actions,
            request: getPageFilters,
        });
    }),
};

export default filters;
