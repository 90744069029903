import { thunk, Thunk, Action, action } from "easy-peasy";
import {
    getVisitsList,
    updateVisit,
    addVisit,
    deleteVisit,
    deleteVisits,
    getOneVisit,
    getBasicVisit,
    inactivePatient,
    activePatient,
    DeleteVisitId,
    DeleteVisitsId,
    VisitId,
    PatientId,
    VisitsListType,
    getInvitation,
    getNps,
    getStory,
    getVisitNavigation,
    VisitLogProps,
    getVisitLogs,
    getScores,
    importVisits,
    ImportVisitsType,
    importVisitsFinal,
    ImportVisitsFinalType,
    getLabelsTestingsList,
    LabelsVisitsListType,
    getVisitsExport,
    TableTypeExport,
    getVisitsImportList,
    SimpleId,
    getImportResult,
    deleteVisitsImport
} from "./visits.service";
import { ErrorEntry } from "../users/users.model";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";

export interface VisitType {
    operationDto: OperationType;
    patientDto: PatientType;
    isSecondButton?: boolean;
}

export type OperationType = {
    id: number;
    patientId: number;
    recordNumber: string;
    showRecordNumber: boolean;
    recordNumberRequired: boolean;
    intakeDate: string;
    operationDate: string;
    showOperationDate: boolean;
    subtrajectoryId: number;
    trajectoryId: number;
    doctorId: number;
    subtrajectoryOptionId: number;
    deleteReasonId: number;
    comments: string;
    showComments: boolean;
    sharingAllowed: boolean;
    discuss: boolean;
};

export type PatientType = {
    id: number;
    patientNumber: string;
    languageId: number;
    email: string;
    emailRequired: boolean;
    phoneNumber: string;
    inactive: boolean;
    anonymousLink: boolean;
    mailToPatient: boolean;
    patientFieldValueDto: PatientFieldValueType[];
};

export type PatientFieldValueType = {
    id: number;
    patientFieldId: number;
    value: string;
};

export interface VisitList {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    count: number;
    items: VisitItem[];
    hasPrevious: boolean;
    hasNext: boolean;
}

export type VisitItem = {
    testingCode: string;
    operationId: number;
    patientNumber: string;
    email: string;
    recordNumber: string;
    intakeDate: string;
    subtrajectoryId: number;
    subtrajectoryName: string;
    active: boolean;
    nps: number;
    storyGreen: number;
    storyOrange: number;
    storyGrey: number;
    reportMark: number;
    notificationCount: number;
    notificationUnhandledCount: number;
    evaluationMomentName: string;
    questionaireStatusName: string;
    filledDate: string;
    invitation: boolean;
    invitationStatus: number;
};

export interface LabelVisitList {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    count: number;
    items: LabelVisitItem[];
    hasPrevious: boolean;
    hasNext: boolean;
}

export type LabelVisitItem = {
    index: number;
    testingCode: string;
    label: string;
    value: string;
    type: number;
    intakeDate: string;
    patientNumber: string;
    subtrajectoryName: string;
    evaluatieName: string;
};

export type FilterType = {
    id: number;
    name: string;
};

export type TestingsType = {
    testingId: number,
    evaluationMomentId: number,
    testingCode: string | null,
    evaluationMomentName: string | null,
    date: string,
    patientStatusName: string
};

export type NavigationResponse = {
    patientNumber: string | number,
    operationId: number,
    testingsForPatient: TestingsType[];
    testingsForWorker: TestingsType[];
};

export type NavigationLogItem = {
    id: number,
    time: string,
    objectClass: string,
    objectId: number,
    objectName: string,
    actionName: string,
    actorName: string,
    isUserName: boolean,
    extraInfo: string
};

export type NavigationLogs = {
    currentPage: number,
    totalPages: number,
    pageSize: number,
    totalCount: number,
    count: number,
    items: NavigationLogItem[],
    hasPrevious: boolean,
    hasNext: boolean
};

export type ScoreProperty = {
    id: number,
    testingId: number,
    questionaireId: number,
    score: number,
    totalScore: boolean,
    queryPropertyId: number,
    queryPropertyName: string,
    role: number
};

export type ScoresTesting = {
    testingId: number,
    evaluationMomentId: number,
    evaluationMomentName: string,
    displayStrings: string[],
    propertyScoresDto: ScoreProperty[]
};

export type ScoresQuestionList = {
    questionaireId: number,
    questionaireName: string,
    testingScoresDto: ScoresTesting[]
};

export type ScoreEvaluationMoment = {
    evaluationMomentId: number,
    evaluationMomentName: string,
    testingCode: string
};

export type ScoresItems = {
    evaluationMomentsDto: ScoreEvaluationMoment[],
    questionaireScoresQuestionsListDto: ScoresQuestionList[]
};

export type ColumnName = {
    key: string,
    val: string
};

export type ImportData = {
    columnNames: ColumnName[],
    fileData: string[][],
    toSend: boolean
};

export type ImportResult = {
    totalRowsCount: number,
    processedRowsCount: number,
    ignoredRowsCount: number,
    successedRowsCount: number,
    wrongRowsCount: number,
    addedRowsCount: number,
    updatedRowsCount: number,
    errors: ColumnName[]
};

export type VisitImportRecord = {
    id: number,
    created: string,
    createdByName: string,
    total: number,
    status: number
};

export interface VisitImportRecordList {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    count: number;
    items: VisitImportRecord[];
    hasPrevious: boolean;
    hasNext: boolean;
}

export type VisitModel = {
    error: ErrorEntry;

    setError: Action<VisitModel, ErrorEntry>;
    delError: Action<VisitModel>;

    getVisitsList: Thunk<VisitModel, VisitsListType>;
    updateVisit: Thunk<VisitModel, VisitType>;
    addVisit: Thunk<VisitModel, VisitType>;
    deleteVisit: Thunk<VisitModel, DeleteVisitId>;
    deleteVisits: Thunk<VisitModel, DeleteVisitsId>;
    getOneVisit: Thunk<VisitModel, VisitId>;
    getBasicVisit: Thunk<VisitModel>;
    getStory: Thunk<VisitModel>;
    getInvitation: Thunk<VisitModel>;
    getNps: Thunk<VisitModel>;
    inactivePatient: Thunk<VisitModel, PatientId>;
    activePatient: Thunk<VisitModel, PatientId>;
    getVisitNavigation: Thunk<VisitModel, VisitId>;
    getVisitLogs: Thunk<VisitModel, VisitLogProps>;
    getScores: Thunk<VisitModel, VisitId>;
    importVisits: Thunk<VisitModel, ImportVisitsType>;
    importVisitsFinal: Thunk<VisitModel, ImportVisitsFinalType>;
    getLabelsTestingsList: Thunk<VisitModel, LabelsVisitsListType>;
    getVisitsExport: Thunk<VisitModel, VisitsListType>;
    getVisitsImportList: Thunk<VisitModel, TableTypeExport>;
    getImportResult: Thunk<VisitModel, SimpleId>;
    deleteVisitsImport: Thunk<VisitModel, SimpleId>;
};

const Visits: VisitModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getVisitsList: thunk(async (actions, payload) => {
        return fetchResponse<VisitList>({
            actions,
            payload,
            request: getVisitsList,
        });
    }),
    updateVisit: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateVisit,
            message: "Visit has been edited",
        });
    }),
    addVisit: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addVisit,
            message: "Visit has been added",
        });
    }),
    deleteVisit: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteVisit,
            message: "Visit has been deleted",
        });
    }),
    deleteVisits: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteVisits,
            message: "Visits has been deleted",
        });
    }),
    getOneVisit: thunk(async (actions, payload) => {
        return fetchResponse<VisitType>({
            actions,
            payload,
            request: getOneVisit,
        });
    }),
    getBasicVisit: thunk(async (actions, payload) => {
        return fetchResponse<VisitType>({
            actions,
            request: getBasicVisit,
        });
    }),
    getNps: thunk(async (actions) => {
        return fetchResponse<FilterType[]>({
            actions,
            request: getNps,
        });
    }),
    getStory: thunk(async (actions) => {
        return fetchResponse<FilterType[]>({
            actions,
            request: getStory,
        });
    }),
    getInvitation: thunk(async (actions) => {
        return fetchResponse<FilterType[]>({
            actions,
            request: getInvitation,
        });
    }),
    inactivePatient: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: inactivePatient,
            message: "Patient has been made inactive",
        });
    }),
    activePatient: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: activePatient,
            message: "Visit has been made active",
        });
    }),
    getVisitNavigation: thunk(async (actions, payload) => {
        return fetchResponse<NavigationResponse>({
            actions,
            payload,
            request: getVisitNavigation
        });
    }),
    getVisitLogs: thunk(async (actions, payload) => {
        return fetchResponse<NavigationLogs>({
            actions,
            payload,
            request: getVisitLogs
        });
    }),
    getScores: thunk(async (actions, payload) => {
        return fetchResponse<ScoresItems>({
            actions,
            payload,
            request: getScores,
        });
    }),
    importVisits: thunk(async (actions, payload) => {
        return fetchResponse<ImportData>({
            actions,
            payload,
            request: importVisits
        });
    }),
    importVisitsFinal: thunk(async (actions, payload) => {
        return fetchResponse<ImportResult>({
            actions,
            payload,
            request: importVisitsFinal
        });
    }),
    getLabelsTestingsList: thunk(async (actions, payload) => {
        return fetchResponse<LabelVisitList>({
            actions,
            payload,
            request: getLabelsTestingsList,
        });
    }),
    getVisitsExport: thunk(async (actions, payload) => {
        try {
            const response = await getVisitsExport(payload);
            return response;
        } catch (error) {
            console.log(error);
        }
    }),
    getVisitsImportList: thunk(async (actions, payload) => {
        return fetchResponse<VisitImportRecordList>({
            actions,
            payload,
            request: getVisitsImportList
        });
    }),
    getImportResult: thunk(async (actions, payload) => {
        return fetchResponse<ImportResult>({
            actions,
            payload,
            request: getImportResult
        });
    }),
    deleteVisitsImport: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteVisitsImport,
            message: "Import record has been deleted",
        });
    }),
};

export default Visits;
