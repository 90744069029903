import ky from "../ky";
import { ThemeDefinitionType } from "./defThemes.model";

export type EditThemeDefType = {
  data: ThemeDefinitionType;
};

export type ThemeDefId = {
  id?: string | number;
};

export const getThemeDefList = () =>
  ky.get(`DefThemes`);

export const updateThemeDefinition = ({ data }: EditThemeDefType) =>
  ky.put(`DefThemes`, { json: data });

export const deleteThemeDefinition = ({ id }: ThemeDefId) =>
  ky.delete(`DefThemes?id=${id}`);

export const getOneThemeDefinition = ({ id }: ThemeDefId) =>
  ky.get(`DefThemes/${id}`);

export const addThemeDefinition = ({ data }: EditThemeDefType) =>
  ky.post(`DefThemes/add`, { json: data });

export const getBasicThemeDefinition = () => ky.get(`DefThemes/basic`);
