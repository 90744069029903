import ky from "../ky";


export type ReportsParams = {
    fromDate?: string | null | undefined,
    toDate?: string | null | undefined,
    subtrajectoryId: number
};

export type ReportsParamsIds = {
    fromDate?: string | null | undefined,
    toDate?: string | null | undefined,
    subtrajectoryIds: number[],
    typeNumber?: number | null
};

export type ReportsParamsIdsNps = {
    isNps: boolean,
    reportParameters: ReportsParamsIds
};

export type ReportsParamsNumber = {
    inputNumber: number
};

export type ReportListParams = {
    reasonId: number,
    currentPageNo: number,
    sortCol: string,
    isDescending: boolean
};

export type ReportCustomParams = {
    search: string,
    currentPageNo: number,
    sortCol: string,
    isDescending: boolean
};

export type IdReportType = {
    id?: string | number;
};

export type IdLangReportType = {
    id?: string | number;
    langCode: string;
};

export type IdsReportType = {
    ids: number[];
};

export type AddReportCustomChartType = {
    id: number;
    type: number;
};

export type AddReportCustomParams = {
    id: number;
    title: string;
    imageTag: string,
    description: string,
    categoryId: number;
    subcategoryId: number;
    evaluationMomentId: number;
    finished: number;
    type: number;
    questionsIds: number[];
    answersIds: number[];
    questionsType: AddReportCustomChartType[];
    grey: number;
};

export type AddReportQuestionParams = {
    id?: number;
    title: string;
    imageTag: string;
    description: string;
    evaluationIds: number[];
    questionIds: number[];
};

export type GetDistributionReportParams = {
    fromDate: string;
    toDate: string;
    subtrajectoryId: number;
    questionaireId: number;
    evaluatieIds: number[];
    languageCode: string;
    optionId: number;
};

export type GetReportDistributionDetailsParams = {
    currentPageNo: number,
    sortCol: string,
    isDescending: boolean,
    inParams: GetReportDistributionDetailsInParams
};

export type GetReportDistributionDetailsInParams = {
    fromDate: string,
    toDate: string,
    subtrajectoryId: number,
    questionaireId: number,
    evaluatieIds: number[],
    questionId: number,
    answerId: number,
    min: number,
    max: number,
    optionId: number
};

export type AddReportExcelParams = {
    langCode: string,
    dateFrom: string,
    dateTo: string,
    subtrajectoryIds: number[],
    settings: boolean[],
    title: string
};

export type GetReportQuestionWordParams = {
    reportId: number,
	fromDate: string,
	toDate: string,
	languageCode:string
};

export const getParticipationReport = ({ fromDate, toDate, subtrajectoryId }: ReportsParams) =>
    ky.get(`Reports/response?fromDate=${fromDate}&toDate=${toDate}&subtrajectoryId=${subtrajectoryId}`, { timeout: 30000 });

export const getComparisonReport = ({ fromDate, toDate, subtrajectoryIds }: ReportsParamsIds) =>
    ky.post(`Reports/comparison?fromDate=${fromDate}&toDate=${toDate}`, {
        json: {
            ids: subtrajectoryIds
        },
        timeout: 30000
    });

export const getNotificationsReport = ({ fromDate, toDate, subtrajectoryIds }: ReportsParamsIds) =>
    ky.post(`Reports/notificationsAndActions?fromDate=${fromDate}&toDate=${toDate}`, {
        json: {
            ids: subtrajectoryIds
        },
        timeout: 30000
    });

export const getMonthsReport = ({ isNps, reportParameters }: ReportsParamsIdsNps) =>
    ky.post(`Reports/comparison/month2?isNps=${isNps}`, {
        json: reportParameters, timeout: 50000
    });

export const getDeletedReport = () =>
    ky.get(`Reports/deleted`);

export const getDeletedReportName = ({ inputNumber }: ReportsParamsNumber) =>
    ky.get(`Reports/deleted/name?reasonId=${inputNumber}`, { timeout: 30000 });

export const getDeletedReportDetails = ({
    reasonId,
    currentPageNo,
    sortCol,
    isDescending
}: ReportListParams) =>
    ky.get(
        `Reports/deleted/details?reasonId=${reasonId}&PageNumber=${currentPageNo}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10`,
        { timeout: 30000 }
    );

export const getReportCustomList = ({
    search,
    currentPageNo,
    sortCol,
    isDescending,
}: ReportCustomParams) =>
    ky.get(
        `Reports/getReportCustom?PageNumber=${currentPageNo}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10&SearchWord=${search}`,
        { timeout: 30000 }
    );

export const deleteReportCustom = ({ id }: IdReportType) =>
    ky.delete(`Reports/deleteReportCustom?id=${id}`, { timeout: 30000 });

export const getQuestionsForReportCustom = ({ id, langCode }: IdLangReportType) =>
    ky.get(`Reports/getQuestionsForReportCustom?id=${id}&languageCode=${langCode}`, { timeout: 30000 });

export const addReportCustom = (params: AddReportCustomParams) =>
    ky.post(`Reports/addReportCustom`, {
        json: params, timeout: 30000
    });

export const getOneReportCustom = ({ id }: IdReportType) =>
    ky.get(`Reports/getOneReportCustom?id=${id}`, { timeout: 30000 });

export const editReportCustom = (params: AddReportCustomParams) =>
    ky.post(`Reports/editReportCustom`, {
        json: params,
        timeout: 30000
    });

export const getReportQuestionList = ({ currentPageNo, sortCol, isDescending, search }: ReportCustomParams) =>
    ky.get(`Reports/getReportQuestion?PageNumber=${currentPageNo}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10&SearchWord=${search}`,
        { timeout: 30000 }
    );

export const deleteReportQuestion = ({ id }: IdReportType) =>
    ky.delete(`Reports/deleteReportQuestion?id=${id}`, { timeout: 30000 });

export const getListOfTrajectsForQuestion = () =>
    ky.get(`Reports/getListOfTrajectsForQuestion`, { timeout: 30000 });

export const addReportQuestion = (params: AddReportQuestionParams) =>
    ky.post(`Reports/addReportQuestion`, {
        json: params,
        timeout: 30000
    });

export const getReportQuestionWord = (params: GetReportQuestionWordParams) =>
    ky.post(`Reports/questionWord`, {
        json: params,
        timeout: 600000
    });

export const getOneReportQuestion = ({ id }: IdReportType) =>
    ky.get(`Reports/getOneReportQuestion?id=${id}`, { timeout: 30000 });

export const editReportQuestion = (params: AddReportQuestionParams) =>
    ky.post(`Reports/editReportQuestion`, {
        json: params,
        timeout: 30000
    });

export const getQuestionaires = ({ id }: IdReportType) =>
    ky.get(`Reports/getQuestionaires?id=${id}`, { timeout: 30000 });

export const getDistributionReport = (params: GetDistributionReportParams) =>
    ky.post(`Reports/getDistributionReport`, {
        json: params,
        timeout: 30000
    });

export const getReportDistributionDetailsList = ({ currentPageNo, sortCol, isDescending, inParams }: GetReportDistributionDetailsParams) =>
    ky.post(`Reports/getReportDistributionDetailsList?PageNumber=${currentPageNo}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10`, {
        json: inParams,
        timeout: 30000
    });

export const getReportExcelList = ({ currentPageNo, sortCol, isDescending, }: ReportCustomParams) =>
    ky.get(
        `Reports/getReportExcelList?PageNumber=${currentPageNo}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10`,
        { timeout: 30000 }
    );

export const deleteReportExcel = ({ id }: IdReportType) =>
    ky.delete(`Reports/deleteReportExcel?id=${id}`, { timeout: 30000 });

export const getListOfTrajectsForExcel = () =>
    ky.get(`Reports/getListOfTrajectsForExcel`, { timeout: 30000 });

export const addReportExcel = (params: AddReportExcelParams) =>
    ky.post(`Reports/addReportExcel`, {
        json: params,
        timeout: 30000
    });
