import * as themes from '../theme/themes';
import tenantsConfigJson from '../_mocks/hospitals_meta.json';

export enum TenantsEnvironments {
  Alpha = 'alpha',
  Prod = 'prod',
}

export type Tenants = keyof typeof themes;

const tenantConfigs = tenantsConfigJson as TenantConfigs;

export interface TenantConfigs {
  [TenantsEnvironments.Alpha]: BaseTenantConfig[];
  [TenantsEnvironments.Prod]: BaseTenantConfig[];
}

interface BaseTenantConfig {
  tenant: string;
  title: string;
  domain: string;
  api?: string;
  env?: string;
  language?: string;
}

export const DEFAULT_TENANT: Tenants = 'h1';
const DEFAULT_ENV: TenantsEnvironments = TenantsEnvironments.Alpha;

export function getCurrentTenantConfig(): BaseTenantConfig {
  let tenantConfig: BaseTenantConfig | undefined;
  const flattenTenantConfig =[...Object.values(tenantConfigs)].flat() as BaseTenantConfig[];
  if (process.env.NODE_ENV === 'production') {
    if (window.location.hostname === 'localhost') {
      tenantConfig = flattenTenantConfig.find(
        it => it.tenant === DEFAULT_TENANT,
      )!;
    } else {
      const tenantDomain = window.location.hostname;
      tenantConfig = flattenTenantConfig.find(it => it.domain === tenantDomain);
      if (!tenantConfig) {
        throw new Error(
          `No tenantConfig found for domain:  ${tenantDomain}. Check hospitals_meta.json file`,
        );
      }
    }
  } else {
    const environment = (process.env.REACT_APP_ENV as TenantsEnvironments) || DEFAULT_ENV;
    const tenant = process.env.REACT_APP_TENANT || DEFAULT_TENANT;
    tenantConfig = tenantConfigs[environment].find(it => it.tenant === tenant);
    if (!tenantConfig) {
      throw new Error(
        `No tenantConfig found for tenant:  ${tenant}. Check hospitals_meta.json`,
      );
    }
  }
  return tenantConfig;
}
