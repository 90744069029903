import user, { UserModel } from "../services/user/user.model";
import translations, {
    TranslationsModel,
} from "../services/translations/translations.model";
import instanceList, {
    InstancesModel,
} from "../services/instances/instances.model";
import keywordsList, {
    KeywordsModel,
} from "../services/keywords/keywords.model";
import languages, {
    LanguagesModel,
} from "../services/languages/languages.model";
import userGroup, {
    UserGroupModel,
} from "../services/usergroups/usergroups.model";
import menu, { MenuModel } from "../services/menu/menu.model";
import users, { UsersModel } from "../services/users/users.model";
import doctors, { DoctorsModel } from "../services/doctors/doctors.model";
import actionStatus, {
    ActionStatusModel,
} from "../services/actionStatus/actionStatus.model";
import actionTypes, {
    ActionTypesModel,
} from "../services/actionType/actionType.model";
import actionPriority, {
    ActionPriorityModel,
} from "../services/actionPriority/actionPriority.model";
import loginLog, { LoginLogModel } from "../services/loginLog/loginLog.model";
import functions, {
    FunctionsModel,
} from "../services/functions/functions.model";
import content, { ContentModel } from "../services/content/content.model";
import ipBlock, { IpBlockModel } from "../services/ipBlock/ipBlock.model";
import labels, { LabelModel } from "../services/labels/labels.model";
import roles, { RoleModel } from "../services/roles/roles.model";
import images, { ImagesModel } from "../services/images/images.model";
import tags, { TagsModel } from "../services/tags/tags.model";
import properties, {
    PropertiesModel,
} from "../services/properties/properties.model";
import categories, {
    TrajectoriesModel,
} from "../services/categories/categories.model";
import questionnaires, {
    QuestionnairesModel,
} from "../services/questionnaires/questionnaires.model";
import qCategories, {
    QCategoriesModel,
} from "../services/qCategories/qCategories.model";
import query, { QueryPropertiesModel } from "../services/query/query.model";
import questions, {
    QuestionModel,
} from "../services/questions/questions.model";
import defQuestions, {
    DefQuestionModel,
} from "../services/defQuestions/defQuestions.model";
import defSubquestions, {
    DefSubquestionModel,
} from "../services/defSubquestions/defSubquestions.model";
import defThemes, {
    DefThemeModel,
} from "../services/defThemes/defThemes.model";
import defAnswers, {
    DefAnswerModel,
} from "../services/defAnswers/defAnswers.model";
import answers, { AnswerTypesModel } from "../services/answers/answers.model";
import emails, { EmailDefinitionModel } from "../services/emails/emails.model";
import subcategories, {
    SubcategoriesModel,
} from "../services/subcategories/subcategories.model";
import deleteReasons, {
    DeleteReasonsModel
} from "../services/deleteReasons/deleteReasons.model";
import evaluation, {
    EvaluationModel,
} from "../services/evaluation/evaluation.model";
import communication, {
    CommunicationModel,
} from "../services/communication/communication.model";
import responsibilities, {
    ResponsibilitiesModel,
} from "../services/responsibilities/responsibilities.model";
import visits, { VisitModel } from "../services/visits/visits.model";
import patientFields, {
    PatientFieldModel,
} from "../services/patientFields/patientField.model";
import notifications, {
    NotificationsModel
} from "../services/notifications/notifications.model";
import actions, {
    ActionsModel
} from "../services/actions/actions.model";
import dashboard, {
    DashboardModel
} from "../services/dashboard/dashboard.model";
import reports, {
    ReportsModel
} from "../services/reports/reports.model";
import filters, {
    FiltersModel
} from "../services/filters/filters.model";
import popups, {
    PopupModel
} from "../services/defPopups/defPopups.model";
import userPopups, { UserPopupModel } from "../services/popups/popups.model";
import aiLog, { AiLogModel } from "../services/aiLog/aiLog.model";

export type StoreModel = {
    user: UserModel;
    translations: TranslationsModel;
    instanceList: InstancesModel;
    keywordsList: KeywordsModel;
    languages: LanguagesModel;
    userGroup: UserGroupModel;
    menu: MenuModel;
    users: UsersModel;
    doctors: DoctorsModel;
    actionStatus: ActionStatusModel;
    actionTypes: ActionTypesModel;
    actionPriority: ActionPriorityModel;
    loginLog: LoginLogModel;
    functions: FunctionsModel;
    content: ContentModel;
    ipBlock: IpBlockModel;
    labels: LabelModel;
    roles: RoleModel;
    images: ImagesModel;
    tags: TagsModel;
    properties: PropertiesModel;
    categories: TrajectoriesModel;
    questionnaires: QuestionnairesModel;
    qCategories: QCategoriesModel;
    query: QueryPropertiesModel;
    questions: QuestionModel;
    defQuestions: DefQuestionModel;
    defSubquestions: DefSubquestionModel;
    defThemes: DefThemeModel;
    defAnswers: DefAnswerModel;
    answers: AnswerTypesModel;
    emails: EmailDefinitionModel;
    subcategories: SubcategoriesModel;
    deleteReasons: DeleteReasonsModel;
    evaluation: EvaluationModel;
    communication: CommunicationModel;
    responsibilities: ResponsibilitiesModel;
    visits: VisitModel;
    patientFields: PatientFieldModel;
    notifications: NotificationsModel;
    actions: ActionsModel;
    dashboard: DashboardModel;
    reports: ReportsModel;
    filters: FiltersModel;
    popups: PopupModel;
    userPopups: UserPopupModel;
    aiLog: AiLogModel;
};

const store: StoreModel = {
    user,
    translations,
    instanceList,
    keywordsList,
    languages,
    userGroup,
    menu,
    users,
    doctors,
    actionStatus,
    actionTypes,
    actionPriority,
    loginLog,
    functions,
    content,
    ipBlock,
    labels,
    roles,
    images,
    tags,
    properties,
    categories,
    questionnaires,
    qCategories,
    query,
    questions,
    defQuestions,
    defSubquestions,
    defThemes,
    defAnswers,
    answers,
    emails,
    subcategories,
    deleteReasons,
    evaluation,
    communication,
    responsibilities,
    visits,
    patientFields,
    notifications,
    actions,
    dashboard,
    reports,
    filters,
    popups,
    userPopups,
    aiLog
};

export default store;
