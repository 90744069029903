export const DARK_GREEN = "#62672e";

export const BG_LIGHT_GREEN = "#E7EDC0";

export const BUTTON_GREEN = "#779933";

export const BUTTON_HOVER_GREEN = "#5C7829";

export const BUTTON_RED = "#DE8888";

export const LIGHT_RED = "#CC3333";

export const BUTTON_HOVER_RED = "#B22222";

export const NAV_BGD = "#606060";

export const NAV_HOVER = "#545454";

export const NAV_BORDER = "#ADACAC";

export const LIGHT_TEXT = "white";

export const TBL_ROW = "#f2f2f2";

export const TBL_HOVER = "#DDD";

export const PINK_HOVER = "rgba(222, 136, 136, 0.5)";

export const COLOR_WHITE = "#ffffff";

export const SHADOW_BLACK = "rgba(0, 0, 0, 0.7)";

export const NPS_GREEN = "#96D200";
export const NPS_ORANGE = "#F08700";
export const NPS_RED = "#E00006";
export const NPS_GRAY = "#CCC";

export const LINK_GRAY = "#CCC";
export const LINK_BLUE = "#4288d2";


export const NEW_DARK_GRAY = "#181818";
export const NEW_DARK_BLUE = "#253542";
export const NEW_BLUE = "#3B5972";
export const NEW_LIGHT_BLUE = "#3B7E81";
export const NEW_ORANGE = "#E04606";
export const NEW_GREEN = "#176510";
export const NEW_RED = "#C81D0E";
export const NEW_LIGHT = "#F9F9F9";
export const NEW_LIGHT_LIGHT = "#FBFBFC";
export const NEW_LIGHT_LIGHT_DARKER = "#E1E1E2";
export const NEW_LIGHT_GREY = "#EBECF0";

export const media = {
    xsmall: `(max-width: 799px)`,
    small: `(min-width: 800px) and (max-width: 989px)`,
    middle: `(min-width: 990px) and (max-width: 1384px)`,
    big: `(min-width: 1385px)`,
    num0: 800,
    num1: 990,
    num2: 1385
};

export const FONT_8 = "0.5rem";
export const FONT_9 = "0.5625rem";
export const FONT_10 = "0.625rem";
export const FONT_11 = "0.6875rem";
export const FONT_12 = "0.75rem";
export const FONT_13 = "0.8125rem";
export const FONT_14 = "0.875rem";
export const FONT_15 = "0.9375rem";
export const FONT_16 = "1rem";
export const FONT_17 = "6875rem";
export const FONT_18 = "1.0625rem";
export const FONT_19 = "1.1875rem";
export const FONT_20 = "1.25rem";
export const FONT_21 = "1.3125rem";
export const FONT_22 = "1.375rem";
export const FONT_23 = "1.4375rem";
export const FONT_24 = "1.5rem";
export const FONT_32 = "2rem";
export const FONT_36 = "2.25rem";
export const FONT_38 = "2.375rem";