import ky from "../ky";

export type AiLogListType = {
    instances: string;
    isPositive: number;
    isLabelled: number;
    aiCallPlace: string;
    dateFrom: string;
    dateTo: string;
    currentPageNo: number;
    query: string;
    sortCol: string;
    isDescending: boolean;
}

export const getAiLogs = ({
    instances,
    isPositive,
    isLabelled,
    aiCallPlace,
    dateFrom,
    dateTo,
    currentPageNo,
    sortCol,
    isDescending,
}: AiLogListType) =>
    ky.get(
        `AiLog?Instances=${instances}&IsPositive=${isPositive}&IsLabelled=${isLabelled}&AiCallPlace=${aiCallPlace}&PageNumber=${currentPageNo}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10&dateFrom=${dateFrom}&dateTo=${dateTo}`, { timeout: 60000 }
    );

export type GetAiLogDetails = {
    id?: string;
}

export const getAiLogDetails = ({ id }: GetAiLogDetails) => ky.get(`AiLog/${id}`);

export const getAiLogsExport = async ({
    instances,
    isPositive,
    isLabelled,
    aiCallPlace,
    dateFrom,
    dateTo,
    sortCol,
    isDescending,
}: AiLogListType) => {
    const resp = await ky.get(`AiLog/export?Instances=${instances}&IsPositive=${isPositive}&IsLabelled=${isLabelled}&AiCallPlace=${aiCallPlace}&OrderBy=${sortCol}&IsDescending=${isDescending}&dateFrom=${dateFrom}&dateTo=${dateTo}&languageCode=NL&isExcel=false`, { timeout: 600000 });
    const blob = resp.blob();
    return blob;
};