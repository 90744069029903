import ky from "../ky";

export type FunctionsListType = {
  currentPageNo: number;
  query: string;
  sortCol: string;
  isDescending: boolean;
};

export const getFunctionsList = ({
  currentPageNo,
  query,
  sortCol,
  isDescending,
}: FunctionsListType) =>
  ky.get(
    `Functions?PageNumber=${currentPageNo}&SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10`
  );

export const getFunctionsL = () => ky.get(`Functions`);

export type IdFunctionsType = {
  id?: string | number;
};

export const deleteFunctions = ({ id }: IdFunctionsType) =>
  ky.delete(`Functions?id=${id}`);

export type FunctionsType = {
  id?: number;
  name: string;
};

export const addFunctions = ({ name }: FunctionsType) =>
  ky.post(`Functions/add`, { json: { name } });

export const updateFunctions = ({ name, id }: FunctionsType) =>
  ky.put(`Functions`, { json: { name, id } });

export const getOneFunctions = ({ id }: IdFunctionsType) =>
  ky.get(`Functions/${id}`);

export const getFunctionsToShow = () => ky.get(`Functions/show`);
