import { ThemeConfig } from './types';

export const h14: ThemeConfig = {
    tenant: 'h14',
    baseColor: '#3A5972',
    navColor: '#ffffff',
    textColor: '#444',
    hoverColor: '#ED4A06',
    disabled: '#999999',
    inputBgColor: "#3A5972",
    matrixHoverColor: '#ED4A06',
    matrixHoverTextColor: '#444',
    matrixOddBgColor: '#c3cdd4',
    matrixOddTextColor: '#ffffff'
};

export default h14;

/*
blue - #3A5972
orange - #ED4A06
*/