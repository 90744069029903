import { ThemeConfig } from './types';

export const h2: ThemeConfig = {
    tenant: 'h2',
    baseColor: '#840054',
    navColor: '#ffffff',
    textColor: '#444',
    hoverColor: '#F26522',
    disabled: '#999999',
    inputBgColor: "#FBFBFC",
    matrixHoverColor: '#F26522',
    matrixHoverTextColor: '#444',
    matrixOddBgColor: '#FBFBFC',
    matrixOddTextColor: '#444'
};

export default h2;