import { thunk, Thunk, Action, action } from "easy-peasy";

import {
    getAiLogs,
    AiLogListType,
    getAiLogDetails,
    GetAiLogDetails,
    getAiLogsExport
} from "./aiLog.service";
import { ErrorEntry } from "../usergroups/usergroups.model";
import { fetchResponse } from "../../helpers/fetching";

export interface AiLogList {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    count: number;
    items: AiLogItem[];
    hasPrevious: boolean;
    hasNext: boolean;
}

export type AiLogItem = {
    id: number;
    instanceId: number;
    instanceName: string;
    date: string;
    testingId: number | null;
    operationId: number | null;
    answerId: number | null;
    answerText: string;
    labelsList: string[];
    aiAnswer: string;
    aiPosLabels: string[];
    aiNegLabels: string[];
    setPosLabels: string[];
    setNegLabels: string[];
    isLabeled: boolean;
    errorText: string;
    aiCallPlace: string;
    isPositive: boolean | null;
    aiCall: string;
    userId: number;
};

export type AiLogModel = {
    getAiLogs: Thunk<AiLogModel, AiLogListType>;
    getAiLogDetails: Thunk<AiLogModel, GetAiLogDetails>
    error: ErrorEntry;
    setError: Action<AiLogModel, ErrorEntry>;
    delError: Action<AiLogModel>;
    getAiLogsExport: Thunk<AiLogModel, AiLogListType>;
};

const aiLog: AiLogModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getAiLogs: thunk(async (actions, payload) => {
        return fetchResponse<AiLogList>({
            actions,
            payload,
            request: getAiLogs,
        });
    }),
    getAiLogDetails: thunk(async (actions, payload) => {
        return fetchResponse<AiLogItem>({
            actions,
            payload,
            request: getAiLogDetails,
        });
    }),
    getAiLogsExport: thunk(async (actions, payload) => {
        try {
            const response = await getAiLogsExport(payload);
            return response;
        } catch (error) {
            console.log(error);
        }
    }),
};

export default aiLog;
