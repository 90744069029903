import ky from "../ky";

import { CommunicationListItem } from "./communication.model";

export type SubCommId = {
  subtrajectoryId: number;
  languageCode: string;
};

export const getSubCommList = ({ subtrajectoryId, languageCode }: SubCommId) =>
  ky.get(
    `SubtrajectoryCommunication?subtrajectoryId=${subtrajectoryId}&languageCode=${languageCode}`
  );

export type SubCommType = {
  languageCode?: string;
  data: CommunicationListItem;
};

export const updateSubComm = ({ languageCode, data }: SubCommType) =>
  ky.put(`SubtrajectoryCommunication?languageCode=${languageCode}`, {
    json: data,
  });

export type CommId = {
  id: number;
  languageCode?: string;
};

export const deleteSubComm = ({ id }: CommId) =>
  ky.delete(`SubtrajectoryCommunication?id=${id}`);

export const getOneComm = ({ id, languageCode }: CommId) =>
  ky.get(`SubtrajectoryCommunication/${id}?languageCode=${languageCode}`);

export const addSubComm = ({ languageCode, data }: SubCommType) =>
  ky.post(`SubtrajectoryCommunication/add?languageCode=${languageCode}`, {
    json: data,
  });

export type SubtrajctoryId = {
  subtrajectoryId: number;
};

export const getBasicComm = ({ subtrajectoryId }: SubtrajctoryId) =>
  ky.get(`SubtrajectoryCommunication/basic?subtrajectoryId=${subtrajectoryId}`);

export const sendTestComm = ({ data }: SubCommType) =>
  ky.post(`SubtrajectoryCommunication/sendTestEmail`, { json: data });
