import ky from "../ky";
import {
    testingsQuestionnaireResponseType,
    InvitationData
} from "./questionnaires.model";

export type QuestionnairesListType = {
    currentPageNo: number;
    query: string;
    sortCol: string;
    isDescending: boolean;
    active: boolean | "";
};

export const getQuestionnaires = ({
    currentPageNo,
    query,
    sortCol,
    isDescending,
    active
}: QuestionnairesListType) =>
    ky.get(
        `Questionaires?PageNumber=${currentPageNo}&SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10${active === "" ? "" : `&active=${active}`}`
    );

export const getAllQuestionnaries = () => ky.get(`Questionaires/show`);

export type QuestionnaireIdType = {
    id?: number | string;
    languageCode?: string;
};

export const deleteQuestionnaires = ({ id }: QuestionnaireIdType) =>
    ky.delete(`Questionaires?id=${id}`);

export const logTesting = ({ code }: TestingsType) =>
    ky.post(`Testings/logTesting?testingCode=${code}`);

export const copyQuestionnaires = ({ id }: QuestionnaireIdType) =>
    ky.post(`Questionaires/copy/${id}`);

export const getOneQuestionnaire = ({
    id,
    languageCode,
}: QuestionnaireIdType) =>
    ky.get(`Questionaires/${id}?languageCode=${languageCode}`);

export type ToggleType = {
    id?: string;
    lock: boolean;
};

export const toggleLockQuestionnaire = ({ id, lock }: ToggleType) =>
    ky.put(`Questionaires/toggleLock?id=${id}&ifLock=${lock}`);

export type BasicQuestionnaireModel = {
    languageCode?: string;
    id?: number;
    title: string;
    spsslabel: string;
    introOnStart: boolean;
    locked: boolean;
    routing: boolean;
    questionaireTextDto: QuestionnaireTextType;
    lastUpdate?: Date;
    active: boolean;
};

type QuestionnaireTextType = {
    title: string;
    intro: string;
};

export const addQuestionnaires = ({
    languageCode,
    title,
    spsslabel,
    introOnStart,
    locked,
    routing,
    questionaireTextDto,
    active
}: BasicQuestionnaireModel) =>
    ky.post(`Questionaires/add?languageCode=${languageCode}`, {
        json: {
            title,
            spsslabel,
            introOnStart,
            locked,
            routing,
            questionaireTextDto,
            active
        },
    });

export const updateQuestionaire = ({
    languageCode,
    id,
    title,
    spsslabel,
    introOnStart,
    locked,
    routing,
    questionaireTextDto,
    active
}: BasicQuestionnaireModel) =>
    ky.put(`Questionaires/?languageCode=${languageCode}`, {
        json: {
            id,
            title,
            spsslabel,
            introOnStart,
            locked,
            routing,
            questionaireTextDto,
            active
        },
    });

export const getQuestionnaireName = ({ id }: QuestionnaireIdType) =>
    ky.get(`Questionaires/name/${id}`);

export type PreviewQuestionnaireType = {
    questionaireId: number;
    languageCode: string;
};

export const previewQuestionnaire = ({
    questionaireId,
    languageCode,
}: PreviewQuestionnaireType) =>
    ky.get(
        `Questionaires/toView?questionaireId=${questionaireId}&languageCode=${languageCode}`
    );

export type EvaluationMomentId = {
    evaluationMomentId: number;
};

export const getQuestionnairesList = ({
    evaluationMomentId,
}: EvaluationMomentId) =>
    ky.get(
        `Questionaires/showForEvaluationMoment?evaluationMomentId=${evaluationMomentId}`
    );

export type PreviewQuestionnairesType = EvaluationMomentId & {
    languageCode: string;
};

export const previewQuestionnaires = ({
    evaluationMomentId,
    languageCode,
}: PreviewQuestionnairesType) =>
    ky.get(
        `Questionaires/toViewForEvaluationMoment?evaluationMomentId=${evaluationMomentId}&languageCode=${languageCode}`
    );

export type TestingsType = {
    code: string | number;
};

export type ResendEmailType = TestingsType & {
    email: string;
};

export const getTestingsQuestionnaireStatus = ({ code }: TestingsType) =>
    ky.get(`Testings/status?testingCode=${code}`);

export const resendEmailWithCode = ({ code, email }: ResendEmailType) =>
    ky.post(`Testings/interruptedTesting?testingCode=${code}&email=${email}`);

export const getTestingsQuestionnaire = ({ code }: TestingsType) => 
    ky.get(`Testings/?testingCode=${code}`, { timeout: 45000 });

export type TestingsAnswersModel = TestingsType &
    testingsQuestionnaireResponseType & { submit: boolean };

export const saveTestingsAnswers = ({
    code,
    testingId,
    questionaireSchemas,
    submit,
}: TestingsAnswersModel) =>
    ky.post(`Testings/answers/?testingCode=${code}&submit=${submit}`, {
        json: {
            testingId,
            questionaireSchemas,
        },
    });

export const getPatientStatus = () => ky.get(`Testings/patientStatus`);

export const getTestingsAnswers = ({ code }: TestingsType) =>
    ky.get(`Testings/filledQuestionaire?testingCode=${code}`, { timeout: 30000 });

export const getTestingsInfo = ({ code }: TestingsType) =>
    ky.get(`Testings/filledQuestionaireInfo?testingCode=${code}`);

export type TestingIdType = {
    testingId: number
};

export type TestingsViewProps = TestingIdType & {
    operationId: number
};

export const getTestingsView = ({ testingId, operationId }: TestingsViewProps) =>
    ky.get(`Testings/visit?testingId=${testingId}&operationId=${operationId}`);

export const resetTesting = ({ code }: TestingsType) =>
    ky.put(`Testings/reset?testingCode=${code}`);

export type ActivateProps = {
    evaluationId: number,
    operationId: number
};

export const activateTestings = ({ evaluationId, operationId }: ActivateProps) =>
    ky.post(`Testings/activate?evaluationMomentId=${evaluationId}&operationId=${operationId}`);

export const getInvitationData = ({ testingId }: TestingIdType) =>
    ky.get(`Testings/invitationEmail?testingId=${testingId}`);

export const sendInvitationAuto = ({ testingId }: TestingIdType) =>
    ky.post(`Testings/automaticInvitation?testingId=${testingId}`);

export type InvitationManualProps = TestingIdType & InvitationData;

export const sendInvitationManual = ({
    testingId,
    ...data
}: InvitationManualProps) =>
    ky.post(`Testings/manualInvitation?testingId=${testingId}`, { json: data });

export const setAsSent = ({
    testingId
}: TestingIdType) =>
    ky.put(`Testings/setAsSent?testingId=${testingId}`);

export const getEvaluatieAnswers = ({ id, languageCode }: QuestionnaireIdType) =>
    ky.get(`Testings/getEvaluatieAnswers?evaluatieId=${id}&languageCode=${languageCode}`, { timeout: 30000 });