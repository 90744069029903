import styled from "styled-components";

import { Link } from "react-router-dom";
import * as color from '../../../components/portal/ColorVariables';

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-beetwen;
  padding: 15px;
  @media ${color.media.xsmall} {
        margin: 0 auto;
    }
`;

export const NavLink = styled(Link)`
  color: ${(props) => props.theme.navColor};
  text-decoration: none;
  padding: 0px 15px 0px 15px;
  font-size: 1.1rem;

  :hover {
    text-decoration: underline;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10%;
  margin: 0 auto;
  color: ${(props) => props.theme.navColor};
  background-color: ${(props) => props.theme.baseColor};
  flex-wrap: wrap;
`;
