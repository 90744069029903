import { thunk, Thunk, Action, action } from "easy-peasy";
import {
    getSubDefList,
    LanguageCodeType,
    updateSubDefinition,
    EditSubDefType,
    EditSubDefTypeDef,
    deleteSubDefinition,
    SubDefId,
    getOneSubDefinition,
    addSubDefinition,
    addSubDefinitionDef,
    getBasicSubDefinition,
    ImportSubquestionType,
    importSubquestions,
    ImportSubquestionFinalType,
    importSubquestionsFinal
} from "./defSubquestions.service";
import { ErrorEntry } from "../users/users.model";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";

export type SubDefinitionType = {
    id: number;
    instanceId: number;
    theme: string;
    themeCode: number;
    spsslabel: string;
    locked: boolean;
    defSubquestionTextDto: SubDefTextType;
    active: boolean;
    questionaires: string[];
};

export type SubDefTextType = {
    defSubquestionId: number;
    languageId: number;
    question: string;
};

export type DefSubquestionModel = {
    getSubDefList: Thunk<DefSubquestionModel, LanguageCodeType>;
    updateSubDefinition: Thunk<DefSubquestionModel, EditSubDefType>;
    addSubDefinition: Thunk<DefSubquestionModel, EditSubDefType>;
    addSubDefinitionDef: Thunk<DefSubquestionModel, EditSubDefTypeDef>;
    getBasicSubDefinition: Thunk<DefSubquestionModel>;
    deleteSubDefinition: Thunk<DefSubquestionModel, SubDefId>;
    getOneSubDefinition: Thunk<DefSubquestionModel, SubDefId>;
    importSubquestions: Thunk<DefSubquestionModel, ImportSubquestionType>;
    importSubquestionsFinal: Thunk<DefSubquestionModel, ImportSubquestionFinalType>;
    error: ErrorEntry;
    setError: Action<DefSubquestionModel, ErrorEntry>;
    delError: Action<DefSubquestionModel>;
};

export type ColumnName = {
    key: string,
    val: string
};

export type ImportData = {
    columnNames: ColumnName[],
    fileData: string[][],
    languageCode: string
};

export type ImportResult = {
    totalRowsCount: number,
    processedRowsCount: number,
    ignoredRowsCount: number,
    successedRowsCount: number,
    wrongRowsCount: number,
    addedRowsCount: number,
    updatedRowsCount: number,
    errors: ColumnName[]
};

const defSubquestions: DefSubquestionModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getSubDefList: thunk(async (actions, payload) => {
        return fetchResponse<SubDefinitionType[]>({
            actions,
            payload,
            request: getSubDefList,
        });
    }),
    updateSubDefinition: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateSubDefinition,
            message: "Definition has been edited",
        });
    }),
    deleteSubDefinition: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteSubDefinition,
            message: "Definition has been deleted",
        });
    }),
    getOneSubDefinition: thunk(async (actions, payload) => {
        return fetchResponse<SubDefinitionType>({
            actions,
            payload,
            request: getOneSubDefinition,
        });
    }),
    addSubDefinition: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addSubDefinition,
            message: "Definition has been added",
        });
    }),
    addSubDefinitionDef: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addSubDefinitionDef,
            message: "Definition has been added",
        });
    }),
    getBasicSubDefinition: thunk(async (actions) => {
        return fetchResponse<SubDefinitionType>({
            actions,
            request: getBasicSubDefinition,
        });
    }),
    importSubquestions: thunk(async (actions, payload) => {
        return fetchResponse<ImportData>({
            actions,
            payload,
            request: importSubquestions
        });
    }),
    importSubquestionsFinal: thunk(async (actions, payload) => {
        return fetchResponse<ImportResult>({
            actions,
            payload,
            request: importSubquestionsFinal
        });
    }),
};

export default defSubquestions;
