import ky from "../ky";

export type SubCategoryListType = {
    id?: string;
    currentPageNo: number;
    query: string;
    active: boolean | string;
    sortCol: string;
    isDescending: boolean;
};

export type UpdateSubcatModel = {
    languageCode: string;
    data: SubcategoryModel;
};

export type CodeParam = {
    code: string;
};

export type IdParam = {
    id: number;
};

export type SubcategoryModel = {
    id?: number;
    title: string;
    spsslabel: string;
    link: string;
    active: boolean;
    trajectoryId: number;
    evaluationMomentId: number;
    trajectoryTitle: string;
    onDashboard: boolean;
    position: number;
    conservative: boolean;
    internal: boolean;
    reminder: boolean;
    onPatientportal: boolean;
    isProms: boolean;
    exportType: number;
    autoEmail: string;
    clearData: boolean;
    subtrajectoryDoctorsDto: SubcategoryDoctors[];
    subtrajectoryOptionsDto: SubcategoryOptions[];
};

export type SubcategoryDoctors = {
    doctorId: number;
    doctorName: string;
    active: boolean;
};

export type SubcategoryOptions = {
    id: number;
    subtrajectoryOptionTextDto: {
        value: string;
    };
};

export const getSubCategories = ({
    id,
    currentPageNo,
    query,
    active,
    sortCol,
    isDescending,
}: SubCategoryListType) =>
    ky.get(
        `Subtrajectories?paged?questionaireId=${id}&PageNumber=${currentPageNo}&SearchWord=${query}&Active=${active}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10`
    );

export const updateSubcategory = ({ languageCode, data }: UpdateSubcatModel) =>
    ky.put(`Subtrajectories?languageCode=${languageCode}`, { json: data });

export type SubcategoryId = {
    id?: number | string;
    languageCode?: string;
};

export const deleteSubcategory = ({ id }: SubcategoryId) =>
    ky.delete(`Subtrajectories?id=${id}`);

export const subcetegoriesToShow = () => ky.get(`Subtrajectories/show`);

export const getOneSubcategory = ({ id, languageCode }: SubcategoryId) =>
    ky.get(`Subtrajectories/${id}?languageCode=${languageCode}`);

export const addSubcategory = ({ languageCode, data }: UpdateSubcatModel) =>
    ky.post(`Subtrajectories/add?languageCode=${languageCode}`, {
        json: data,
    });

export type TrajectoryId = {
    trajectoryId: string | number;
};

export const getBasicSubcategory = ({ trajectoryId }: TrajectoryId) =>
    ky.get(`Subtrajectories/getBasic?trajectoryId=${trajectoryId}`);

export const listForTrajectory = ({ trajectoryId }: TrajectoryId) =>
    ky.get(`Subtrajectories/showForTrajectory?trajectoryId=${trajectoryId}`);

export const listForTrajectoryWithNotActive = ({ trajectoryId }: TrajectoryId) =>
    ky.get(`Subtrajectories/showForTrajectoryWithNotActive?trajectoryId=${trajectoryId}`);

export const listForTrajectoryOnStart = ({ trajectoryId }: TrajectoryId) =>
    ky.get(`Subtrajectories/showForTrajectoryOnStart?trajectoryId=${trajectoryId}`);

export const showOptions = ({ id, languageCode }: SubcategoryId) =>
    ky.get(
        `Subtrajectories/showOptions?subtrajectoryId=${id}&languageCode=${languageCode}`
    );

export const showDoctors = ({ id }: SubcategoryId) =>
    ky.get(`Subtrajectories/showDoctors?subtrajectoryId=${id}`);

export const showForUser = () => ky.get(`Subtrajectories/showForUser`);

export const getIdByLink = ({ code }: CodeParam) =>
    ky.get(`Subtrajectories/getIdByLink?subCode=${code}`);

export const createNewOperation = ({ id }: IdParam) =>
    ky.get(`Subtrajectories/createNewOperation?subId=${id}`);
