import { thunk, Thunk, Action, action } from "easy-peasy";

import {
  getQuery,
  delQuery,
  addQuery,
  editQuery,
  EditQueryType,
  updateQuery,
  getQueryTypes,
  QueryType,
  propertiesToShow,
  getPropertyList,
} from "./properties.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

export type QueryTypesType = {
  id: number;
  name: string;
};

export type QueryProperty = {
  id: number;
  title: string;
};

export type PropertiesModel = {
  getQuery: Thunk<PropertiesModel>;
  delQuery: Thunk<PropertiesModel, EditQueryType>;
  addQuery: Thunk<PropertiesModel, QueryType>;
  editQuery: Thunk<PropertiesModel, EditQueryType>;
  updateQuery: Thunk<PropertiesModel, QueryType>;
  getQueryTypes: Thunk<PropertiesModel>;
  propertiesToShow: Thunk<PropertiesModel>;
  error: ErrorEntry;
  setError: Action<PropertiesModel, ErrorEntry>;
  delError: Action<PropertiesModel>;
  getPropertyList: Thunk<QueryProperty>;
};

const properties: PropertiesModel = {
  error: { code: 0 },
  setError: action((state, payload) => {
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),
  getQuery: thunk(async (actions, payload) => {
    return fetchResponse<QueryType[]>({
      actions,
      request: getQuery,
    });
  }),
  delQuery: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: delQuery,
      message: "Property has been deleted",
    });
  }),
  addQuery: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: addQuery,
      message: "Property has been added",
    });
  }),
  editQuery: thunk(async (actions, payload) => {
    return fetchResponse<QueryType>({
      actions,
      payload,
      request: editQuery,
    });
  }),
  updateQuery: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: updateQuery,
      message: "Property has been edited",
    });
  }),
  getQueryTypes: thunk(async (actions, payload) => {
    return fetchResponse<QueryTypesType[]>({
      actions,
      request: getQueryTypes,
    });
  }),
  propertiesToShow: thunk(async (actions, payload) => {
    return fetchResponse<QueryProperty[]>({
      actions,
      request: propertiesToShow,
    });
  }),
  getPropertyList: thunk(async (actions, payload) => {
    return fetchResponse<QueryProperty[]>({
      actions,
      request: getPropertyList,
    });
  }),
};

export default properties;
