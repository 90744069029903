import { thunk, Thunk, Action, action } from "easy-peasy";
import {
    getPopupsList,
    updatePopup,
    addPopup,
    deletePopup,
    getBasicPopup,
    getOnePopup,
    getInstances,
    getInstances2,
    PopupsListType,
    DeletePopupType,
    BasicDefPopupModel,
    PopupId,
    StoriesPropsInternal,
    DefPopupTextType,
    getGroupPositions
} from "./defPopups.service";
import { ErrorEntry } from "../users/users.model";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";

export interface Instances{
    label: string,
    value: string,
    instanceIds: number[]
}

export type DefInstance = {
    label: string,
    value: string
    instanceIds: number[]
}

export type GroupPosition = {
    label: string,
    value: string
    groupPositions: number[]
}

export interface PopupList {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    count: number;
    items: BasicDefPopupModel[];
    hasPrevious: boolean;
    hasNext: boolean;
}

export type PopupItem = {
    id: number;
    title: string;
    text: string;
    date: string;
};

export interface DefPopupItem {
    title: string;
    deleted: boolean;
    createDate: number;
    startDate: number;
    endDate: number;
}

export interface PopupType {
    defPopupDto: BasicDefPopupModel;
    defPopupTextDto: DefPopupTextType;
}

export type FilterType = {
    id: number;
    name: string;
};

export type NavigationLogItem = {
    id: number,
    time: string,
    objectClass: string,
    objectId: number,
    objectName: string,
    actionName: string,
    actorName: string,
    isUserName: boolean,
    extraInfo: string
};

export type DefPopups = {
    currentPage: number,
    totalPages: number,
    pageSize: number,
    totalCount: number,
    count: number,
    items: PopupItem[],
    hasPrevious: boolean,
    hasNext: boolean
};

export type PopupModel = {
    error: ErrorEntry;

    setError: Action<PopupModel, ErrorEntry>;
    delError: Action<PopupModel>;

    getPopupsList: Thunk<PopupModel, PopupsListType>;
    updatePopup: Thunk<PopupModel, BasicDefPopupModel>;
    addPopup: Thunk<PopupModel, BasicDefPopupModel>;
    deletePopup: Thunk<PopupModel, DeletePopupType>;
    getBasicPopup: Thunk<PopupModel>;
    getOnePopup: Thunk<PopupModel, PopupId>;
    getInstances: Thunk<PopupModel>;
    getInstances2: Thunk<PopupModel, StoriesPropsInternal>;
    getGroupPositions: Thunk<PopupModel>;
};

const Popups: PopupModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getPopupsList: thunk(async (actions, payload) => {
        return fetchResponse<DefPopups>({
            actions,
            payload,
            request: getPopupsList,
        });
    }),
    updatePopup: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updatePopup,
            message: "Popup has been edited",
        });
    }),
    addPopup: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addPopup,
            message: "Popup has been added",
        });
    }),
    deletePopup: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deletePopup,
            message: "Popup has been deleted",
        });
    }),
    getBasicPopup: thunk(async (actions, payload) => {
        return fetchResponse<BasicDefPopupModel>({
            actions,
            request: getBasicPopup,
        });
    }),
    getOnePopup: thunk(async (actions, payload) => {
        return fetchResponse<BasicDefPopupModel>({
            actions,
            payload,
            request: getOnePopup,
        });
    }),
    getInstances: thunk(async (actions) => {
        return fetchResponse<DefInstance[]>({
            actions,
            request: getInstances,
        });
    }),
    getInstances2: thunk(async (actions, payload) => {
        return fetchResponse<Instances[]>({
            actions,
            payload,
            request: getInstances2,
        });
    }),
    getGroupPositions: thunk(async (actions) => {
        return fetchResponse<GroupPosition[]>({
            actions,
            request: getGroupPositions,
        });
    }),
};

export default Popups;
