import { thunk, Thunk, Action, action } from "easy-peasy";

import {
    getUsers,
    deleteUser,
    DeleteUserType,
    UsersListType,
    getBasicUser,
    addUser,
    getUserFunctions,
    getOneUser,
    GetUserType,
    editUser,
    getUsersList,
    getUserIp,
    getProfile,
    ProfileModel,
    updateProfile,
    SubtrajectoryIdType,
    getForSubtrajectory,
    getForActions,
    getForEditActions,
    getUserSignalsList,
    getUserActionsList,
    RevertUserType,
    revertUser,
} from "./users.services";
import { TestingsType } from "../questionnaires/questionnaires.service";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";

export interface UsersList {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    count: number;
    items: UsersItem[];
    hasPrevious: boolean;
    hasNext: boolean;
}

export type UsersItem = {
    id: string;
    idInstance: number;
    email: string;
    name: string;
    idUsergroup: number;
    active: boolean;
    inOverview: boolean;
    canLogin: boolean;
    usergroupName: string;
    subtrajectories: string;
    notificationsAndActions: number;
    position: number;
};

export interface BasicUserModel {
    id: number;
    idToken?: string;
    idInstance: number;
    name: string;
    email: string;
    password: string;
    phone: string;
    idUsergroup: number;
    active: boolean;
    inOverview: boolean;
    directLinkCode?: string;
    anonymous: boolean;
    canLogin: boolean;
    deleted?: boolean;
    functionId: number;
    sendEmail: boolean;
    userSubtrajectoriesDto: UserSubtrajectoryDto[];
};

export type UserSubtrajectoryDto = {
    userId: number,
    subtrajectoryId: number,
    subtrajectoryName: string,
    trajectoryName: string
};

export interface BasicUserModelForEdit {
    id: number;
    idToken?: string;
    idInstance: number;
    name: string;
    email: string;
    password: string;
    phone: string;
    idUsergroup: number;
    active: boolean;
    inOverview: boolean;
    directLinkCode?: string;
    anonymous: boolean;
    canLogin: boolean;
    deleted?: boolean;
    functionId: number;
    sendEmail: boolean;
    userSubtrajectories: number[];
};

export const mapUserForEdit = (x: BasicUserModel) => {
    return {
        id: x.id,
        idToken: x.idToken,
        idInstance: x.idInstance,
        name: x.name,
        email: x.email,
        password: x.password,
        phone: x.phone,
        idUsergroup: x.idUsergroup,
        active: x.active,
        inOverview: x.inOverview,
        directLinkCode: x.directLinkCode,
        anonymous: x.anonymous,
        canLogin: x.canLogin,
        deleted: x.deleted,
        functionId: x.functionId,
        sendEmail: x.sendEmail,
        userSubtrajectories: x.userSubtrajectoriesDto ? x.userSubtrajectoriesDto.map((x) => { return x.subtrajectoryId; }) : []
    } as BasicUserModelForEdit;
};

export const mapUserFromEdit = (x: BasicUserModelForEdit) => {
    return {
        id: x.id,
        idToken: x.idToken,
        idInstance: x.idInstance,
        name: x.name,
        email: x.email,
        password: x.password,
        phone: x.phone,
        idUsergroup: x.idUsergroup,
        active: x.active,
        inOverview: x.inOverview,
        directLinkCode: x.directLinkCode,
        anonymous: x.anonymous,
        canLogin: x.canLogin,
        deleted: x.deleted,
        functionId: x.functionId,
        sendEmail: x.sendEmail,
        userSubtrajectoriesDto: x.userSubtrajectories ? x.userSubtrajectories.map((y) => {
            return {
                userId: x.id,
                subtrajectoryId: y,
                subtrajectoryName: "",
                trajectoryName: ""
            } as UserSubtrajectoryDto;
        }) : []
    } as BasicUserModel;
};

export interface UserFunctionType {
    id: number;
    name: string;
}

export type UserListType = {
    id: number;
    name: string;
};

export type ErrorEntry = {
    code?: number;
    message?: string;
};

export type IpModel = {
    ip: string;
};

export type UsersModel = {
    getUsers: Thunk<UsersModel, UsersListType>;
    deleteUser: Thunk<UsersModel, DeleteUserType>;
    getBasicUser: Thunk<UsersModel>;
    addUser: Thunk<UsersModel, BasicUserModel>;
    getUserFunctions: Thunk<UsersModel>;
    getOneUser: Thunk<UsersModel, GetUserType>;
    editUser: Thunk<UsersModel, BasicUserModel>;
    getUsersList: Thunk<UsersModel>;
    getUserIp: Thunk<UsersModel>;
    getProfile: Thunk<UsersModel>;
    updateProfile: Thunk<UsersModel, ProfileModel>;
    getForSubtrajectory: Thunk<UsersModel, SubtrajectoryIdType>;
    getUsersForActions: Thunk<UsersModel>;
    getUsersForAction: Thunk<UsersModel, TestingsType>;
    error: ErrorEntry;
    setError: Action<UsersModel, ErrorEntry>;
    delError: Action<UsersModel>;
    getUserSignalsList: Thunk<UsersModel, GetUserType>;
    getUserActionsList: Thunk<UsersModel, GetUserType>;
    revertUser: Thunk<UsersModel, RevertUserType>;
};

const users: UsersModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getUsers: thunk(async (actions, payload) => {
        return fetchResponse<UsersList>({
            actions,
            payload,
            request: getUsers,
        });
    }),
    deleteUser: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteUser,
            message: "User has been reverted",
        });
    }),
    getBasicUser: thunk(async (actions) => {
        return fetchResponse<BasicUserModel>({
            actions,
            request: getBasicUser,
        });
    }),
    addUser: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addUser,
            message: "User has been added",
        });
    }),
    getUserFunctions: thunk(async (actions) => {
        return fetchResponse<UserFunctionType[]>({
            actions,
            request: getUserFunctions,
        });
    }),
    getOneUser: thunk(async (actions, payload) => {
        return fetchResponse<BasicUserModel>({
            actions,
            payload,
            request: getOneUser,
        });
    }),
    editUser: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: editUser,
            message: "User has been edited",
        });
    }),
    getUsersList: thunk(async (actions) => {
        return fetchResponse<UserListType[]>({
            actions,
            request: getUsersList,
        });
    }),
    getUserIp: thunk(async (actions) => {
        return fetchResponse<IpModel>({
            actions,
            request: getUserIp,
        });
    }),
    getProfile: thunk(async (actions) => {
        return fetchResponse<ProfileModel>({
            actions,
            request: getProfile,
        });
    }),
    updateProfile: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateProfile,
            message: "User has been edited",
        });
    }),
    getForSubtrajectory: thunk(async (actions, payload) => {
        return fetchResponse<UserListType[]>({
            actions,
            payload,
            request: getForSubtrajectory,
        });
    }),
    getUsersForActions: thunk(async (actions) => {
        return fetchResponse<UserListType[]>({
            actions,
            request: getForActions
        });
    }),
    getUsersForAction: thunk(async (actions, payload) => {
        return fetchResponse<UserListType[]>({
            actions,
            payload,
            request: getForEditActions
        });
    }),
    getUserSignalsList: thunk(async (actions, payload) => {
        return fetchResponse<string[]>({
            actions,
            payload,
            request: getUserSignalsList,
        });
    }),
    getUserActionsList: thunk(async (actions, payload) => {
        return fetchResponse<string[]>({
            actions,
            payload,
            request: getUserActionsList,
        });
    }),
    revertUser: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: revertUser,
            message: "User has been deleted",
        });
    })
};

export default users;
