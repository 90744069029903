import { Action, action, thunk, Thunk } from "easy-peasy";
import { LoginResponeEntry } from "../../components/logging/LogInEmail";
import { ResetPasswordTypes } from "./user.service";
import {
    login,
    LoginType,
    logout,
    sendForgotPasswordEmail,
    sendResetPasswordRequestKY,
    loginSecondStep,
    loginAs,
    loginBack,
    clearLoginBack,
    LoginSecondStepType,
    UserIdType,
    getForResponsibility,
    UserBoolType,
    getIsIpBlocked,
    getAllUserRights,
    getInstanceProms,
    getRememberFilters
} from "./user.service";

import { fetchBasic, fetchResponse } from "../../helpers/fetching";
import { ErrorEntry } from "../users/users.model";
import { LoginResponseSecondStep } from "../../components/logging/LogInCode";

export type UserModel = {
    isProms: boolean;
    setIsProms: Action<UserModel, boolean>;
    rememberFilters: boolean;
    setRememberFilters: Action<UserModel, boolean>;
    getInstanceProms: Thunk<UserModel>;
    isUserLoggedIn: boolean;
    loginToken: string;
    loginBackUserId: LoginResponeEntry[];
    userId: LoginResponeEntry[];
    intUserId: number;
    logOut: Action<UserModel>;
    logout: Thunk<UserModel>;
    setLoginBackUserId: Action<UserModel, LoginResponeEntry[]>;
    setUserId: Action<UserModel, LoginResponeEntry[]>;
    setIntUserId: Action<UserModel, number>;
    createLocalStorage: Action<UserModel>;
    logIn: Thunk<UserModel, LoginType>;
    error: ErrorEntry;
    setError: Action<UserModel, ErrorEntry>;
    delError: Action<UserModel>;
    setForgotPasswordSuccessStatus: Action<UserModel, boolean>;
    setForgotPasswordErrorStatus: Action<UserModel, boolean>;
    forgotPasswordSuccessStatus: boolean;
    forgotPasswordErrorStatus: boolean;
    isForgotPasswordLoading: boolean;
    setForgotPasswordLoading: Action<UserModel, boolean>;
    sendForgotPassEmail: Thunk<UserModel, { email: string }>;
    resetPasswordSuccessStatus: boolean;
    setResetPasswordSuccessStatus: Action<UserModel, boolean>;
    resetPasswordErrorStatus: number | string;
    setResetPasswordErrorStatus: Action<UserModel, number | string>;
    sendResetPasswordRequest: Thunk<UserModel, ResetPasswordTypes>;
    resetPasswordLoading: boolean;
    setResetPasswordLoading: Action<UserModel, boolean>;
    isLoginLoading: boolean;
    setIsLoginLoading: Action<UserModel, boolean>;
    loginErrorStatus: number | string;
    setLoginErrorStatus: Action<UserModel, number | string>;
    loginSuccessStatus: boolean;
    setLoginSuccessStatus: Action<UserModel, boolean>;
    setLoginToken: Action<UserModel, string>;
    loginSecondStep: Thunk<UserModel, LoginSecondStepType>;
    loginAs: Thunk<UserModel, UserIdType>;
    loginBack: Thunk<UserModel>;
    clearLoginBack: Thunk<UserModel>;
    getUsersForResponsibility: Thunk<UserModel>;
    getIsIpBlocked: Thunk<UserModel, boolean>;
    getAllUserRights: Thunk<UserModel>;
    getRememberFilters: Thunk<UserModel>;
};

const user: UserModel = {
    isProms: false,
    setIsProms: action((state, payload) => {
        state.isProms = payload;
    }),
    rememberFilters: false,
    setRememberFilters: action((state, payload) => {
        state.rememberFilters = payload;
    }),
    getInstanceProms: thunk(async (actions) => {
        const response = await getInstanceProms();
        const parsed = (await response.json()) as boolean;
        actions.setIsProms(parsed);
        return parsed;
    }),
    loginSuccessStatus: false,
    loginToken: "",
    loginBackUserId: JSON.parse(
        window.localStorage.getItem("permissionsBack") || "[]"
    ),
    isLoginLoading: false,
    resetPasswordLoading: false,
    resetPasswordErrorStatus: "",
    loginErrorStatus: "",
    resetPasswordSuccessStatus: false,
    isForgotPasswordLoading: false,
    forgotPasswordSuccessStatus: false,
    forgotPasswordErrorStatus: false,
    error: { code: 0 },
    isUserLoggedIn: window.localStorage.getItem("is_logged") === "true",
    userId: [],
    intUserId: 0,
    setLoginSuccessStatus: action((state, payload) => {
        state.loginSuccessStatus = payload;
    }),
    setLoginToken: action((state, payload) => {
        state.loginToken = payload;
    }),
    setLoginErrorStatus: action((state, payload) => {
        state.loginErrorStatus = payload;
    }),
    setIsLoginLoading: action((state, payload) => {
        state.isLoginLoading = payload;
    }),
    setForgotPasswordSuccessStatus: action((state, payload) => {
        state.forgotPasswordSuccessStatus = payload;
    }),
    setForgotPasswordErrorStatus: action((state, payload) => {
        state.forgotPasswordErrorStatus = payload;
    }),
    setForgotPasswordLoading: action((state, payload) => {
        state.isForgotPasswordLoading = payload;
    }),
    setResetPasswordSuccessStatus: action((state, payload) => {
        state.resetPasswordSuccessStatus = payload;
    }),
    setResetPasswordErrorStatus: action((state, payload) => {
        state.resetPasswordErrorStatus = payload;
    }),
    setResetPasswordLoading: action((state, payload) => {
        state.resetPasswordLoading = payload;
    }),
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    setIntUserId: action((state, payload) => {
        state.intUserId = payload;
    }),
    logOut: action((state) => {
        state.userId.splice(0, state.userId.length);

        window.localStorage.setItem("is_logged", "false");
        window.localStorage.removeItem("permissions");
        window.localStorage.removeItem("permissionsBack");
        state.isUserLoggedIn = false;
        state.intUserId = 0;
    }),
    setLoginBackUserId: action((state, payload) => {
        state.loginBackUserId = payload;
        window.localStorage.setItem("permissionsBack", JSON.stringify(payload));
    }),
    setUserId: action((state, payload) => {
        state.userId = payload;
        window.localStorage.setItem("permissions", JSON.stringify(payload));
    }),
    createLocalStorage: action((state, payload) => {
        window.localStorage.setItem("is_logged", "true");
        state.isUserLoggedIn = true;
    }),

    logIn: thunk(async (actions, payload) => {
        try {
            actions.setIsLoginLoading(true);
            const response = await login(payload);
            const parsed = (await response.json()) as LoginResponeEntry[] | LoginResponseSecondStep;
            if (parsed && Array.isArray(parsed)) {
                actions.setLoginBackUserId([]);
                actions.setUserId(parsed);
                actions.setLoginSuccessStatus(true);
                (response.status === 200) && actions.createLocalStorage();
            } else if (parsed && "token" in parsed) {
                actions.setLoginToken(parsed["token"]);
            }

            return parsed;
        } catch (error) {
            const { message } = await error.response.json();
            actions.setLoginErrorStatus(message);
        } finally {
            actions.setIsLoginLoading(false);
        }
    }),
    logout: thunk(async (actions) => {
        return fetchBasic({
            actions,
            request: logout,
            message: "You been logged out",
        });
    }),
    sendForgotPassEmail: thunk(async (actions, payload) => {
        try {
            actions.setForgotPasswordLoading(true);
            await sendForgotPasswordEmail(payload);
            actions.setForgotPasswordSuccessStatus(true);
        } catch (error) {
            if (error.response.status === 400) {
                actions.setForgotPasswordErrorStatus(true);
            } else {
                const message = await error.response.json();
            }
        } finally {
            actions.setForgotPasswordLoading(false);
        }
    }),
    sendResetPasswordRequest: thunk(async (actions, payload) => {
        try {
            actions.setResetPasswordLoading(true);
            await sendResetPasswordRequestKY(payload);
            actions.setResetPasswordSuccessStatus(true);
        } catch (error) {
            if (error.response.status === 400) {
                const { message } = await error.response.json();
                actions.setResetPasswordErrorStatus(message);
            } else {
                const { message } = await error.response.json();
                actions.setResetPasswordErrorStatus(message);
            }
        } finally {
            actions.setResetPasswordLoading(false);
        }
    }),
    loginSecondStep: thunk(async (actions, payload) => {
        try {
            actions.setIsLoginLoading(true);
            const response = await loginSecondStep(payload);
            const parsed = (await response.json()) as LoginResponeEntry[];
            actions.setLoginBackUserId([]);
            actions.setUserId(parsed);
            actions.setLoginSuccessStatus(true);
            actions.setLoginToken("");
            if (response.status === 200) actions.createLocalStorage();

            return parsed;
        } catch (error) {
            const { message } = await error.response.json();
            actions.setLoginErrorStatus(message);
            actions.setLoginSuccessStatus(false);
        } finally {
            actions.setIsLoginLoading(false);
        }
    }),
    loginAs: thunk(async (actions, payload) => {
        try {
            actions.setIsLoginLoading(true);
            const data = JSON.parse(window.localStorage.getItem("permissions") || "");
            const response = await loginAs(payload);
            const parsed = (await response.json()) as LoginResponeEntry[];
            actions.setLoginBackUserId(data);
            actions.setUserId(parsed);
            actions.setLoginSuccessStatus(true);
            actions.setLoginToken("");
            if (response.status === 200) actions.createLocalStorage();

            return parsed;
        } catch (error) {
            actions.setLoginErrorStatus(error.message);
            actions.setLoginSuccessStatus(false);
        } finally {
            actions.setIsLoginLoading(false);
        }
    }),
    getAllUserRights: thunk(async (actions) => {
        const response = await getAllUserRights();
        const parsed = (await response.json()) as LoginResponeEntry[];
        actions.setUserId(parsed);
    }),
    loginBack: thunk(async (actions) => {
        try {
            actions.setIsLoginLoading(true);
            const response = await loginBack();
            const parsed = (await response.json()) as LoginResponeEntry[];
            actions.setLoginBackUserId([]);
            actions.setUserId(parsed);
            actions.setLoginSuccessStatus(true);
            actions.setLoginToken("");
            if (response.status === 200) actions.createLocalStorage();

            return parsed;
        } catch (error) {
            actions.setLoginErrorStatus(error.message);
            actions.setLoginSuccessStatus(false);
        } finally {
            actions.setIsLoginLoading(false);
        }
    }),
    clearLoginBack: thunk(async (actions) => {
        try {
            await clearLoginBack();
            actions.setLoginBackUserId([]);
            actions.setLoginToken("");
        } catch (error) {
            actions.setLoginErrorStatus(error.message);
        }
    }),
    getUsersForResponsibility: thunk(async (actions) => {
        const response = await getForResponsibility();
        const parsed = (await response.json());

        return parsed;
    }),
    getIsIpBlocked: thunk(async (actions, payload) => {
        return fetchResponse<UserBoolType>({
            actions,
            payload: { val: payload },
            request: getIsIpBlocked,
        });
    }),
    getRememberFilters: thunk(async (actions) => {
        const response = await getRememberFilters();
        const parsed = (await response.json()) as UserBoolType;
        actions.setRememberFilters(parsed.val);
    }),
};

export default user;
