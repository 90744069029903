import ky from "../ky";

export type StoriesProps = {
    fromDate?: string | null | undefined,
    toDate?: string | null | undefined,
    subtrajectoryIds?: number[],
    npsPositive: boolean,
    npsNegative: boolean,
    npsNeutral: boolean,
    noNps: boolean,
    internal: boolean
};

export type VisitsProps = {
    pageSize?: number,
    pageNumber: number,
    orderBy: string,
    isDescending: boolean,
    subtrajectoryIds?: number[],
    fromDate?: string | null | undefined,
    toDate?: string | null | undefined,
    internal: boolean,
    searchWord: string,
    emailFilter: number | "",
    artsId: number | "",
    visitsType: number,
    languageCode: string,
    signal?: AbortSignal | null | undefined
};

export type FooterProps = {
    fromDate?: string | null | undefined,
    toDate?: string | null | undefined,
    subtrajectoryIds?: number[],
    internal: boolean
};

export type StoriesPropsInternal = {
    internal: boolean,
    onDashboard?: boolean,
    fromDate?: string | null | undefined,
    toDate?: string | null | undefined
};

export type StoriesPropsTag = { 
    fromDate?: string | null | undefined,
    toDate?: string | null | undefined,
    subtrajectoryIds?: number[],
    tag: string,
    isPositive: boolean,
    pageNumber: number,
    patientNumber: string,
    orderBy: string,
    isDescending: boolean,
    pageSize?: number,
    internal: boolean,
    signal?: AbortSignal | null | undefined
};

export type StoriesPropsCpmplements = {
    fromDate?: string | null | undefined,
    toDate?: string | null | undefined,
    subtrajectoryIds?: number[],
    npsPositive: boolean,
    npsNegative: boolean,
    npsNeutral: boolean,
    noNps: boolean,
    patientNumber?: string | null | undefined,
    signals: number,
    actions: number,
    settled: number,
    labels: number,
    pageNumber: number,
    searchWord?: string | null | undefined,
    orderBy: string,
    isDescending: boolean,
    pageSize?: number,
    internal: boolean,
    signal?: AbortSignal | null | undefined
};

export type TestingLogProps = {
    testingCode: string,
    pageNumber: number,
    orderBy: string,
    isDescending: boolean,
    pageSize?: number
};

export type SetSettledProps = {
    searchParameters: StoriesPropsCpmplements,
    code: string,
    settled: boolean,
    isPositive: boolean
};

export type RefreshTimeProps = {
    pageName: string
};

export const getStories = (data: StoriesProps) =>
    ky.post(`Dashboard/stories`, { json: data, timeout: 30000 });

export const getDashboardStats = () => ky.get(`Dashboard/stats`, { timeout: 30000 });

export const getLabels = (data: StoriesProps) =>
    ky.post(`Dashboard/labels`, { json: data, timeout: 30000 });

export const getAllAnswers = (data: StoriesProps) =>
    ky.post(`Dashboard/numbersOfAnswers`, { json: data, timeout: 30000 });

export const getAllInfo = (data: StoriesPropsInternal) =>
    ky.get(`Dashboard/allInfo?intern=${data.internal ? "true" : "false"}${data.fromDate ? `&fromDate=${data.fromDate}` : ""}${data.toDate ? `&toDate=${data.toDate}` : ""}`, { timeout: 30000 });

export const getAllInfoFiltered = (data: StoriesProps) =>
    ky.post(`Dashboard/allInfo`, { json: data, timeout: 30000 });

export const getWords = (data: StoriesProps) =>
    ky.post(`Dashboard/wordcloud`, { json: data, timeout: 30000 });

export const getSubtrajects = () => ky.get(`Dashboard/subtrajects`, { timeout: 30000 });

export const getSubtrajects2 = (data: StoriesPropsInternal) =>
    ky.get(`Dashboard/subtrajects2?intern=${data.internal ? "true" : "false"}${data.onDashboard === true ? "&onDashboard=true" : ""}`, { timeout: 30000 });

export const getTags = (data: StoriesPropsTag) =>
    ky.post(`Visits/tags`, { json: data, timeout: 30000, signal: data.signal });

export const getComplements = (data: StoriesPropsCpmplements) =>
    ky.post(`Dashboard/numbersOfAnswers/satisfied`, { json: data, timeout: 120000, signal: data.signal });

export const getImprovements = (data: StoriesPropsCpmplements) =>
    ky.post(`Dashboard/numbersOfAnswers/toImprove`, { json: data, timeout: 120000, signal: data.signal });

export const getTestingLogs = (data: TestingLogProps) =>
    ky.post(`Visits/Logs/testing`, { json: data, timeout: 30000 });

export const setSettled = (data: SetSettledProps) =>
    ky.post(`Dashboard/improvements/setSettled?code=${data.code}&settled=${data.settled ? "true" : "false"}&isPositive=${data.isPositive ? "true" : "false"}`,
        { json: data.searchParameters, timeout: 30000 });

export const getFooter = (data: FooterProps) =>
    ky.post(`Dashboard/footerData`, { json: data, timeout: 30000 });

export const getDashboardVisits = (data: VisitsProps) =>
    ky.post(`Dashboard/getDashboardVisits`, { json: data, timeout: 30000, signal: data.signal });

export const getRefreshTime = (data: RefreshTimeProps) =>
    ky.get(`Dashboard/getRefreshTime?page=${data.pageName}`, { timeout: 30000 });