import { thunk, Thunk, Action, action } from "easy-peasy";

import {
    TrajectoriesListType,
    TrajectoriesType,
    IdTrajectoriesType,
    addTrajectories,
    deleteTrajectories,
    getTrajectoriesList,
    getOneTrajectories,
    updateTrajectories,
    copyTrajectory,
    CopyType,
    getPathForSubtrajectory,
    getPathForEvaluationMom,
    getPathForResponsibility,
    getPathForCommunicationMom,
    IdsType,
    getToShow,
    getToShowOnStart,
} from "./categories.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

export type CategoriesList = {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    count: number;
    items: TrajectoriesType[];
    hasPrevious: boolean;
    hasNext: boolean;
};

export type TrajectoriesModel = {
    getTrajectoriesList: Thunk<TrajectoriesModel, TrajectoriesListType>;
    deleteTrajectories: Thunk<TrajectoriesModel, IdTrajectoriesType>;
    addTrajectories: Thunk<TrajectoriesModel, TrajectoriesType>;
    getOneTrajectories: Thunk<TrajectoriesModel, IdTrajectoriesType>;
    updateTrajectories: Thunk<TrajectoriesModel, TrajectoriesType>;
    copyTrajectory: Thunk<TrajectoriesModel, CopyType>;
    getPathForSubtrajectory: Thunk<TrajectoriesModel, IdsType>;
    getPathForEvaluationMom: Thunk<TrajectoriesModel, IdsType>;
    getPathForResponsibility: Thunk<TrajectoriesModel, IdsType>;
    getPathForCommunicationMom: Thunk<TrajectoriesModel, IdsType>;
    getToShow: Thunk<TrajectoriesModel>;
    getToShowOnStart: Thunk<TrajectoriesModel>;
    error: ErrorEntry;
    setError: Action<TrajectoriesModel, ErrorEntry>;
    delError: Action<TrajectoriesModel>;
};

export type NavigationType = {
    id: number;
    title: string;
    active: boolean;
    subtrajectories?: NavigationItem[];
};

export type NavigationItem = {
    id: number;
    title: string;
    hasEvaluationMoment?: boolean;
};
const Trajectories: TrajectoriesModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getTrajectoriesList: thunk(async (actions, payload) => {
        return fetchResponse<CategoriesList>({
            actions,
            payload,
            request: getTrajectoriesList,
        });
    }),
    deleteTrajectories: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteTrajectories,
            message: "Category has been deleted",
        });
    }),
    addTrajectories: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addTrajectories,
            message: "Category has been added",
        });
    }),
    getOneTrajectories: thunk(async (actions, payload) => {
        return fetchResponse<TrajectoriesType>({
            actions,
            payload,
            request: getOneTrajectories,
        });
    }),
    updateTrajectories: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateTrajectories,
            message: "Category has been edited",
        });
    }),
    copyTrajectory: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: copyTrajectory,
            message: "Category has been copied",
        });
    }),
    getPathForSubtrajectory: thunk(async (actions, payload) => {
        return fetchResponse<NavigationType>({
            actions,
            payload,
            request: getPathForSubtrajectory,
        });
    }),
    getPathForEvaluationMom: thunk(async (actions, payload) => {
        return fetchResponse<NavigationType>({
            actions,
            payload,
            request: getPathForEvaluationMom,
        });
    }),
    getPathForResponsibility: thunk(async (actions, payload) => {
        return fetchResponse<NavigationType>({
            actions,
            payload,
            request: getPathForResponsibility,
        });
    }),
    getPathForCommunicationMom: thunk(async (actions, payload) => {
        return fetchResponse<NavigationType>({
            actions,
            payload,
            request: getPathForCommunicationMom,
        });
    }),
    getToShow: thunk(async (actions) => {
        return fetchResponse<NavigationType[]>({
            actions,
            request: getToShow,
        });
    }),
    getToShowOnStart: thunk(async (actions) => {
        return fetchResponse<NavigationType[]>({
            actions,
            request: getToShowOnStart,
        });
    }),
};

export default Trajectories;
