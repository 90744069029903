import ky from "../ky";

import { PopupType } from "./defPopups.model";

export type PopupId = {
    id: number;
};

export type SimpleId = {
    id: number;
};

export type BasicDefPopupModel = {
    
    id?: number;
    title: string;
    deleted: boolean;
    createDate: string; 
    startDate: string;
    endDate: string;
    defPopupTextDto: DefPopupTextType[];
    instanceIds: number[];
    groupPositions: number[];
}

export type DefPopupTextType = {
    languageCode: string;
    value: string;
}

export type PopupsListType = {
    currentPageNo: number;
    search: string;
    sortCol: string;
    isDescending: boolean;
    fromDate?: string | null | undefined;
    toDate?: string | null | undefined;
    languageCode: string;
};

export type StoriesPropsInternal = {
    internal: boolean,
    onDashboard?: boolean,
    fromDate?: string | null | undefined,
    toDate?: string | null | undefined
};

export const getPopupsList = ({
    isDescending,
    currentPageNo,
    search,
    sortCol,
    languageCode,
}: PopupsListType) =>
    ky.get(
        `DefPopup?PageNumber=${currentPageNo}&SearchWord=${search}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10&languageCode=${languageCode}`
    );

export const updatePopup = ({
    id,
    title,
    deleted,
    createDate,
    startDate,
    endDate,
    defPopupTextDto,
    instanceIds,
    groupPositions   }: BasicDefPopupModel) =>
    ky.put(`DefPopup`, {
        json: {
            id,
            title,
            deleted,
            createDate,
            startDate,
            endDate,
            defPopupTextDto,
            instanceIds,
            groupPositions
        },
    });

export const addPopup = ({
    id,
    title,
    deleted,
    startDate,
    endDate,
    defPopupTextDto,
    instanceIds,
    groupPositions }: BasicDefPopupModel) =>
    ky.post(`DefPopup/add`, {
        json: {
            id,
            title,
            deleted,
            startDate,
            endDate,
            defPopupTextDto,
            instanceIds,
            groupPositions
        },
    });

export type DeletePopupType = {
    id: number;
};

export const deletePopup = ({ id }: DeletePopupType) =>
    ky.delete(`DefPopup/${id}`);

export const getBasicPopup = () =>
    ky.get(`DefPopup/basic`);

export const getOnePopup = ({ id }: PopupId) =>
    ky.get(`DefPopup/${id}`);

export const getInstances = () =>
    ky.get(`DefPopup/instanceIds`, { timeout: 30000 });

export const getGroupPositions = () => 
    ky.get(`DefPopup/groupPositions`, { timeout: 30000 });

export const getInstances2 = (data: StoriesPropsInternal) =>
    ky.get(`DefPopup/instanceIds2?intern=${data.internal ? "true" : "false"}${data.onDashboard === true ? "&onDashboard=true" : ""}`, { timeout: 30000 });
