import { thunk, Thunk, Action, action } from "easy-peasy";
import {
  getQueryPropertiesList,
  editQueryProperty,
  QueryDetailType,
  deleteQuery,
  QueryId,
  getOneQuery,
  addQuery,
  getQueryTypes,
  getTypesForResponsibility,
  getQueryToShow,
  QuestionnaireId,
  getQueryForResponsibilities,
} from "./query.service";
import { ErrorEntry } from "../users/users.model";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";

export type QueryType = {
  id: number;
  title: string;
  spsslabel?: string;
  locked?: boolean;
};

export type QueryTypeType = {
  id: number;
  name: string;
};

export type QueryPropertiesModel = {
  getQueryPropertiesList: Thunk<QueryPropertiesModel>;
  getQueryTypes: Thunk<QueryPropertiesModel>;
  getTypesForResponsibility: Thunk<QueryPropertiesModel>;
  getQueryToShow: Thunk<QueryPropertiesModel>;
  editQueryProperty: Thunk<QueryPropertiesModel, QueryDetailType>;
  addQuery: Thunk<QueryPropertiesModel, QueryDetailType>;
  deleteQuery: Thunk<QueryPropertiesModel, QueryId>;
  getOneQuery: Thunk<QueryPropertiesModel, QueryId>;
  getQueryForResponsibilities: Thunk<QueryPropertiesModel, QuestionnaireId>;
  error: ErrorEntry;
  setError: Action<QueryPropertiesModel, ErrorEntry>;
  delError: Action<QueryPropertiesModel>;
};

const query: QueryPropertiesModel = {
  error: { code: 0 },
  setError: action((state, payload) => {
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),
  getQueryPropertiesList: thunk(async (actions) => {
    return fetchResponse<QueryType[]>({
      actions,
      request: getQueryPropertiesList,
    });
  }),
  editQueryProperty: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: editQueryProperty,
      message: "Query has been edited",
    });
  }),
  deleteQuery: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: deleteQuery,
      message: "Query has been deleted",
    });
  }),
  getOneQuery: thunk(async (actions, payload) => {
    return fetchResponse<QueryType>({
      actions,
      payload,
      request: getOneQuery,
    });
  }),
  addQuery: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: addQuery,
      message: "Query has been added",
    });
  }),
  getQueryTypes: thunk(async (actions) => {
    return fetchResponse<QueryTypeType[]>({
      actions,
      request: getQueryTypes,
    });
  }),
  getTypesForResponsibility: thunk(async (actions) => {
    return fetchResponse<QueryTypeType[]>({
      actions,
      request: getTypesForResponsibility,
    });
  }),
  getQueryToShow: thunk(async (actions) => {
    return fetchResponse<QueryType[]>({
      actions,
      request: getQueryToShow,
    });
  }),
  getQueryForResponsibilities: thunk(async (actions, payload) => {
    return fetchResponse<QueryType[]>({
      actions,
      payload,
      request: getQueryForResponsibilities,
    });
  }),
};

export default query;
