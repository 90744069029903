import { thunk, Thunk, Action, action } from "easy-peasy";
import {
    getDefList,
    LanguageCodeType,
    getDefListById,
    QuestionTypeEval,
    LanguageCodeTypeEval,
    getDefListByEval,
    LanguageCodeTypeById,
    updateDefinition,
    EditDefType,
    EditQuestDefType,
    deleteDefinition,
    DefId,
    getOneDefinition,
    addDefinition,
    getBasicDef,
    QuestionTypeType,
    ImportQuestionType,
    importQuestions,
    ImportQuestionFinalType,
    importQuestionsFinal,
    addQuestionDef
} from "./defQuestions.service";
import { ErrorEntry } from "../users/users.model";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";

export interface DefinitionType {
    id: number;
    instanceId: number;
    theme: string;
    themeCode: number;
    spsslabel: string;
    questionType: number;
    questionTypeName: string;
    layout: null | number;
    layoutName: string;
    toonWaardes: null | number;
    toonImages: null | number;
    title: string;
    locked: boolean;
    lastUpdateDate: string;
    lastUpdateText: string;
    active: boolean;
    defQuestionTextDto: DefTextType;
    questionaires: string[];
}

export type DefTextType = {
    instruction: string;
    question: string;
    textLeft: null;
    textRight: null;
};

export type DefQuestionModel = {
    getDefList: Thunk<DefQuestionModel, LanguageCodeType>;
    getDefListById: Thunk<DefQuestionModel, LanguageCodeTypeById>;
    getDefListByEval: Thunk<DefQuestionModel, LanguageCodeTypeEval>;
    updateDefinition: Thunk<DefQuestionModel, EditDefType>;
    addDefinition: Thunk<DefQuestionModel, EditDefType>;
    addQuestionDef: Thunk<DefQuestionModel, EditQuestDefType>;
    getBasicDef: Thunk<DefQuestionModel, QuestionTypeType>;
    deleteDefinition: Thunk<DefQuestionModel, DefId>;
    getOneDefinition: Thunk<DefQuestionModel, DefId>;
    importQuestions: Thunk<DefQuestionModel, ImportQuestionType>;
    importQuestionsFinal: Thunk<DefQuestionModel, ImportQuestionFinalType>;
    error: ErrorEntry;
    setError: Action<DefQuestionModel, ErrorEntry>;
    delError: Action<DefQuestionModel>;
};

export type ColumnName = {
    key: string,
    val: string
};

export type ImportData = {
    columnNames: ColumnName[],
    fileData: string[][],
    languageCode: string
};

export type ImportResult = {
    totalRowsCount: number,
    processedRowsCount: number,
    ignoredRowsCount: number,
    successedRowsCount: number,
    wrongRowsCount: number,
    addedRowsCount: number,
    updatedRowsCount: number,
    errors: ColumnName[]
};

const defQuestions: DefQuestionModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getDefList: thunk(async (actions, payload) => {
        return fetchResponse<DefinitionType[]>({
            actions,
            payload,
            request: getDefList,
        });
    }),
    getDefListById: thunk(async (actions, payload) => {
        return fetchResponse<DefinitionType[]>({
            actions,
            payload,
            request: getDefListById,
        });
    }),
    getDefListByEval: thunk(async (actions, payload) => {
        return fetchResponse<DefinitionType[]>({
            actions,
            payload,
            request: getDefListByEval,
        });
    }),
    updateDefinition: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateDefinition,
            message: "Definition has been edited",
        });
    }),
    deleteDefinition: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteDefinition,
            message: "Definition has been deleted",
        });
    }),
    getOneDefinition: thunk(async (actions, payload) => {
        return fetchResponse<DefinitionType>({
            actions,
            payload,
            request: getOneDefinition,
        });
    }),
    addDefinition: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addDefinition,
            message: "Definition has been added",
        });
    }),
    addQuestionDef: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addQuestionDef,
            message: "Question has been added",
        });
    }),
    getBasicDef: thunk(async (actions, payload) => {
        return fetchResponse<DefinitionType>({
            actions,
            payload,
            request: getBasicDef,
        });
    }),
    importQuestions: thunk(async (actions, payload) => {
        return fetchResponse<ImportData>({
            actions,
            payload,
            request: importQuestions
        });
    }),
    importQuestionsFinal: thunk(async (actions, payload) => {
        return fetchResponse<ImportResult>({
            actions,
            payload,
            request: importQuestionsFinal
        });
    }),
};

export default defQuestions;
