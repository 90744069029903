import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {getCurrentTenantConfig} from "./config/tenant-config";
import {StoreProvider, createStore} from "easy-peasy";
import model, {StoreModel} from "./models/store";

const THEME = createMuiTheme({
    typography: {
        "fontFamily": `"Maven Pro", sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    }
});

const store = createStore<StoreModel>(model);

const Root: React.FC = () => {
    const tenant = getCurrentTenantConfig().tenant;
    const theme = require(`./theme/themes/${tenant}`).default;

    return (
        <MuiThemeProvider theme={THEME}>
            <StoreProvider store={store}>
                <App theme={theme}/>
            </StoreProvider>
        </MuiThemeProvider>
    );
};

const rootElement = document.getElementById("root");
ReactDOM.render(<Root/>, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
