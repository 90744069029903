import { thunk, Thunk, Action, action } from "easy-peasy";
import {
    getUserGroup,
    UserGroupListType,
    deleteUserGroup,
    DeleteUserGroupType,
    copyUserGroup,
    CopyUserGroupType,
    getBasicUserGroup,
    getPasswordRegex,
    addUserGroup,
    editGroup,
    EditGroupType,
    updateGroup,
    getUserGroups,
    getPermissions,
    getGroupPermissions,
    PermListType,
    setGroupPermissions,
    getPasswordRegexForUser,
    getUserGroupForUser,
    getUserGroupWithToken,
    getPasswordRegexWithToken,
    Token,
    SetFiltersGroupType,
    setFiltersGroup
} from "./usergroups.service";
import { BasicUserGroupModel } from "../../pages/portal/usergroups/model";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";
import { LoginResponeEntry } from "../../components/logging/LogInEmail";

export interface UserGroupList {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    count: number;
    items: UserGroupItem[];
    hasPrevious: boolean;
    hasNext: boolean;
}

export type UserGroupItem = {
    id: number;
    name: string;
    position: number;
};

export type RegexEntry = {
    id: number;
    name: string;
    regex: string;
};

export type ErrorEntry = {
    code?: number;
    message?: string;
};

export type UserGroupModel = {
    getUserGroup: Thunk<UserGroupModel, UserGroupListType>;
    deleteUserGroup: Thunk<UserGroupModel, DeleteUserGroupType>;
    copyUserGroup: Thunk<UserGroupModel, CopyUserGroupType>;
    getBasicUserGroup: Thunk<UserGroupModel>;
    getPasswordRegex: Thunk<UserGroupModel>;
    getPasswordRegexForUser: Thunk<UserGroupModel>;
    getUserGroupForUser: Thunk<UserGroupModel>;
    addUserGroup: Thunk<UserGroupModel, BasicUserGroupModel>;
    updateGroup: Thunk<UserGroupModel, BasicUserGroupModel>;
    editGroup: Thunk<UserGroupModel, EditGroupType>;
    getUserGroups: Thunk<UserGroupModel>;
    getPermissions: Thunk<UserGroupModel>;
    getGroupPermissions: Thunk<UserGroupModel, EditGroupType>;
    setGroupPermissions: Thunk<UserGroupModel, PermListType>;
    error: ErrorEntry;
    setError: Action<UserGroupModel, ErrorEntry>;
    delError: Action<UserGroupModel>;
    getPasswordRegexWithToken: Thunk<UserGroupModel, Token>;
    getUserGroupWithToken: Thunk<UserGroupModel, Token>;
    setFiltersGroup: Thunk<UserGroupModel, SetFiltersGroupType>;
};

const userGroup: UserGroupModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getUserGroup: thunk(async (actions, payload) => {
        return fetchResponse<UserGroupList>({
            actions,
            payload,
            request: getUserGroup,
        });
    }),
    deleteUserGroup: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteUserGroup,
            message: "User group has been deleted",
        });
    }),
    copyUserGroup: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: copyUserGroup,
            message: "User group has been copied",
        });
    }),
    getBasicUserGroup: thunk(async (actions) => {
        return fetchResponse<BasicUserGroupModel>({
            actions,
            request: getBasicUserGroup,
        });
    }),
    getPasswordRegex: thunk(async (actions) => {
        return fetchResponse<RegexEntry[]>({
            actions,
            request: getPasswordRegex,
        });
    }),
    addUserGroup: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addUserGroup,
            message: "Group has been added",
        });
    }),
    editGroup: thunk(async (actions, payload) => {
        return fetchResponse<BasicUserGroupModel>({
            actions,
            payload,
            request: editGroup,
        });
    }),
    updateGroup: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateGroup,
            message: "User group has been edited",
        });
    }),
    getUserGroups: thunk(async (actions, payload) => {
        return fetchResponse<UserGroupItem[]>({
            actions,
            request: getUserGroups,
        });
    }),
    getPermissions: thunk(async (actions) => {
        return fetchResponse<LoginResponeEntry[]>({
            actions,
            request: getPermissions,
        });
    }),
    getGroupPermissions: thunk(async (actions, payload) => {
        return fetchResponse<LoginResponeEntry[]>({
            actions,
            payload,
            request: getGroupPermissions,
        });
    }),
    setGroupPermissions: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: setGroupPermissions,
            message: "Permissions has been edited",
        });
    }),
    getPasswordRegexForUser: thunk(async (actions) => {
        return fetchResponse<RegexEntry>({
            actions,
            request: getPasswordRegexForUser,
        });
    }),
    getUserGroupForUser: thunk(async (actions) => {
        return fetchResponse<BasicUserGroupModel>({
            actions,
            request: getUserGroupForUser,
        });
    }),
    getPasswordRegexWithToken: thunk(async (actions, payload) => {
        return fetchResponse<RegexEntry>({
            actions,
            payload,
            request: getPasswordRegexWithToken
        });
    }),
    getUserGroupWithToken: thunk(async (actions, payload) => {
        return fetchResponse<BasicUserGroupModel>({
            actions,
            payload,
            request: getUserGroupWithToken
        });
    }),
    setFiltersGroup: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: setFiltersGroup,
            message: "Remember filters has been set",
        });
    }),
};

export default userGroup;
