import ky from "../ky";

export type LoginLogListType = {
  ipAddress: string;
  userName: string;
  email: string;
  result: string;
  code: string;
  currentPageNo: number;
  query: string;
  sortCol: string;
  isDescending: boolean;
}

export const getLoginLogs = ({
  ipAddress,
  userName,
  email,
  result,
  code,
  currentPageNo,
  query,
  sortCol,
  isDescending,
}: LoginLogListType) =>
  ky.get(
      `LoginLogs?IpAddress=${ipAddress}&UserName=${userName}&Email=${email}&Result=${result}&Code=${code}&PageNumber=${currentPageNo}&SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10`, { timeout: 60000 }
  );

  export type GetLoginDetails = {
    id?: string;
  }

  export const getLoginDetails = ( {id}: GetLoginDetails ) => ky.get(`LoginLogs/${id}`);