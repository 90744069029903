import ky from "../ky";

import { EvaluationType, EvaluationQuestionnaire } from "./evaluation.model";

export type SubcategoryId = {
  subtrajectoryId: number;
};

export const getEvaluationMoments = ({ subtrajectoryId }: SubcategoryId) =>
  ky.get(`EvaluationMoments?subtrajectoryId=${subtrajectoryId}`);

export type EvaluationMoment = {
  data: EvaluationType;
};

export const updateEvaluationMoment = ({ data }: EvaluationMoment) =>
  ky.put(`EvaluationMoments`, { json: data });

export type EvaluationId = {
  id: number;
};

export const deleteEvaluationMoment = ({ id }: EvaluationId) =>
  ky.delete(`EvaluationMoments?id=${id}`);

export const getOneMoment = ({ id }: EvaluationId) =>
  ky.get(`EvaluationMoments/${id}`);

export const addEvaluationMoment = ({ data }: EvaluationMoment) =>
  ky.post(`EvaluationMoments/add`, { json: data });

export const getBasicMoment = ({ subtrajectoryId }: SubcategoryId) =>
  ky.get(`EvaluationMoments/basic?subtrajectoryId=${subtrajectoryId}`);

export type EvaluationMomentId = {
  evaluationMomentId: number;
  data?: { ids: number[] };
};

export const getQuestionnaireListForEvaluation = ({
  evaluationMomentId,
}: EvaluationMomentId) =>
  ky.get(
    `EvaluationMoments/questionnaire?evaluationMomentId=${evaluationMomentId}`
  );

export type updateEvaluationMomentType = {
  data: {
    id: number;
    active: boolean;
    inExcel: boolean;
    evaluationMomentId: number;
    questionaireId: number;
    greater: boolean | null;
    number: number | null;
  };
};

export const updateEvaluationMomentQuestionnaire = ({
  data,
}: updateEvaluationMomentType) =>
  ky.put(`EvaluationMoments/questionnaire`, { json: data });

export const deleteEvaluationMomentQuestionnaire = ({ id }: EvaluationId) =>
  ky.delete(`EvaluationMoments/questionnaire?id=${id}`);

export const getEvaluationMomentQuestionnaire = ({ id }: EvaluationId) =>
  ky.get(`EvaluationMoments/questionnaire/${id}`);

export const addEvaluationMomentQuestionnaire = ({
  evaluationMomentId,
  data,
}: EvaluationMomentId) =>
  ky.post(
    `EvaluationMoments/questionnaire/add?evaluationMomentId=${evaluationMomentId}`,
    { json: data }
  );

export const editPositionList = (
  evaluationMomentQuestionaires: EvaluationQuestionnaire[]
) =>
  ky.put(`EvaluationMoments/editPositionList`, {
    json: { evaluationMomentQuestionaires },
  });

export type EvaluationViewProps = {
  evaluationId: number,
  operationId: number
};

export const getEvaluationView = ({ evaluationId, operationId }: EvaluationViewProps) => 
  ky.get(`EvaluationMoments/visit?evaluationMomentId=${evaluationId}&operationId=${operationId}`);