import ky from "../ky";

export type LoginType = {
  email: string;
  password: string;
};

export type EmailType = {
  email: string;
};

export type ResetPasswordTypes = {
  token: string;
  bit: string;
  newPassword: string;
  confirmPassword: string;
};

export type LoginSecondStepType = {
  tokenValue: string;
  emailPassword: string;
};

export type UserIdType = {
  userId: number;
}

export type UserBoolType = {
    val: boolean;
}

export const getInstanceProms = () =>
    ky.get(`Instances/getInstanceProms`);

export const login = ({ email, password }: LoginType) =>
  ky.post(`Login`, {
    json: {
      email,
      password,
      crossDomain: true,
      xhrFields: {
        withCredentials: true,
      },
    },
  });

export const logout = () => ky.get(`Login/logout`);

export const sendForgotPasswordEmail = ({ email }: EmailType) =>
  ky.post(`Login/ForgotPassword`, {
    json: {
      email,
    },
  });

export const sendResetPasswordRequestKY = ({
  token,
  bit,
  newPassword,
  confirmPassword,
}: ResetPasswordTypes) =>
  ky.post(`resetPassword`, {
    json: {
      token,
      bit,
      newPassword,
      confirmPassword,
    },
  });

export const loginSecondStep = ({ 
  tokenValue, 
  emailPassword 
}: LoginSecondStepType) =>
  ky.post(`Login/SecondStepLogin`, {
    json: {
      tokenValue, 
      emailPassword 
    },
  });

export const getAllUserRights = () =>
    ky.get(`Login/getAllUserRights`);

export const loginAs = ({ userId }: UserIdType) =>
  ky.get(`Login/loginAs?userId=${userId}`);

export const loginBack = () =>
    ky.get(`Login/loginBack`);

export const clearLoginBack = () =>
    ky.get(`Login/clearLoginBack`);

export const getForResponsibility = () =>
    ky.get(`Users/getForResponsibility`);

export const getIsIpBlocked = ({ val }: UserBoolType) =>
    ky.get(`Users/getIsIpBlocked?log=${val}`);

export const getRememberFilters = () =>
    ky.get(`Users/getRememberFilters`);