import { thunk, Thunk, Action, action } from "easy-peasy";

import {
  ActionsPropsType,
  getActions,
  ActionIdType,
  getActionById,
  deleteAction,
  ActionType,
  TestingCodeType,
  AddActionPropsType,
  addAction,
  updateAction,
  getOtherActionsTestingCode,
    getOtherActionsActionId,
    getActionsExport
} from "./actions.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

export type ActionsListType = {
  currentPage: number,
  totalPages: number,
  pageSize: number,
  totalCount: number,
  count: number,
  items: ActionItem[],
  hasPrevious: boolean,
  hasNext: boolean
};

export type ActionItem = {
  id: number,
    madeByUserName: string,
    madeByUserDel: boolean,
    assignedToUserName: number,
    assignedToUserDel: boolean,
  madeOn: Date,
  actionStatusName: string,
  subtrajectoryName: string,
  trajectoryName: string,
  value: string,
  status?: boolean
};

export type OtherAction = {
  id: number,
  actionTypeName: string,
  assignedToUserName: string,
  madeByUserName: string,
  madeOn: string,
  reaction: string,
    status: boolean,
  statusName: string,
  value: string
};

export type ActionsModel = {
  error: ErrorEntry;

  setError: Action<ActionsModel, ErrorEntry>;
  delError: Action<ActionsModel>;

  getActions: Thunk<ActionsModel, ActionsPropsType>;
  getActionById: Thunk<ActionsModel, ActionIdType>;
  deleteAction: Thunk<ActionsModel, ActionIdType>;
  addAction: Thunk<ActionsModel, AddActionPropsType>;
  updateAction: Thunk<ActionsModel, ActionType>;
  getOtherActionsForTestingCode: Thunk<ActionsModel, TestingCodeType>;
    getOtherActionsForActionId: Thunk<ActionsModel, ActionIdType>;
    getActionsExport: Thunk<ActionsModel, ActionsPropsType>;
};

const actions: ActionsModel = {
  error: { code: 0 },

  setError: action((state, payload) => {
    console.log({ payload })
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),

  getActions: thunk(async (actions, payload) => {
    return fetchResponse<ActionsListType>({
      actions,
      payload,
      request: getActions,
    });
  }),
  getActionById: thunk(async (actions, payload) => {
    return fetchResponse<ActionType>({
      actions,
      payload,
      request: getActionById,
    });
  }),
  deleteAction: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: deleteAction,
      message: "Action has been deleted",
    });
  }),
  addAction: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: addAction,
      message: "Action has been added",
    });
  }),
  updateAction: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: updateAction,
      message: "Action has been edited",
    });
  }),
  getOtherActionsForTestingCode: thunk(async (actions, payload) => {
    return fetchResponse<OtherAction[]>({
      actions,
      payload,
      request: getOtherActionsTestingCode,
    });
  }),
  getOtherActionsForActionId: thunk(async (actions, payload) => {
    return fetchResponse<OtherAction[]>({
      actions,
      payload,
      request: getOtherActionsActionId,
    });
  }),
    getActionsExport: thunk(async (actions, payload) => {
        try {
            const response = await getActionsExport(payload);
            return response;
        } catch (error) {
            console.log(error);
        }
    }),
};

export default actions;
