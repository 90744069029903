import { thunk, Thunk, Action, action } from "easy-peasy";

import {
    ReportsParams,
    ReportsParamsIds,
    ReportsParamsIdsNps,
    ReportsParamsNumber,
    ReportListParams,
    getParticipationReport,
    getComparisonReport,
    getNotificationsReport,
    getMonthsReport,
    getDeletedReport,
    getDeletedReportName,
    getDeletedReportDetails,
    ReportCustomParams,
    getReportCustomList,
    IdReportType,
    deleteReportCustom,
    IdLangReportType,
    getQuestionsForReportCustom,
    AddReportCustomParams,
    addReportCustom,
    getOneReportCustom,
    editReportCustom,
    getReportQuestionList,
    deleteReportQuestion,
    getListOfTrajectsForQuestion,
    AddReportQuestionParams,
    addReportQuestion,
    getOneReportQuestion,
    editReportQuestion,
    getQuestionaires,
    GetDistributionReportParams,
    getDistributionReport,
    GetReportDistributionDetailsParams,
    getReportDistributionDetailsList,
    getReportExcelList,
    deleteReportExcel,
    getListOfTrajectsForExcel,
    AddReportExcelParams,
    addReportExcel,
    AddReportCustomChartType,
    getReportQuestionWord,
    GetReportQuestionWordParams
} from "./reports.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic, fetchFile } from "../../helpers/fetching";
import { getCurrentDateYear, getLastYear } from "../../helpers/utils";

export type ResponsibilitiesListItem = {
    evaluationMomentName: string,
    percentResponse?: number | null | undefined,
    invited: number,
    anonymous: number,
    completedNotAnonymous: number,
    completedAnonymous: number,
    pending: number,
    missing: number
};

export type ComparisonStatisticsListItem = {
    trajectoryName: string,
    subtrajectoryName: string,
    visits: number,
    anonymous: number,
    notAnonymous: number,
    invited: number,
    percentOfResponse: number,
    reportGrade: number,
    nps: number,
    percentOfPromoters: number,
    percentOfCritics: number,
    compliments: number,
    pointOfImprovement: number,
    percentOfCompliments: number,
    percentOfPointOfImprovement: number
};

export type NotificationListItem = {
    trajectoryName: string,
    subtrajectoryName: string,
    allNotifications: number,
    openedNotifications: number,
    readNotifications: number,
    daysToOpenNotifications: number,
    allActions: number,
    openedActions: number,
    completedActions: number,
    daysToCompletedActions: number,
    complimentLabels: number,
    improvementLabels: number
};

export type ValueForSubtrajectory = {
    subtrajectoryId: number,
    subtrajectoryName: string,
    trajectoryName: string,
    value?: number | undefined | null,
    count?: number | undefined | null,
    isPercent: boolean
};

export type MonthlyComparisonStatistics = {
    month: number,
    year: number,
    valuesForSubtrajectories: ValueForSubtrajectory[]
};

export type NumberOfDeletedByDeletedReason = {
    deletedReasonName: string,
    deletedReasonId: number,
    count: number
};

export type RemovalStatistics = {
    total: number,
    deleted: number,
    numbersOfRemovalByDeletedReason: NumberOfDeletedByDeletedReason[]
};

export type DeletedReasonValue = {
    id: number,
    name: string
}

export type DeletedDetailsItem = {
    testingId: number,
    operationNumber: string,
    email: string,
    patientNumber: string,
    operationDate: string
}

export interface DeletedDetailsList {
    currentPage: number,
    totalPages: number,
    pageSize: number,
    totalCount: number,
    count: number,
    items: DeletedDetailsItem[],
    hasPrevious: boolean,
    hasNext: boolean
};

export interface ReportCustomList {
    currentPage: number,
    totalPages: number,
    pageSize: number,
    totalCount: number,
    count: number,
    items: ReportCustomItem[],
    hasPrevious: boolean,
    hasNext: boolean
};

export type ReportCustomItem = {
    id: number,
    title: string,
    settings: string
};

export type ReportCustomQuestionaireFull = {
    questionaires: ReportCustomQuestionaire[],
    questionsIds: number[],
    questionChartTypes: AddReportCustomChartType[]
};

export type ReportCustomQuestionaire = {
    id: number,
    title: string,
    categories: ReportCustomCategory[],
    questionsIds: number[]
};

export type ReportCustomCategory = {
    id: number,
    title: string,
    questions: ReportCustomQuestion[],
    questionsIds: number[],
    active: number
};

export type ReportCustomQuestion = {
    id: number,
    title: string,
    answers: ReportCustomAnswer[]
};

export type ReportCustomAnswer = {
    id: number,
    title: string
};

export type ReportIdNameItem = {
    id: number,
    name: string
};

export type DistributionReportAnswerItem = {
    answerId: number,
    answerText: string,
    min: number,
    max: number,
    answersCount: number,
    answersPercent: number
}

export type DistributionReportItem = {
    questionId: number,
    questionText: string,
    questionType: number,
    answersCount: number,
    answers: DistributionReportAnswerItem[]
}

export type ReportDistributionDetailsItem = {
    testingId: number,
    testingCode: string,
    operationNumber: string,
    patientNumber: string,
    answerText: string
}

export interface ReportDistributionDetailsList {
    currentPage: number,
    totalPages: number,
    pageSize: number,
    totalCount: number,
    count: number,
    items: ReportDistributionDetailsItem[],
    hasPrevious: boolean,
    hasNext: boolean
};

export interface ReportExcelList {
    currentPage: number,
    totalPages: number,
    pageSize: number,
    totalCount: number,
    count: number,
    items: ReportExcelItem[],
    hasPrevious: boolean,
    hasNext: boolean
};

export type ReportExcelItem = {
    id: number,
    operationsNumber: number,
    tokenId: string,
    user: string,
    created: boolean,
    name: string
};

export type ReportExcelTrajectsFull = {
    trajects: ReportExcelTraject[],
    subtrajectsIds: number[]
};

export type ReportExcelTraject = {
    id: number,
    title: string,
    subtrajects: ReportExcelSubtraject[],
    subtrajectsIds: number[]
};

export type ReportExcelSubtraject = {
    id: number,
    title: string
};

export type ReportQuestionTrajectsFull = {
    trajects: ReportQuestionTraject[],
    evaluationsIds: number[]
};

export type ReportQuestionTraject = {
    id: number,
    title: string,
    subtrajects: ReportQuestionSubtraject[],
    evaluationsIds: number[]
};

export type ReportQuestionSubtraject = {
    id: number,
    title: string
    evaluations: ReportQuestionEvaluation[],
    evaluationsIds: number[]
};

export type ReportQuestionEvaluation = {
    id: number,
    title: string
};

export type ReportDistributionSelection = {
    dateFrom: string,
    dateTo: string,
    trajectId: number,
    subtrajectId: number,
    optionId: number,
    evalMoments: number[],
    questId: number
};

export type ReportsModel = {
    error: ErrorEntry,
    setError: Action<ReportsModel, ErrorEntry>,
    delError: Action<ReportsModel>,
    selDistReport: ReportDistributionSelection,
    setSelDistReport: Action<ReportsModel, ReportDistributionSelection>,
    getParticipationReport: Thunk<ReportsModel, ReportsParams>,
    getComparisonReport: Thunk<ReportsModel, ReportsParamsIds>,
    getNotificationsReport: Thunk<ReportsModel, ReportsParamsIds>,
    getMonthsReport: Thunk<ReportsModel, ReportsParamsIdsNps>,
    getDeletedReport: Thunk<ReportsModel>,
    getDeletedReportName: Thunk<ReportsModel, ReportsParamsNumber>,
    getDeletedReportDetails: Thunk<ReportsModel, ReportListParams>,
    getReportCustomList: Thunk<ReportsModel, ReportCustomParams>;
    deleteReportCustom: Thunk<ReportsModel, IdReportType>;
    getQuestionsForReportCustom: Thunk<ReportsModel, IdLangReportType>;
    addReportCustom: Thunk<ReportsModel, AddReportCustomParams>;
    getOneReportCustom: Thunk<ReportsModel, IdReportType>;
    editReportCustom: Thunk<ReportsModel, AddReportCustomParams>;
    getReportQuestionList: Thunk<ReportsModel, ReportCustomParams>;
    deleteReportQuestion: Thunk<ReportsModel, IdReportType>;
    getListOfTrajectsForQuestion: Thunk<ReportsModel>;
    addReportQuestion: Thunk<ReportsModel, AddReportQuestionParams>;
    getOneReportQuestion: Thunk<ReportsModel, IdReportType>;
    editReportQuestion: Thunk<ReportsModel, AddReportQuestionParams>;
    getQuestionaires: Thunk<ReportsModel, IdReportType>;
    getDistributionReport: Thunk<ReportsModel, GetDistributionReportParams>;
    getReportDistributionDetailsList: Thunk<ReportsModel, GetReportDistributionDetailsParams>,
    getReportExcelList: Thunk<ReportsModel, ReportCustomParams>;
    deleteReportExcel: Thunk<ReportsModel, IdReportType>;
    getListOfTrajectsForExcel: Thunk<ReportsModel>;
    addReportExcel: Thunk<ReportsModel, AddReportExcelParams>;
    getReportQuestionWord: Thunk<ReportsModel, GetReportQuestionWordParams>;
};

const reports: ReportsModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    selDistReport: {
        dateFrom: getLastYear(),
        dateTo: getCurrentDateYear(),
        trajectId: 0,
        subtrajectId: 0,
        optionId: 0,
        evalMoments: [],
        questId: 0
    },
    setSelDistReport: action((state, payload) => {
        state.selDistReport = payload;
    }),
    getParticipationReport: thunk(async (actions, payload) => {
        return fetchResponse<ResponsibilitiesListItem[]>({
            actions,
            payload,
            request: getParticipationReport,
        });
    }),
    getComparisonReport: thunk(async (actions, payload) => {
        return fetchResponse<ComparisonStatisticsListItem[]>({
            actions,
            payload,
            request: getComparisonReport,
        });
    }),
    getNotificationsReport: thunk(async (actions, payload) => {
        return fetchResponse<NotificationListItem[]>({
            actions,
            payload,
            request: getNotificationsReport,
        });
    }),
    getMonthsReport: thunk(async (actions, payload) => {
        return fetchResponse<MonthlyComparisonStatistics[]>({
            actions,
            payload,
            request: getMonthsReport,
        });
    }),
    getDeletedReport: thunk(async (actions) => {
        return fetchResponse<RemovalStatistics>({
            actions,
            request: getDeletedReport,
        });
    }),
    getDeletedReportName: thunk(async (actions, payload) => {
        return fetchResponse<DeletedReasonValue>({
            actions,
            payload,
            request: getDeletedReportName,
        });
    }),
    getDeletedReportDetails: thunk(async (actions, payload) => {
        return fetchResponse<DeletedDetailsList>({
            actions,
            payload,
            request: getDeletedReportDetails,
        });
    }),
    getReportCustomList: thunk(async (actions, payload) => {
        return fetchResponse<ReportCustomList>({
            actions,
            payload,
            request: getReportCustomList,
        });
    }),
    deleteReportCustom: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteReportCustom,
            message: "Custom report has been deleted",
        });
    }),
    getQuestionsForReportCustom: thunk(async (actions, payload) => {
        return fetchResponse<ReportCustomQuestionaireFull>({
            actions,
            payload,
            request: getQuestionsForReportCustom,
        });
    }),
    addReportCustom: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addReportCustom,
            message: "Custom report has been created",
        });
    }),
    getOneReportCustom: thunk(async (actions, payload) => {
        return fetchResponse<AddReportCustomParams>({
            actions,
            payload,
            request: getOneReportCustom,
        });
    }),
    editReportCustom: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: editReportCustom,
            message: "Custom report has been updated",
        });
    }),
    getReportQuestionList: thunk(async (actions, payload) => {
        return fetchResponse<ReportCustomList>({
            actions,
            payload,
            request: getReportQuestionList,
        });
    }),
    deleteReportQuestion: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteReportQuestion,
            message: "Question report has been deleted",
        });
    }),
    getListOfTrajectsForQuestion: thunk(async (actions) => {
        return fetchResponse<ReportQuestionTrajectsFull>({
            actions,
            request: getListOfTrajectsForQuestion,
        });
    }),
    addReportQuestion: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addReportQuestion,
            message: "Questions report has been created",
        });
    }),
    getOneReportQuestion: thunk(async (actions, payload) => {
        return fetchResponse<AddReportQuestionParams>({
            actions,
            payload,
            request: getOneReportQuestion,
        });
    }),
    editReportQuestion: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: editReportQuestion,
            message: "Question report has been updated",
        });
    }),
    getReportQuestionWord: thunk(async (actions, payload) => {
        return fetchFile({
            actions,
            payload,
            request: getReportQuestionWord,
        });
    }),
    getQuestionaires: thunk(async (actions, payload) => {
        return fetchResponse<ReportIdNameItem[]>({
            actions,
            payload,
            request: getQuestionaires,
        });
    }),
    getDistributionReport: thunk(async (actions, payload) => {
        return fetchResponse<DistributionReportItem[]>({
            actions,
            payload,
            request: getDistributionReport,
        });
    }),
    getReportDistributionDetailsList: thunk(async (actions, payload) => {
        return fetchResponse<ReportDistributionDetailsList>({
            actions,
            payload,
            request: getReportDistributionDetailsList,
        });
    }),
    getReportExcelList: thunk(async (actions, payload) => {
        return fetchResponse<ReportExcelList>({
            actions,
            payload,
            request: getReportExcelList,
        });
    }),
    deleteReportExcel: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteReportExcel,
            message: "Excel report has been deleted",
        });
    }),
    getListOfTrajectsForExcel: thunk(async (actions) => {
        return fetchResponse<ReportExcelTrajectsFull>({
            actions,
            request: getListOfTrajectsForExcel,
        });
    }),
    addReportExcel: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addReportExcel,
            message: "Excel report has been created",
        });
    }),
};

export default reports;
