import { thunk, Thunk, Action, action } from "easy-peasy";

import {
    getContent,
    ContentListType,
    editContent,
    KeywordType,
    setKeyword,
    EditContentType,
    updateContent,
    UpdateContentModel,
    deleteKey,
    DeleteCodeType,
} from "./content.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

export interface ContentList {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    count: number;
    items: ContentItem[];
    hasPrevious: boolean;
    hasNext: boolean;
}

export type ContentItem = {
    contentId: number;
    languageId: number;
    instanceId: number;
    value: string;
    contentName: string;
    contentType: number;
    translationOverride: boolean;
};

export type ContentModel = {
    getContent: Thunk<ContentModel, ContentListType>;
    editContent: Thunk<ContentModel, EditContentType>;
    updateContent: Thunk<ContentModel, UpdateContentModel>;
    setKeyword: Thunk<ContentModel, KeywordType>;
    error: ErrorEntry;
    setError: Action<ContentModel, ErrorEntry>;
    delError: Action<ContentModel>;
    deleteKey: Thunk<ContentModel, DeleteCodeType>;
};

const content: ContentModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getContent: thunk(async (actions, payload) => {
        return fetchResponse<ContentList>({
            actions,
            payload,
            request: getContent,
        });
    }),
    editContent: thunk(async (actions, payload) => {
        return fetchResponse<ContentItem>({
            actions,
            payload,
            request: editContent,
        });
    }),
    updateContent: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateContent,
            message: "Content has been edited",
        });
    }),
    setKeyword: thunk(async (actions, payload) => {
        return fetchBasic({
          actions,
          payload,
          request: setKeyword,
          message: "Keyword has been added",
        });
      }),
    deleteKey: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteKey,
            message: "Keyword has been removed",
        });
    }),
};

export default content;
