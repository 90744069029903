import ky from "../ky";

export type ImagesListType = {
  currentPageNo: number;
  query: string;
  sortCol: string;
  isDescending: boolean;
  pageSize?: number;
};

export const getImagesList = ({
  currentPageNo,
  query,
  sortCol,
  isDescending,
  pageSize = 10
}: ImagesListType) =>
  ky.get(
    `Images?PageNumber=${currentPageNo}&SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=${pageSize}`
  );

export const getImagesL = () => ky.get(`Images?PageNumber=0&SearchWord=&OrderBy=&IsDescending=false&PageSize=10`);

export type IdImagesType = {
  id?: string | number;
};

export type TagImagesType = {
  tagName?: string;
};

export const deleteImages = ({ id }: IdImagesType) =>
  ky.delete(`Images?id=${id}`);

export type ImagesType = {
  id?: number;
  tag?: string;
  tagName?: string;
  title?: string;
  data: FormData;
};

export const addImages = ({ tag, title, data }: ImagesType) =>
  ky.post(`Images/add?tagName=${tag}&title=${title}`, { body: data });

export const updateImages = ({ tag, id, title, data }: ImagesType) =>
  ky.put(`Images?tagName=${tag}&tagId=${id}&title=${title}`, { body: data });

export const getImageTag = ({ id }: IdImagesType) => ky.get(`Images/${id}`);

export const getImage = async ({ id }: IdImagesType) => {
    const resp = await ky.get(`Images/image?tagId=${id}`, { timeout: 30000 });
  const blob = resp.blob();
  return blob;
};

export const getImageByTag = async ({ tagName }: TagImagesType) => {
  const resp = await ky.get(`Images/byTagName/image?tagName=${tagName}`);
  const blob = resp.blob();
  return blob;
};

export const getOptionalImage = async ({ id }: IdImagesType) => {
  const resp = await ky.get(`Images/optionalImage?tagId=${id}`);
  const blob = resp.blob();
  return blob;
};

export const delImage = ({ id }: IdImagesType) =>
  ky.delete(`Images/image?tagId=${id}`);

export const delOpitonalImage = ({ id }: IdImagesType) =>
  ky.delete(`Images/optionalImage?tagId=${id}`);
