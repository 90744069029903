import ky from "../ky";

export type ContentListType = {
  currentPageNo: number;
  query: string;
  sortCol: string;
  isDescending: boolean;
  languageCode: string;
};

export const getContent = ({
  currentPageNo,
  query,
  sortCol,
  isDescending,
  languageCode,
}: ContentListType) =>
  ky.get(
    `Content/all?PageNumber=${currentPageNo}&SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10&languageCode=${languageCode}`
  );

export type EditContentType = {
  contentId?: string;
  instanceId?: string;
  languageId?: string;
};

export const editContent = ({
  contentId,
  instanceId,
  languageId,
}: EditContentType) =>
  ky.get(
    `Content?contentId=${contentId}&instanceId=${instanceId}&languageId=${languageId}`
  );

export type UpdateContentModel = {
  languageId: number;
  contentId: number;
  value: string;
};

export const updateContent = ({ languageId, contentId, value }: UpdateContentModel) =>
  ky.put(`Content/?languageId=${languageId}`, {
    json: { contentId, value },
  });

export type DeleteCodeType = {
    codeId: number;
};

export const deleteKey = ({ codeId }: DeleteCodeType) =>
    ky.delete(`Content/delete?id=${codeId}`);


export const setKeyword = ({ keyName, keyValue, enValue }: KeywordType) => 
  ky.post(`Content/add`, {
    json: {
      keyValue: keyName,
      nlValue: keyValue,
      engValue: enValue
    }
});

export type KeywordType = {
  keyName: string;
  keyValue: string;
  enValue: string;
};
