import { thunk, Thunk, Action, action } from "easy-peasy";
import {
  getKeywordsList,
  KeywordsListType,
  setKeyword,
  KeywordType,
  getEditCode,
  EditCodeType,
  deleteKey,
  DeleteCodeType,
  updateKey,
  UpdateKeyType,
  editKeyValue,
  EditKeyValueType
} from "./keywords.service";
import { ErrorEntry } from "../users/users.model";
import { fetchBasic, fetchResponse } from "../../helpers/fetching";

export interface TransList {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  count: number;
  items: TransItem[];
  hasPrevious: boolean;
  hasNext: boolean;
}

export type TransItem = {
  translationId: number;
  languageId: number;
  codeId: string;
  defaultNlValue: string;
  value: string;
  key: string;
};

interface TransKeyEntry {
  id: number;
  code: string;
}

export type KeywordsModel = {
  getKeywordsList: Thunk<KeywordsModel, KeywordsListType>;
  setKeyword: Thunk<KeywordsModel, KeywordType>;
  getEditCode: Thunk<KeywordsModel, EditCodeType>;
  deleteKey: Thunk<KeywordsModel, DeleteCodeType>;
  updateKey: Thunk<KeywordsModel, UpdateKeyType>;
  editKeyValue: Thunk<KeywordsModel, EditKeyValueType>;
  error: ErrorEntry;
  setError: Action<KeywordsModel, ErrorEntry>;
  delError: Action<KeywordsModel>;
};

const keywordsList: KeywordsModel = {
  error: { code: 0 },
  setError: action((state, payload) => {
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),
  getKeywordsList: thunk(async (actions, payload) => {
    return fetchResponse<TransList>({
      actions,
      payload,
      request: getKeywordsList,
    });
  }),
  setKeyword: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: setKeyword,
      message: "Keyword has been added",
    });
  }),
  getEditCode: thunk(async (actions, payload) => {
    return fetchResponse<TransKeyEntry>({
      actions,
      payload,
      request: getEditCode,
    });
  }),
  deleteKey: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: deleteKey,
      message: "Keyword has been removed",
    });
  }),
  updateKey: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: updateKey,
      message: "Keyword has been edited",
    });
  }),
  editKeyValue: thunk(async (actions, payload) => {
    return fetchBasic({
      actions,
      payload,
      request: editKeyValue,
      message: "Value has been added",
    });
  })
};

export default keywordsList;
