import { thunk, Thunk } from "easy-peasy";
import { getTranslations } from "./translations.service";

export type ResourcesModel = {
  [key: string]: {
    translation: {};
  };
};

export type TranslationsModel = {
  getTranslations: Thunk<TranslationsModel>;
};

const translations: TranslationsModel = {
  getTranslations: thunk(async () => {
    try {
      const response = (await getTranslations().json()) as ResourcesModel;
      return response;
    } catch (error) {
      console.error(error);
    }
  }),
};

export default translations;
