import { thunk, Thunk, Action, action } from "easy-peasy";

import {
    LabelListType,
    LabelType,
    IdLabelType,
    addLabel,
    deleteLabel,
    getLabelList,
    getLabelL,
    getOneLabel,
    updateLabel,
    getAllLabels,
    TestingCodeType,
    getLabelsForCode,
    getLabelsDataForCodeSingle,
    TestingCodeSingleType,
    getLabelsDataForCode,
    UpdateLabelsProps,
    UpdateLabelsPropsSingle,
    updateLabels,
    updateLabelsSingle,
    ImportType,
    importData,
    ImportFinalType,
    importDataFinal,
    setFavourite,
    LabelFavouriteParam
} from "./labels.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

export type LabelList = {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    count: number;
    items: LabelItem[];
    hasPrevious: boolean;
    hasNext: boolean;
};

export type LabelItem = {
    id: number;
    title: string;
    favourite: boolean;
};

export type LabelData = {
    id?: number,
    labelId: number,
    type: number,
    isAI: boolean,
};

export type LabelToShowResponse = {
    operationId: number,
    testingCode: string,
    compliments: string[];
    improvements: string[];
    complimentsAi: string[];
    improvementsAi: string[];
};

export type ColumnName = {
    key: string,
    val: string
};

export type ImportData = {
    columnNames: ColumnName[],
    fileData: string[][],
    languageCode: string
};

export type ImportResult = {
    totalRowsCount: number,
    processedRowsCount: number,
    ignoredRowsCount: number,
    successedRowsCount: number,
    wrongRowsCount: number,
    addedRowsCount: number,
    updatedRowsCount: number,
    errors: ColumnName[]
};

export type LabelModel = {
    error: ErrorEntry;

    setError: Action<LabelModel, ErrorEntry>;
    delError: Action<LabelModel>;

    getLabelList: Thunk<LabelModel, LabelListType>;
    deleteLabel: Thunk<LabelModel, IdLabelType>;
    addLabel: Thunk<LabelModel, LabelType>;
    getOneLabel: Thunk<LabelModel, IdLabelType>;
    updateLabel: Thunk<LabelModel, LabelType>;
    getLabelL: Thunk<LabelModel>;
    getAllLabels: Thunk<LabelModel>;
    getLabelsToShow: Thunk<LabelModel, TestingCodeType>;
    getLabelsDataForCodeSingle: Thunk<LabelModel, TestingCodeSingleType>;
    getLabelsDataForCode: Thunk<LabelModel, TestingCodeType>;
    updateLabels: Thunk<LabelModel, UpdateLabelsProps>;
    updateLabelsSingle: Thunk<LabelModel, UpdateLabelsPropsSingle>;
    importData: Thunk<LabelModel, ImportType>;
    importDataFinal: Thunk<LabelModel, ImportFinalType>;
    setFavourite: Thunk<LabelModel, LabelFavouriteParam>;
};

const Label: LabelModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getLabelList: thunk(async (actions, payload) => {
        return fetchResponse<LabelList>({
            actions,
            payload,
            request: getLabelList,
        });
    }),
    getLabelL: thunk(async (actions) => {
        return fetchResponse<LabelList>({
            actions,
            request: getLabelL,
        });
    }),
    deleteLabel: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteLabel,
            message: "Label has been deleted",
        });
    }),
    addLabel: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addLabel,
            message: "Label has been added",
        });
    }),
    getOneLabel: thunk(async (actions, payload) => {
        return fetchResponse<LabelItem>({
            actions,
            payload,
            request: getOneLabel,
        });
    }),
    updateLabel: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateLabel,
            message: "Label has been edited",
        });
    }),
    getAllLabels: thunk(async (actions) => {
        return fetchResponse<LabelItem[]>({
            actions,
            request: getAllLabels,
        });
    }),
    getLabelsToShow: thunk(async (actions, payload) => {
        return fetchResponse<LabelToShowResponse>({
            actions,
            payload,
            request: getLabelsForCode,
        });
    }),
    getLabelsDataForCodeSingle: thunk(async (actions, payload) => {
        return fetchResponse<LabelData[]>({
            actions,
            payload,
            request: getLabelsDataForCodeSingle,
        });
    }),
    getLabelsDataForCode: thunk(async (actions, payload) => {
        return fetchResponse<LabelData[]>({
            actions,
            payload,
            request: getLabelsDataForCode,
        });
    }),
    updateLabels: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateLabels,
            message: "Labels have been updated",
        });
    }),
    updateLabelsSingle: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateLabelsSingle,
            message: "Labels have been updated",
        });
    }),
    importData: thunk(async (actions, payload) => {
        return fetchResponse<ImportData>({
            actions,
            payload,
            request: importData
        });
    }),
    importDataFinal: thunk(async (actions, payload) => {
        return fetchResponse<ImportResult>({
            actions,
            payload,
            request: importDataFinal
        });
    }),
    setFavourite: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: setFavourite,
            message: "Label updated",
        });
    }),
};

export default Label;