import ky from "../ky";

export type KeywordsListType = {
  currentLang: string;
  currentPageNo: number;
  query: string;
  sortCol: string;
  isDescending: boolean;
};

export const getKeywordsList = ({
  currentLang,
  currentPageNo,
  query,
  sortCol,
  isDescending
}: KeywordsListType) =>
  ky.get(
    `Translations/all/${currentLang}?PageNumber=${currentPageNo}&SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10`
  );

export type KeywordType = {
  keyName: string;
  keyValue: string;
  enValue: string;
};

export const setKeyword = ({ keyName, keyValue, enValue }: KeywordType) => 
  ky.post(`Language/translationCode/addWithEng`, {
    json: {
      keyValue: keyName,
      nlValue: keyValue,
      engValue: enValue,
      crossDomain: true,
      xhrFields: {
        withCredentials: true
      }
    }
  });
;

export type EditCodeType = {
  id?: string;
};

export const getEditCode = ({ id }: EditCodeType) =>
  ky.get(`Language/translationCode/${id}`);

export type DeleteCodeType = {
  codeId: string;
};

export const deleteKey = ({ codeId }: DeleteCodeType) =>
  ky.delete(`Language/translationCode/delete?id=${codeId}`);

export type UpdateKeyType = {
  codeId: string;
  code: string;
};

export const updateKey = ({ codeId, code }: UpdateKeyType) =>
  ky.post(`Language/translationCode/edit`, {
    json: {
      id: codeId,
      code,
      deleted: false,
      crossDomain: true,
      xhrFields: {
        withCredentials: true
      }
    }
  });

export type EditKeyValueType = {
  translationId: number;
  languageId: number;
  codeId: string;
  defaultNlValue: string;
  transValue: string;
  key: string;
};

export const editKeyValue = ({
  key,
  transValue,
  defaultNlValue,
  codeId,
  languageId,
  translationId
}: EditKeyValueType) =>
  ky.post(`Translations/edit`, {
    json: {
      key,
      defaultNlValue,
      translationId,
      languageId,
      codeId,
      value: transValue,
      crossDomain: true,
      xhrFields: {
        withCredentials: true
      }
    }
  });
