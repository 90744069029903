import ky from "../ky";

export type TrajectoriesListType = {
  active: boolean | string;
  currentPageNo: number;
  query: string;
  sortCol: string;
  isDescending: boolean;
};

export const getTrajectoriesList = ({
  active,
  currentPageNo,
  query,
  sortCol,
  isDescending,
}: TrajectoriesListType) =>
  ky.get(
    `Trajectories?Active=${active}&PageNumber=${currentPageNo}&SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10`
  );

export type IdTrajectoriesType = {
  id?: string | number;
};

export const deleteTrajectories = ({ id }: IdTrajectoriesType) =>
  ky.delete(`Trajectories?id=${id}`);

export type TrajectoriesType = {
  id?: number;
  instanceId?: number;
  title: string;
  active: boolean;
  code: string;
  position?: number;
};

export const addTrajectories = ({ title, active, code }: TrajectoriesType) =>
  ky.post(`Trajectories/add`, { json: { title, active, code } });

export const updateTrajectories = ({
  title,
  id,
  active,
  code,
}: TrajectoriesType) =>
  ky.put(`Trajectories`, { json: { title, id, active, code } });

export const getOneTrajectories = ({ id }: IdTrajectoriesType) =>
  ky.get(`Trajectories/${id}`);

export type CopyType = {
  trajectoryToCopyId: number;
  instanceWhereToCopyId: number;
};

export const copyTrajectory = ({
  trajectoryToCopyId,
  instanceWhereToCopyId,
}: CopyType) =>
  ky.post(
    `Trajectories/copy?trajectoryToCopyId=${trajectoryToCopyId}&instanceWhereToCopyId=${instanceWhereToCopyId}`
  );

export type IdsType = {
  subtrajectoryId?: number;
  evaluationMomentId?: number;
  responsibilityId?: number;
  communicationMomentId?: number;
};

export const getPathForSubtrajectory = ({ subtrajectoryId }: IdsType) =>
  ky.get(`Trajectories/path/subtrajectoryId/${subtrajectoryId}`);

export const getPathForEvaluationMom = ({ evaluationMomentId }: IdsType) =>
  ky.get(`Trajectories/path/evaluationMomentId/${evaluationMomentId}`);

export const getPathForResponsibility = ({ responsibilityId }: IdsType) =>
  ky.get(`Trajectories/path/responsibilityId/${responsibilityId}`);

export const getPathForCommunicationMom = ({
  communicationMomentId,
}: IdsType) =>
  ky.get(`Trajectories/path/communicationMomentId/${communicationMomentId}`);

export const getToShow = () => ky.get(`Trajectories/toShow`);
export const getToShowOnStart = () => ky.get(`Trajectories/toShowOnStart`);
