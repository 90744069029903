import { ThemeConfig } from './types';

export const h8: ThemeConfig = {
    tenant: 'h8',
    baseColor: '#424450',
    navColor: '#ffffff',
    textColor: '#444',
    hoverColor: '#65687a',
    disabled: '#999999',
    inputBgColor: "#FBFBFC",
    matrixHoverColor: '#65687a',
    matrixHoverTextColor: '#444',
    matrixOddBgColor: '#FBFBFC',
    matrixOddTextColor: '#444'
};

export default h8;