import React from 'react';
import { useTranslation } from "react-i18next";


import { Section, NavLink, Wrapper} from './style';
import { ChangeFontSizeButtons } from '../../change-font-size/ChangeFontSize'
import { ChangeLanguageSelect } from '../../change-language/ChangeLanguageSelect';

export const MainNav:React.FC = () => {
  const  { t } = useTranslation();

return (<Wrapper>
    <Section>
        <NavLink to="/">{t("front_start_header")}</NavLink>
        <NavLink to="/register">{t("front_aanmelden_header")}</NavLink>
    </Section>
    <Section>
        <ChangeFontSizeButtons />
        <ChangeLanguageSelect />
    </Section>
  </Wrapper>)

}

