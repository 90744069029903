import ky from "../ky";

import { BasicUserGroupModel } from "../../pages/portal/usergroups/model";

export type UserGroupListType = {
  currentPageNo: number;
  query: string;
  sortCol: string;
  isDescending: boolean;
};

export const getUserGroup = ({
  currentPageNo,
  query,
  sortCol,
  isDescending,
}: UserGroupListType) =>
  ky.get(
    `UserGroups?PageNumber=${currentPageNo}&SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10`
  );

export type DeleteUserGroupType = {
  id: number;
};

export const deleteUserGroup = ({ id }: DeleteUserGroupType) =>
  ky.delete(`UserGroups/${id}`);

export type CopyUserGroupType = {
  id: number;
};

export type Token = {
    token: string;
};

export const copyUserGroup = ({ id }: CopyUserGroupType) =>
  ky.post(`UserGroups/copy/${id}`);

export const getBasicUserGroup = () => ky.get(`UserGroups/getBasic`);

export const getPasswordRegex = () => ky.get(`UserGroups/getPasswordRegexps`);

export const getPasswordRegexForUser = () => ky.get(`UserGroups/getPasswordRegexForUser`);

export const getUserGroupForUser = () => ky.get(`UserGroups/getGroupForUser`);

export const getPasswordRegexWithToken = ({ token }: Token) => ky.get(`UserGroups/getPasswordRegexForUser?token=${token}`);

export const getUserGroupWithToken = ({ token }: Token) => ky.get(`UserGroups/getGroupForUser?token=${token}`);

export type EditGroupType = {
  id?: string;
};

export type SetFiltersGroupType = {
    id: number;
    toSet: boolean;
};

export const editGroup = ({ id }: EditGroupType) => ky.get(`UserGroups/${id}`);

export const addUserGroup = ({
  idPasswordRegex,
  usergroupIpRestrictionsDto,
  daylock,
  ipRestriction,
  name,
  passwordMinLength,
  passwordValidity,
  securityStyle,
  timelockFrom,
  timelockTo,
  userDto,
}: BasicUserGroupModel) =>
  ky.post(`UserGroups/add`, {
    json: {
      name,
      daylock,
      ipRestriction,
      idPasswordRegex,
      usergroupIpRestrictionsDto,
      passwordMinLength,
      passwordValidity,
      securityStyle,
      timelockFrom,
      timelockTo,
      userDto,
    },
  });

export const updateGroup = ({
  id,
  idInstance,
  idPasswordRegex,
  usergroupIpRestrictionsDto,
  daylock,
  ipRestriction,
  name,
  passwordMinLength,
  passwordValidity,
  securityStyle,
  timelockFrom,
  timelockTo,
  userDto,
}: BasicUserGroupModel) =>
  ky.put(`UserGroups/`, {
    json: {
      id,
      idInstance,
      name,
      daylock,
      ipRestriction,
      idPasswordRegex,
      usergroupIpRestrictionsDto,
      passwordMinLength,
      passwordValidity,
      securityStyle,
      timelockFrom,
      timelockTo,
      userDto,
    },
  });

export const getUserGroups = () => ky.get(`UserGroups/getUsergroupNamesToShow`);

export const getPermissions = () => ky.get(`Permissions`);

export const getGroupPermissions = ({ id }: EditGroupType) =>
  ky.get(`Permissions/${id}`);

export type PermListType = {
  id?: string;
  ids: Array<number>;
};

export const setGroupPermissions = ({ id, ids }: PermListType) =>
    ky.put(`UserGroups/permissions/?usergroupId=${id}`, { json: { ids } });

export const setFiltersGroup = ({ id, toSet }: SetFiltersGroupType) =>
    ky.post(`UserGroups/setFiltersGroup`, { json: { id: id, toSet: toSet } });
