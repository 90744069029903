import { thunk, Thunk, Action, action } from "easy-peasy";

import {
    ActionStatusListType,
    ActionStatusType,
    IdActionStatusType,
    addActionStatus,
    deleteActionStatus,
    getActionStatusList,
    getActionStatusL,
    getOneActionStatus,
    updateActionStatus,
    getForActions,
    ImportType,
    importData,
    ImportFinalType,
    importDataFinal
} from "./actionStatus.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

export type ActionList = {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    count: number;
    items: ActionItem[];
    hasPrevious: boolean;
    hasNext: boolean;
};

export type ActionItem = {
    id: number;
    title: string;
    isDefault?: boolean;
    isFinal?: boolean;
};

export type ColumnName = {
    key: string,
    val: string
};

export type ImportData = {
    columnNames: ColumnName[],
    fileData: string[][],
    languageCode: string
};

export type ImportResult = {
    totalRowsCount: number,
    processedRowsCount: number,
    ignoredRowsCount: number,
    successedRowsCount: number,
    wrongRowsCount: number,
    addedRowsCount: number,
    updatedRowsCount: number,
    errors: ColumnName[]
};

export type ActionStatusModel = {
    getActionStatusList: Thunk<ActionStatusModel, ActionStatusListType>;
    deleteActionStatus: Thunk<ActionStatusModel, IdActionStatusType>;
    addActionStatus: Thunk<ActionStatusModel, ActionStatusType>;
    getOneActionStatus: Thunk<ActionStatusModel, IdActionStatusType>;
    updateActionStatus: Thunk<ActionStatusModel, ActionStatusType>;
    getActionStatusL: Thunk<ActionStatusModel>;
    getStatusesForActions: Thunk<ActionStatusModel>;
    error: ErrorEntry;
    setError: Action<ActionStatusModel, ErrorEntry>;
    delError: Action<ActionStatusModel>;
    importData: Thunk<ActionStatusModel, ImportType>;
    importDataFinal: Thunk<ActionStatusModel, ImportFinalType>;
};

const actionStatus: ActionStatusModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getActionStatusList: thunk(async (actions, payload) => {
        return fetchResponse<ActionList>({
            actions,
            payload,
            request: getActionStatusList,
        });
    }),
    getActionStatusL: thunk(async (actions) => {
        return fetchResponse<ActionList>({
            actions,
            request: getActionStatusL,
        });
    }),
    deleteActionStatus: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteActionStatus,
            message: "Action status has been deleted",
        });
    }),
    addActionStatus: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addActionStatus,
            message: "Action status has been added",
        });
    }),
    getOneActionStatus: thunk(async (actions, payload) => {
        return fetchResponse<ActionItem>({
            actions,
            payload,
            request: getOneActionStatus,
        });
    }),
    updateActionStatus: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateActionStatus,
            message: "Action status has been edited",
        });
    }),
    getStatusesForActions: thunk(async (actions) => {
        return fetchResponse<ActionItem[]>({
            actions,
            request: getForActions
        });
    }),
    importData: thunk(async (actions, payload) => {
        return fetchResponse<ImportData>({
            actions,
            payload,
            request: importData
        });
    }),
    importDataFinal: thunk(async (actions, payload) => {
        return fetchResponse<ImportResult>({
            actions,
            payload,
            request: importDataFinal
        });
    }),
};

export default actionStatus;
