import ky from "../ky";

export type ActionTypesListType = {
  currentPageNo: number;
  query: string;
  sortCol: string;
  isDescending: boolean;
};

export const getActionTypesList = ({
  currentPageNo,
  query,
  sortCol,
  isDescending,
}: ActionTypesListType) =>
  ky.get(
    `ActionTypes?PageNumber=${currentPageNo}&SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10`
  );

export const getActionTypesL = () => ky.get(`ActionTypes`);

export type IdActionTypesType = {
  id?: string | number;
};

export const deleteActionTypes = ({ id }: IdActionTypesType) =>
  ky.delete(`ActionTypes?id=${id}`);

export type ActionTypesType = {
  id?: number;
  title: string;
};

export type ImportType = {
    languageCode: string,
    data: FormData
};

export type ImportFinalType = {
    importData: string[][]
};

export const addActionTypes = ({ title }: ActionTypesType) =>
  ky.post(`ActionTypes/add`, { json: { title } });

export const updateActionTypes = ({ title, id }: ActionTypesType) =>
  ky.put(`ActionTypes`, { json: { title, id } });

export const getOneActionTypes = ({ id }: IdActionTypesType) =>
  ky.get(`ActionTypes/${id}`);

export const getForActions = () => ky.get(`ActionTypes/ToShow`);

export const importData = ({ languageCode, data }: ImportType) =>
    ky.post(`ActionTypes/Import/Get?languageCode=${languageCode}`, { body: data });

export const importDataFinal = ({ importData }: ImportFinalType) =>
    ky.post(`ActionTypes/addMany`, { json: { data: importData } });