import ky from "../ky";

export type LanguageCode = {
    languageCode: string;
};

export type PopupsId = {
    popupId: number;
};

export type PopupListType = {
    currentPageNo: number;
    search: string;
    sortCol: string;
    isDescending: boolean;
    fromDate?: string | null | undefined;
    toDate?: string | null | undefined;
    languageCode: string;
};

export const getPopupsList = ({languageCode}: LanguageCode) =>
    ky.get(`Popups/GetPopups?languageCode=${languageCode}`);

export const readOff = ({ popupId }: PopupsId) =>
    ky.post(`Popups/ReadOff?popupId=${popupId}`, { json: { popupId: popupId } });

export const getMyPopupList = ({
    isDescending,
    currentPageNo,
    search,
    sortCol,
    languageCode,
}: PopupListType) =>
    ky.get(
        `Popups/GetMyPopups?PageNumber=${currentPageNo}&SearchWord=${search}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10&languageCode=${languageCode}`
    );

export const getMyPopupUnread = () => 
    ky.get(`Popups/MyPopupUnread`);