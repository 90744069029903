import ky from "../ky";

import { PatientFieldItem } from "./patientField.model";

export type InstanceId = {
  instanceId: number;
};

export const getPatientFields = ({ instanceId }: InstanceId) =>
  ky.get(`PatientFields?instanceId=${instanceId}`);

export type PatientFiledDto = {
  id: number;
  instanceId: number;
  title: string;
  spsslabel: string;
  obligated: boolean;
  export: boolean;
  active: boolean;
  onPatientPortal: boolean;
  type: 1 | 2;
  position: number;
  patientFieldAnswersDto: PatientFieldAnswerDto[];
};

export type PatientFieldAnswerDto = {
  id: number;
  value: string;
  spsslabel: string;
  patientFieldId: number;
  position: number;
};

export type PatientFieldData = {
  data: PatientFiledDto;
};

export const updatePatientField = ({ data }: PatientFieldData) =>
  ky.put(`PatientFields`, { json: data });

export type PatenitFiledId = {
  id: number;
};

export const deletePatientField = ({ id }: PatenitFiledId) =>
  ky.delete(`PatientFields?id=${id}`);

export const getOnePatientField = ({ id }: PatenitFiledId) =>
  ky.get(`PatientFields/${id}`);

export const addPatientField = ({ data }: PatientFieldData) =>
  ky.post(`PatientFields/add`, { json: data });

export const getBasicPatientField = ({ instanceId }: InstanceId) =>
  ky.get(`PatientFields/basic?instanceId=${instanceId}`);

export type UpdatePositionsType = {
  data: PatientFieldItem[];
};
export const editPositionList = ({ data }: UpdatePositionsType) =>
  ky.put(`PatientFields/editPositionList`, { json: { patientFields: data } });

export const getPatientFieldListToShow = () => ky.get(`PatientFields/toShow`);
