import {initReactI18next} from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {ResourcesModel} from "./services/translations/translations.model";

const initI18n = (resources: ResourcesModel) => {
    i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            resources,
            fallbackLng: "en",
            debug: true,
            keySeparator: false,
            interpolation: {
                escapeValue: false
            }
        });
};

export default initI18n;