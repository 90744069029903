import { thunk, Thunk, Action, action } from "easy-peasy";

import {
    ActionTypesListType,
    ActionTypesType,
    IdActionTypesType,
    addActionTypes,
    deleteActionTypes,
    getActionTypesList,
    getActionTypesL,
    getOneActionTypes,
    updateActionTypes,
    getForActions,
    ImportType,
    importData,
    ImportFinalType,
    importDataFinal
} from "./actionType.service";
import { ErrorEntry } from "../users/users.model";
import { fetchResponse, fetchBasic } from "../../helpers/fetching";

export type ActionList = {
    currentPage: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
    count: number;
    items: ActionItem[];
    hasPrevious: boolean;
    hasNext: boolean;
};

export type ActionItem = {
    id: number;
    title: string;
    isDefault?: boolean;
    isFinal?: boolean;
};

export type ColumnName = {
    key: string,
    val: string
};

export type ImportData = {
    columnNames: ColumnName[],
    fileData: string[][],
    languageCode: string
};

export type ImportResult = {
    totalRowsCount: number,
    processedRowsCount: number,
    ignoredRowsCount: number,
    successedRowsCount: number,
    wrongRowsCount: number,
    addedRowsCount: number,
    updatedRowsCount: number,
    errors: ColumnName[]
};

export type ActionTypesModel = {
    getActionTypesList: Thunk<ActionTypesModel, ActionTypesListType>;
    deleteActionTypes: Thunk<ActionTypesModel, IdActionTypesType>;
    addActionTypes: Thunk<ActionTypesModel, ActionTypesType>;
    getOneActionTypes: Thunk<ActionTypesModel, IdActionTypesType>;
    updateActionTypes: Thunk<ActionTypesModel, ActionTypesType>;
    getActionTypesL: Thunk<ActionTypesModel>;
    getTypesForActions: Thunk<ActionTypesModel>;
    error: ErrorEntry;
    setError: Action<ActionTypesModel, ErrorEntry>;
    delError: Action<ActionTypesModel>;
    importData: Thunk<ActionTypesModel, ImportType>;
    importDataFinal: Thunk<ActionTypesModel, ImportFinalType>;
};

const actionTypes: ActionTypesModel = {
    error: { code: 0 },
    setError: action((state, payload) => {
        state.error = payload;
    }),
    delError: action((state) => {
        state.error = { code: 0, message: "" };
    }),
    getActionTypesList: thunk(async (actions, payload) => {
        return fetchResponse<ActionList>({
            actions,
            payload,
            request: getActionTypesList,
        });
    }),
    getActionTypesL: thunk(async (actions) => {
        return fetchResponse<ActionList>({
            actions,
            request: getActionTypesL,
        });
    }),
    deleteActionTypes: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: deleteActionTypes,
            message: "Action type has been deleted",
        });
    }),
    addActionTypes: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: addActionTypes,
            message: "Action type has been added",
        });
    }),
    getOneActionTypes: thunk(async (actions, payload) => {
        return fetchResponse<ActionItem>({
            actions,
            payload,
            request: getOneActionTypes,
        });
    }),
    updateActionTypes: thunk(async (actions, payload) => {
        return fetchBasic({
            actions,
            payload,
            request: updateActionTypes,
            message: "Action type has been edited",
        });
    }),
    getTypesForActions: thunk(async (actions) => {
        return fetchResponse<ActionItem[]>({
            actions,
            request: getForActions
        });
    }),
    importData: thunk(async (actions, payload) => {
        return fetchResponse<ImportData>({
            actions,
            payload,
            request: importData
        });
    }),
    importDataFinal: thunk(async (actions, payload) => {
        return fetchResponse<ImportResult>({
            actions,
            payload,
            request: importDataFinal
        });
    }),
};

export default actionTypes;
