import { thunk, Thunk, Action, action } from "easy-peasy";

import { getLoginLogs, LoginLogListType, getLoginDetails, GetLoginDetails } from "./loginLog.service";
import { ErrorEntry } from "../usergroups/usergroups.model";
import { fetchResponse } from "../../helpers/fetching";

export interface LoginLogList {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  count: number;
  items: LoginLogItem[];
  hasPrevious: boolean;
  hasNext: boolean;
}

export type LoginLogItem = {
  id: number;
  instanceId: number;
  date: string;
  ip: string;
  userId: number;
  email: string;
  result: string;
  code: number;
  userName: string;
};

export type LoginLogModel = {
  getLoginLogs: Thunk<LoginLogModel, LoginLogListType>;
  getLoginDetails: Thunk<LoginLogModel, GetLoginDetails>
  error: ErrorEntry;
  setError: Action<LoginLogModel, ErrorEntry>;
  delError: Action<LoginLogModel>;
};

const loginLog: LoginLogModel = {
  error: { code: 0 },
  setError: action((state, payload) => {
    state.error = payload;
  }),
  delError: action((state) => {
    state.error = { code: 0, message: "" };
  }),
  getLoginLogs: thunk(async (actions, payload) => {
    return fetchResponse<LoginLogList>({
      actions,
      payload,
      request: getLoginLogs,
    });
  }),
  getLoginDetails: thunk(async (actions, payload) => {
    return fetchResponse<LoginLogItem>({
      actions,
      payload,
      request: getLoginDetails,
    });
  }),
};

export default loginLog;
