import ky from "../ky";

export type ActionsPropsType = {
  assignedToUserId: number | "",
  subtrajectoryIds: number[] | undefined,
    discuss: boolean | "",
    onDashboard: boolean | "",
  actionPriorityId: number | "",
  actionTypeId: number | "",
  actionStatusId: number | "",
  currentPageNo: number;
  query: string;
  sortCol: string;
  isDescending: boolean;
  pageSize?: number;
};

const urlHelper = (id: number | boolean | "", param: string, end?: string) => {
  return id !== "" ? `${param}=${id}${end && end}` : "";
};

export const getActions = ({
  assignedToUserId,
  subtrajectoryIds,
    discuss,
    onDashboard,
  actionPriorityId,
  actionTypeId,
  actionStatusId,
  currentPageNo,
  query,
  sortCol,
  isDescending,
  pageSize = 10
}: ActionsPropsType) =>
    ky.get(`Actions?${urlHelper(assignedToUserId, "AssignedToUserId", "&")}${urlHelper(discuss, "Discuss", "&")}${urlHelper(onDashboard, "OnDashboard", "&")}${urlHelper(actionPriorityId, "ActionPriorityId", "&")}${urlHelper(actionTypeId, "ActionTypeId", "&")}${urlHelper(actionStatusId, "ActionStatusId", "&")}PageNumber=${currentPageNo}&SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=${pageSize}${subtrajectoryIds ? subtrajectoryIds.map((id) => `&SIds=${id}`).join('') : ""}`);

export type ActionIdType = {
  id?: string | number;
};

export const getActionById = ({ id }: ActionIdType) => 
  ky.get(`Actions/${id}`);

export const deleteAction = ({ id }: ActionIdType) => 
  ky.delete(`Actions?id=${id}`);

export type FilledQuestionnaireInfoType = {
  testingId: number,
  patientTitleAndName: string,
  filledDate: string,
  patientNumber: number,
  subtrajectoryName: string,
  trajectoryName: string,
  patientEmail: string,
  testingCode: string
};

export type ActionType = {
  id?: number;
  assignedToUserId?: number,
  actionTypeId?: number,
  value?: string,
  reaction?: string,
  actionStatusId?: number,
  actionPriorityId?: number,
  discuss?: boolean,
  madeOn?: string,
  wrapUpOn?: string,
  editPermission?: boolean,
  filledQuestionaireInfo?: FilledQuestionnaireInfoType;
};

export type TestingCodeType = {
  testingCode: string
};

export type AddActionPropsType = ActionType & TestingCodeType;

export const addAction = ({ 
  testingCode,
  ...props 
}: AddActionPropsType) =>
  ky.post(
    `Actions/add?testingCode=${testingCode}`, 
    { json: { ...props } }
  );

export const updateAction = (props: ActionType) =>
  ky.put(`Actions`, { json: { ...props } });

export const getOtherActionsTestingCode = ({ testingCode }: TestingCodeType) =>
  ky.get(`Actions/fromTestingByCode?testingCode=${testingCode}`);

export const getOtherActionsActionId = ({ id }: ActionIdType) =>
    ky.get(`Actions/fromTestingByActionId?actionId=${id}`);

export const getActionsExport = async ({
    assignedToUserId,
    subtrajectoryIds,
    discuss,
    onDashboard,
    actionPriorityId,
    actionTypeId,
    actionStatusId,
    query,
    sortCol,
    isDescending
}: ActionsPropsType) => {
    const resp = await ky.get(`Actions/export?${urlHelper(assignedToUserId, "AssignedToUserId", "&")}${urlHelper(discuss, "Discuss", "&")}${urlHelper(onDashboard, "OnDashboard", "&")}${urlHelper(actionPriorityId, "ActionPriorityId", "&")}${urlHelper(actionTypeId, "ActionTypeId", "&")}${urlHelper(actionStatusId, "ActionStatusId", "&")}SearchWord=${query}&OrderBy=${sortCol}&IsDescending=${isDescending}${subtrajectoryIds ? subtrajectoryIds.map((id) => `&SIds=${id}`).join('') : ""}&languageCode=NL&isExcel=false`, { timeout: 600000 });
    const blob = resp.blob();
    return blob;
};