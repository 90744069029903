import ky from "../ky";

export const getQueryPropertiesList = () => ky.get(`QueryProperties`);

export type QueryDetailType = {
  id: number;
  title: string;
  spsslabel: string;
  type: number;
  active: boolean;
  locked: boolean;
};

export const editQueryProperty = ({
  id,
  title,
  spsslabel,
  type,
  active,
  locked,
}: QueryDetailType) =>
  ky.put(`QueryProperties`, {
    json: { id, title, spsslabel, type, active, locked },
  });

export type QueryId = {
  id?: string;
};

export const deleteQuery = ({ id }: QueryId) =>
  ky.delete(`QueryProperties?id=${id}`);

export const getOneQuery = ({ id }: QueryId) => ky.get(`QueryProperties/${id}`);

export const addQuery = ({
  id,
  title,
  spsslabel,
  type,
  active,
  locked,
}: QueryDetailType) =>
  ky.post(`QueryProperties/add`, {
    json: { id, title, spsslabel, type, active, locked },
  });

export const getQueryTypes = () => ky.get(`QueryProperties/types`);

export const getTypesForResponsibility = () =>
  ky.get(`QueryProperties/typesForResponsibility`);

export const getQueryToShow = () => ky.get(`QueryProperties/show`);

export type QuestionnaireId = {
  questionaireId: number;
};

export const getQueryForResponsibilities = ({
  questionaireId,
}: QuestionnaireId) =>
  ky.get(
    `QueryProperties/showForQuestionaire?questionaireId=${questionaireId}`
  );
