import ky from "../ky";

import { VisitType } from "./visits.model";

export type DeleteVisitId = {
    operationId: number;
    deleteReasonId: number;
};

export type DeleteVisitsId = {
    operationIds: number[];
    deleteReasonId: number;
};

export type VisitId = {
    operationId: number;
};

export type PatientId = {
    patientId: number;
};

export type SimpleId = {
    id: number;
};

export type VisitsListType = {
    subcategoryId: number | "";
    active: boolean | "";
    npsFilter: number | "";
    storiesId: number | "";
    invitationQuestionaireId: number | "";
    statusQuestionaireId: number | "";
    currentPageNo: number;
    search: string;
    sortCol: string;
    isDescending: boolean;
    subtrajectoryIds?: number[] | undefined;
    fromDate?: string | null | undefined;
    toDate?: string | null | undefined;
    internal: boolean;
    signal?: AbortSignal | null | undefined;
};

export type VisitsListTypeExport = {
    subcategoryId: number | "";
    active: boolean | "";
    npsFilter: number | "";
    storiesId: number | "";
    invitationQuestionaireId: number | "";
    statusQuestionaireId: number | "";
    search: string;
    sortCol: string;
    isDescending: boolean;
    subtrajectoryIds?: number[] | undefined;
    fromDate?: string | null | undefined;
    toDate?: string | null | undefined;
    internal: boolean;
};

export type LabelsVisitsListType = {
    typeId: number | "";
    currentPageNo: number;
    search: string;
    sortCol: string;
    isDescending: boolean;
    subtrajectoryIds?: number[] | undefined;
    labelsIds?: number[] | undefined;
    dateFrom?: string | null | undefined;
    dateTo?: string | null | undefined;
};

export type ImportVisitsType = {
    toSend: string,
    data: FormData
};

export type ImportVisitsFinalDataType = {
    importVisit: string[][]
};

export type ImportVisitsFinalType = {
    importVisit: string[][],
    languageCode: string,
    automaticInvitation: boolean
};

export type TableTypeExport = {
    currentPageNo: number;
    sortCol: string;
    isDescending: boolean;
};

const urlHelper = (id: number | boolean | "", param: string, end?: string) => {
    return id !== "" ? `${param}=${id}${end && end}` : "";
};

export const getVisitsList = ({
    subcategoryId,
    active,
    npsFilter,
    storiesId,
    invitationQuestionaireId,
    statusQuestionaireId,
    isDescending,
    currentPageNo,
    search,
    sortCol,
    subtrajectoryIds,
    fromDate,
    toDate,
    internal,
    signal
}: VisitsListType) =>
    ky.get(
        `Visits?${urlHelper(subcategoryId, "SubcategoryId", "&")}${urlHelper(
            active,
            "Active",
            "&"
        )}${urlHelper(npsFilter, "NpsFilter", "&")}${urlHelper(
            storiesId,
            "StoriesId",
            "&"
        )}${urlHelper(
            invitationQuestionaireId,
            "InvitationQuestionaireId",
            "&"
        )}${urlHelper(
            statusQuestionaireId,
            "StatusQuestionaireId",
            "&"
        )}${urlHelper(
            currentPageNo,
            "PageNumber",
            "&"
        )}SearchWord=${search}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10${(fromDate && fromDate.length > 0) ? `&FromDate=${fromDate}` : ""}${(toDate && toDate.length > 0) ? `&ToDate=${toDate}` : ""}${subtrajectoryIds ? subtrajectoryIds.map((id) => `&SIds=${id}`).join('') : ""}&internal=${internal ? "true" : "false"}`, { timeout: 600000, signal: signal }
    );

export const getVisitsExport = async ({
    subcategoryId,
    active,
    npsFilter,
    storiesId,
    invitationQuestionaireId,
    statusQuestionaireId,
    isDescending,
    search,
    sortCol,
    subtrajectoryIds,
    fromDate,
    toDate,
    internal
}: VisitsListType) => {
    const resp = await ky.get(`../../api/Visits/export?${urlHelper(subcategoryId, "SubcategoryId", "&")}${urlHelper(
        active,
        "Active",
        "&"
    )}${urlHelper(npsFilter, "NpsFilter", "&")}${urlHelper(
        storiesId,
        "StoriesId",
        "&"
    )}${urlHelper(
        invitationQuestionaireId,
        "InvitationQuestionaireId",
        "&"
    )}${urlHelper(
        statusQuestionaireId,
        "StatusQuestionaireId",
        "&"
        )}SearchWord=${search}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10${(fromDate && fromDate.length > 0) ? `&FromDate=${fromDate}` : ""}${(toDate && toDate.length > 0) ? `&ToDate=${toDate}` : ""}${subtrajectoryIds ? (subtrajectoryIds as number[]).map((sid) => `&SIds=${sid}`).join('') : ""}&internal=${internal ? "true" : "false"}&languageCode=NL&isExcel=false`, { timeout: 600000 });
    const blob = resp.blob();
    return blob;
};

export const updateVisit = ({ patientDto, operationDto }: VisitType) =>
    ky.put(`Visits`, { json: { patientDto, operationDto } });

export const addVisit = ({ patientDto, operationDto }: VisitType) =>
    ky.post(`Visits/add`, { json: { patientDto, operationDto }, timeout: 60000 });

export const deleteVisit = ({ operationId, deleteReasonId }: DeleteVisitId) =>
    ky.delete(
        `Visits?operationId=${operationId}&deleteReasonId=${deleteReasonId}`
    );

export const deleteVisits = ({ operationIds, deleteReasonId }: DeleteVisitsId) =>
    ky.post(
        `Visits/deleteMany`, { json: { operationIds, deleteReasonId }, timeout: 20000 }
    );

export const getOneVisit = ({ operationId }: VisitId) =>
    ky.get(`Visits/${operationId}`);

export const getBasicVisit = () => ky.get(`Visits/basic`);

export const inactivePatient = ({ patientId }: PatientId) =>
    ky.put(`Visits/inactive?patientId=${patientId}`);

export const activePatient = ({ patientId }: PatientId) =>
    ky.put(`Visits/active?patientId=${patientId}`);

export const getNps = () => ky.get(`Visits/NpsFilterOptions`);

export const getStory = () => ky.get(`Visits/StoryOptions`);

export const getInvitation = () => ky.get(`Visits/InvitationOptions`);

export const getVisitNavigation = ({ operationId }: VisitId) =>
    ky.get(`Visits/visitNavigation/${operationId}`);

export type VisitLogProps = VisitId & {
    pageSize?: number;
};

export const getVisitLogs = ({ operationId, pageSize = 15 }: VisitLogProps) =>
    ky.get(`Visits/Logs?operationId=${operationId}&pageSize=${pageSize}`);

export const getScores = ({ operationId }: VisitId) =>
    ky.get(`Scores?operationId=${operationId}`);

export const importVisits = ({ toSend, data }: ImportVisitsType) =>
    ky.post(`Visits/Import/Get?toSend=${toSend}`, { body: data });

export const importVisitsFinal = ({ languageCode, automaticInvitation, importVisit }: ImportVisitsFinalType) =>
    ky.post(`Visits/addMany?languageCode=${languageCode}&automaticInvitation=${automaticInvitation}`, { json: { data: importVisit }, timeout: 600000 });

export const getLabelsTestingsList = ({
    typeId,
    isDescending,
    currentPageNo,
    search,
    sortCol,
    subtrajectoryIds,
    labelsIds,
    dateFrom,
    dateTo
}: LabelsVisitsListType) =>
    ky.get(
        `Visits/getLabelsTestingsList?${urlHelper(
            typeId,
            "TypeId",
            "&"
        )}${urlHelper(
            currentPageNo,
            "PageNumber",
            "&"
        )}SearchWord=${search}&OrderBy=${sortCol}&IsDescending=${isDescending}&PageSize=10${(dateFrom && dateFrom.length > 0) ? `&DateFrom=${dateFrom}` : ""}${(dateTo && dateTo.length > 0) ? `&DateTo=${dateTo}` : ""}${subtrajectoryIds ? subtrajectoryIds.map((id) => `&SIds=${id}`).join('') : ""}${labelsIds ? labelsIds.map((id) => `&LIds=${id}`).join('') : ""}`
    );

export const getVisitsImportList = ({
    currentPageNo,
    sortCol,
    isDescending
}: VisitsListType) =>
    ky.get(`Visits/getImportRecords?OrderBy=${sortCol}&IsDescending=${isDescending}&PageNumber=${currentPageNo}&PageSize=10`, { timeout: 600000 });

export const getImportResult = ({
    id
}: SimpleId) =>
    ky.get(`Visits/getImportResult?fileId=${id}`, { timeout: 600000 });

export const deleteVisitsImport = ({
    id
}: SimpleId) =>
    ky.delete(`Visits/deleteVisitsImport?fileId=${id}`, { timeout: 600000 });